import * as api from '@/api'
import { ShadcnButton } from '@/ui'
import { subscriptionChosen } from '@screens'
import { Drawer, DrawerClose, DrawerContent, DrawerHeader } from '@shared/ui/drawer'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { availablePlans, availablePlansByTitle, PlanIDs, ROUTES } from '@/constants'
import { $userProfile } from '@/entities/user'
import fmt from '@/lib/fmt'
import { CheckIcon, Cross1Icon, ReloadIcon } from '@radix-ui/react-icons'
import { ScrollArea } from '@shared/ui/scroll-area'
import { useEffect, useState } from 'react'
import { usePlan } from 'src/features/plan-context'

const RenderPlan = ({ data }: { data: string[] }) => {
  return (
    <div className="flex flex-nowrap items-start gap-1">
      <CheckIcon className="h-4 min-h-[16px] w-4 min-w-[16px] text-gray-500" />
      <span className="text-[14px] font-medium">{data}</span>
    </div>
  )
}

export const PlanBlock = ({
  plan,
}: {
  plan: (typeof availablePlans)[number]
  handleClosing: () => void
  redirect: string
}) => {
  const [user, onSelectSubscription, isPaymentLoading] = useUnit([
    $userProfile,
    subscriptionChosen,
    api.$isPaymentLoading,
  ])
  const t = useTranslations()
  const [isLoading, setIsLoading] = useState(false)

  const translateJumpTable: Record<string, string> = {
    Basic: t(`basic`),
    Premium: plan.title,
  }

  useEffect(() => {
    if (!isPaymentLoading) {
      setIsLoading(false)
    }
  }, [isPaymentLoading])

  const handleOnClick = () => {
    setIsLoading(true)
    onSelectSubscription(plan)
  }

  return (
    <div className="border-slate w-full rounded-[12px] border p-4 font-golos">
      <div className={'flex w-full flex-col bg-white'}>
        <div className="mb-4 flex items-center">
          <h1 className={'font-stem text-[27px] font-medium'}>{translateJumpTable[plan.title]}</h1>
          <span className={'ml-4 text-[14px] opacity-[0.7]'}>
            ({fmt.number(plan?.checks || '', 'kz', false)}
            {plan.months === 12 ? t(`checks_year`) : t(`checks`)})
          </span>
        </div>
        <div className="flex flex-1 flex-col md:grid md:grid-cols-[1fr,_auto]">
          <div className="grid auto-rows-max gap-2 md:grid-cols-2 lg:gap-4">
            {Array.from(t.raw(`plan_modal_description.${plan.title.toLowerCase()}`)).map((entries: any, idx) => (
              <RenderPlan key={idx} data={entries} />
            ))}
          </div>
          <div className="flex flex-col items-center justify-center md:w-80">
            <h3
              className={`my-4 flex items-center gap-2 pt-2 font-stem text-[40px] font-medium md:mt-0 md:flex-col md:text-center`}
            >
              <div className="relative flex items-start justify-center gap-5">
                {plan.old_price && plan.months === 12 ? (
                  <span className="relative text-[20px] line-through opacity-[0.7]">
                    <span className="absolute ml-[-10px] text-[15px] opacity-[0.7]">₸</span>
                    <span>{fmt.number(plan.old_price || 0, 'kz', false)}</span>
                  </span>
                ) : null}
                <span className="relative">
                  <span className="absolute ml-[-15px] mt-[8px] text-[20px] opacity-[0.7]">₸</span>
                  {fmt.number(plan.price || 0, 'kz', false)}
                </span>
              </div>
              <span className="text-sm opacity-[0.7] before:content-['('] after:content-[')'] md:mt-[-10px] md:before:hidden md:after:hidden">
                {plan.months === 1 ? t('checks_price') : t(`checks_price_year`)}
              </span>
            </h3>
            <ShadcnButton variant="linear" size="xl" onClick={handleOnClick} className="w-full" disabled={isLoading}>
              {isLoading && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}

              <span className="text-lg font-bold">
                {!user ? t('create_account') : user?.plan_id === plan.id ? t('update_subscribe') : t('start')}
              </span>
            </ShadcnButton>
          </div>
        </div>
      </div>
    </div>
  )
}

export const PlanModalWindow = ({ redirect = ROUTES.PRICING }: { redirect: string }) => {
  const t = useTranslations()

  const { closePlanModalWindow, planModalWindow } = usePlan()

  const handleClosing = () => {
    closePlanModalWindow()
  }

  return (
    <Drawer open={planModalWindow} onClose={closePlanModalWindow}>
      <DrawerContent className="container max-h-[90dvh]">
        <DrawerClose className="hidden md:block">
          <Cross1Icon className="absolute right-4 top-4 h-4 w-4 cursor-pointer" onClick={handleClosing} />
        </DrawerClose>
        <DrawerHeader className="px-0">
          <h1 className="text-left font-golos text-xl">{t('see_full_report')}</h1>
        </DrawerHeader>
        <ScrollArea className="max-h-[calc(100%_-_88px)] overflow-y-auto">
          <div className="flex flex-col items-center justify-center gap-4 overflow-y-auto pb-12 md:pb-8">
            {availablePlans
              .filter((plan) =>
                [...availablePlansByTitle.Basic, ...availablePlansByTitle.Premium].includes(plan.id as PlanIDs),
              )
              .filter((plan) => plan.months !== 12)
              .map((plan, id) => (
                <PlanBlock plan={plan} key={id} handleClosing={handleClosing} redirect={redirect} />
              ))}
          </div>
        </ScrollArea>
      </DrawerContent>
    </Drawer>
  )
}
