import { Store } from 'effector'
import _ from 'lodash'

import kbkJson from 'json/kbk.json'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

type ExtractGeneric<P> = P extends Store<infer T> ? T : never

const processFinancialItem = (items: Record<string, any>, table: any) => {
  ;(items || []).forEach((item: Record<string, any>) => {
    const key = `${item.kbk}-${item.year}`
    const previousValue = table.get(key) || {
      code: item.kbk,
      name: kbkJson[item.kbk as keyof typeof kbkJson],
      summary: 0,
      year: item.year,
    }

    const paid = _.get(item, 'paid', 0)
    const summary = _.get(previousValue, 'summary', 0) + (isNaN(paid) ? 0 : Math.ceil(paid))
    _.set(previousValue, 'summary', summary)

    table.set(key, previousValue)
  })
}

export function parseFinancials(data: ExtractGeneric<ForCompanyOrIndividualProps['for']>) {
  const initialState = {
    totalRevenue: 0,
    totalDeductions: 0,
    totalAccruals: 0,
    estimAnnualRevenueByYear: {},
    deductionsGraphDataByYear: {},
    accrualsGraphDataByByYear: {},
    deductionsTable: new Map(),
    accrualsTable: new Map(),
  }

  if (!data?.financials) return initialState

  return (data.financials as Record<string, any>[]).reduce((prev, cur) => {
    const totalAccruals = _.sumBy(cur.accruals, 'paid')
    processFinancialItem(cur.deductions, prev.deductionsTable)
    processFinancialItem(cur.accruals, prev.accrualsTable)

    return {
      totalRevenue: prev.totalRevenue + (cur.revenue || 0),
      totalDeductions: prev.totalDeductions + (cur.taxes || 0),
      totalAccruals: prev.totalAccruals + totalAccruals,
      estimAnnualRevenueByYear: {
        ...prev.estimAnnualRevenueByYear,
        [cur.year]: Math.ceil(cur.revenue),
      },
      deductionsGraphDataByYear: {
        ...prev.deductionsGraphDataByYear,
        [cur.year]: Math.ceil(cur.taxes),
      },
      accrualsGraphDataByByYear: {
        ...prev.accrualsGraphDataByByYear,
        [cur.year]: totalAccruals,
      },
      deductionsTable: prev.deductionsTable,
      accrualsTable: prev.accrualsTable,
    }
  }, initialState)
}
