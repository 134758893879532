import { createStore, sample } from 'effector'

import { loadSubsidiariesFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, $isLoaded, $companyId, visibilityChanged, $shouldBeLoaded } = createBindingForVisibility()

export const $subsidiaries = createStore<ResponseType<'/business/{id}/subsidiaries', 'get'>>({})

sample({
  clock: $inView,
  source: { isLoaded: $isLoaded, id: $companyId, shouldBeLoaded: $shouldBeLoaded },
  filter: ({ isLoaded, id, shouldBeLoaded }, inView) => shouldBeLoaded && !isLoaded && inView && !!id,

  fn: ({ id }) => ({
    path: {
      id: id as string,
    },
  }),
  target: [loadSubsidiariesFx],
})

sample({
  clock: loadSubsidiariesFx.doneData,
  target: $subsidiaries,
})
