import { createEvent, createStore, sample } from 'effector'
import { createForm } from 'effector-forms'

import * as yup from 'yup'

import { loginUserFx } from '@/api'
import { createRule } from '@/lib/effector-forms'

import { loginUser } from '@/process/auth'
import { pushFx } from '@/shared-events'

export const $redirectURI = createStore('/me/personal-area')
export const setRedirectURI = createEvent<string>()

export const $$loginForm = createForm({
  fields: {
    email: { init: '', rules: [createRule({ schema: yup.string().required(), name: 'required' })] },
    password: { init: '', rules: [createRule({ schema: yup.string().required().min(4), name: 'required' })] },
  },
})

sample({
  clock: setRedirectURI,
  source: $redirectURI,
  fn: (original, incoming) => {
    if (!incoming) {
      return original
    }
    return incoming
  },
  target: $redirectURI,
})

sample({
  clock: $$loginForm.formValidated,
  target: loginUser,
})

sample({
  clock: loginUserFx.done,
  source: $redirectURI,
  fn: (redirectURI) => redirectURI,
  target: pushFx,
})

sample({
  clock: loginUserFx.doneData,
  fn: () => $redirectURI.reset(),
})
