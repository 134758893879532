import { useTranslations } from 'next-intl'
import { ReactNode, useState } from 'react'

import { SafeView } from '@/features'
import { Placeholder } from '@/ui'

import { DataSection } from './data-section'

type Props = {
  title: ReactNode
  children: ReactNode
  isFull: boolean
  mocked?: boolean
  collapsed: ReactNode
  showCollapse?: boolean
}

export function CollapsibleDataSection({
  title,
  children,
  isFull,
  mocked = false,
  collapsed,
  showCollapse = true,
}: Props) {
  const [toggle, setToggle] = useState(true)
  const t = useTranslations()

  return (
    <>
      <DataSection title={title}>
        <SafeView for={!mocked} otherwise={<Placeholder withGeneration />}>
          <div className="-mt-1 flex items-center gap-2">
            {children}
            {showCollapse && (
              <button
                onClick={() => (isFull ? setToggle(!toggle) : '')}
                className="hover:text-statsnet flex items-center rounded py-1 text-sm text-gray-400 outline-none duration-300 focus:outline-none "
              >
                {toggle ? t(`show`) : t(`hide`)}

                <span className={`forTable text-gray-400 ${toggle ? 'rotated' : ''}`}></span>
              </button>
            )}
          </div>
        </SafeView>
      </DataSection>
      {!toggle && <div className="col-span-full">{collapsed}</div>}
    </>
  )
}
