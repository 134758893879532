import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useInView } from 'react-intersection-observer'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'

import fmt from '@/lib/fmt'

import { Placeholder } from '@/ui'

import { CollapsibleBlock } from '@/widgets'

import { MiniBar } from 'src/screens/company/ui/mini-bar'

import { DataSection } from '../data-section'
import { ForCompanyOrIndividualProps } from '../types'
import { parseFinancials } from './common'
import { $companyData, $taxDeclarations, visibilityChanged } from './model'
import { FinancesTabs } from './tabs'

export function Finances({ for: $data, isFull: $isFull, person }: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) {
  const t = useTranslations()

  const [data, isFull, onVisibilityChanged, taxDeclarations, companyData] = useUnit([
    $data,
    $isFull,
    visibilityChanged,
    $taxDeclarations,
    $companyData,
  ])

  const { ref } = useInView({
    onChange: (inView) => onVisibilityChanged({ visible: inView, id: data?.id, shouldBeLoaded: isFull }),
  })

  const finances = useStoreMap({
    store: $data,
    keys: [data?.id],
    fn: (data) => {
      return parseFinancials(data)
    },
  })

  return (
    <SafeView for={data?.jurisdiction === 'kz'}>
      <CollapsibleBlock title={t('finances')} dataType="finances" id="finances" ref={ref}>
        <DataSection title={t('estimated_annual_revenues')}>
          <SafeView for={finances.totalRevenue} otherwise={t('no_information')}>
            <MiniBar
              data={finances.estimAnnualRevenueByYear}
              totalSum={finances.totalRevenue}
              jurisdiction={data?.jurisdiction ?? 'kz'}
              type="estimate"
              isVatPayer
            />
          </SafeView>
        </DataSection>

        <DataSection title={t('vat_payer')} colored>
          {data?.vat_payer ? t('good_vat_payer') : t('bad_vat_payer')}
        </DataSection>

        <DataSection title={t('authorized_capital')}>
          <SafeView for={isFull} otherwise={<Placeholder withCuntrySgin withGeneration />}>
            {data?.capital?.share_capital
              ? fmt.number(data.capital.share_capital, data.jurisdiction as any)
              : t('not_found')}
          </SafeView>
        </DataSection>

        <SafeView for={data?.jurisdiction === 'kz' && isFull}>
          <DataSection title={t('property')}>
            <SafeView for={companyData?.assets?.filter((asset) => !!asset.type)} withProps otherwise={t('not_found')}>
              {(assets) =>
                assets
                  .map((asset) => {
                    return asset.type
                  })
                  .join(' · ')
              }
            </SafeView>
          </DataSection>
        </SafeView>

        <DataSection title={t('tax_regime')} colored>
          <SafeView for={isFull} otherwise={<Placeholder withCuntrySgin withGeneration />}>
            <SafeView for={taxDeclarations?.tax_regime} otherwise={t('not_found')}>
              {taxDeclarations?.tax_regime}
            </SafeView>
          </SafeView>
        </DataSection>

        <FinancesTabs for={$data} isFull={$isFull} person={person} finances={finances} />
      </CollapsibleBlock>
    </SafeView>
  )
}
