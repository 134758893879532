import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { ForCompanyOrIndividualProps } from '../../types'
import { TableSection } from './table-section'

export function CompanyNames({ for: $data }: Pick<ForCompanyOrIndividualProps, 'for'>) {
  const t = useTranslations()
  const [data] = useUnit([$data])
  const nameFields = t.raw('short_info_about_comp') as Record<string, any>
  return (
    <>
      {Object.entries(nameFields).map(([title, value]) => {
        const isFullName = title === 'full_name'
        const isNameNational = title === 'name_national'
        const isSEStructure = data?.structure === 'se'
        const nameToDisplay = isNameNational ? value[data?.jurisdiction?.toUpperCase() as string] : value

        return (
          <TableSection title={nameToDisplay} key={title}>
            {isFullName ? (
              <div className="col-span-full flex items-center gap-2">
                {data?.name?.toUpperCase()}

                {isSEStructure && (
                  <a
                    href="https://stat.gov.kz"
                    target="_blank"
                    className="col-span-full ml-2 text-xs text-gray-500 hover:text-gray-500"
                    rel="nofollow"
                  >
                    {t('open_source', {
                      name: 'stat.gov.kz',
                    })}
                  </a>
                )}
              </div>
            ) : isNameNational ? (
              data?.name_native?.toUpperCase()
            ) : (
              (data?.[title as keyof typeof data] as string)?.toUpperCase()
            )}
          </TableSection>
        )
      })}
    </>
  )
}
