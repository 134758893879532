import { useMemo } from 'react'

import { apiTypes } from '@/api'
import fmt from '@/lib/fmt'

export function IndustryCode({ company }: { company: apiTypes.Company }) {
  const mainIndustryDescriptions = useMemo(() => fmt.industryCode({ company } as any), [company])
  const hasMainIndustry = Boolean(mainIndustryDescriptions)
  const industry = company?.industry_code?.descr || company?.industry

  return (
    <div className="gap-1 text-sm">
      {hasMainIndustry && <span>{mainIndustryDescriptions}</span>}
      {hasMainIndustry && industry && ' · '}
      {industry && <span className={hasMainIndustry ? 'text-gray-500' : ''}>{industry}</span>}
    </div>
  )
}
