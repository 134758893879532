import React, { useEffect, useState } from 'react'

interface NotificationProps {
  message: string
  color: string
}

const Notification: React.FC<NotificationProps> = ({ message, color }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [message])

  return (
    <div
      className={`fixed bottom-0 left-0 mb-5 ml-5 w-auto max-w-sm rounded-lg p-6 text-white shadow-lg transition-opacity duration-1000 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{
        transition: 'opacity 1s ease-in-out',
        zIndex: '1000',
        fontWeight: 'bold',
        background: color,
      }}
    >
      {message}
    </div>
  )
}

export default Notification
