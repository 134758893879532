import { Bar } from '@nivo/bar'
import { useLocale, useTranslations } from 'next-intl'

import fmt from '@/lib/fmt'

type GraphTooltipTitle = 'deduction_in' | 'accruals_in' | 'declarations_in' | 'invalid'
type GraphData = { [year: number]: number }

export function Visualization({ data, title }: { data: GraphData; title: GraphTooltipTitle }) {
  const t = useTranslations()
  const lang = useLocale()
  return (
    <div className={'overflow-x-auto pb-2'}>
      <Bar
        data={Object.entries(data).map(([key, value]) => ({
          sum: value ? Math.round(value as number) : 0,
          year: key ? key : 0,
        }))}
        keys={['sum']}
        axisLeft={{
          tickSize: 0,
          tickValues: 4,
          renderTick: (tick: any) => (
            <g transform={`translate(${tick.x},${tick.y})`}>
              <text
                textAnchor="middle"
                dominantBaseline="middle"
                style={{
                  // ...theme.axis.ticks.text,
                  fill: '#333',
                  fontSize: 14,
                }}
              >
                {new Intl.NumberFormat(lang, {
                  notation: 'compact',
                  compactDisplay: 'short',
                }).format(tick.value) + ` ₸`}
              </text>
            </g>
          ),
        }}
        padding={Object.values(data).length <= 3 ? 0.8 : 0.2}
        //@ts-ignore
        labelFormat={(d) => (
          <tspan y={-10} z={100} fontSize={14} style={{ backgroundColor: '#fff' }}>
            {d}
          </tspan>
        )}
        colors="#acc6d8"
        enableLabel={false}
        tooltipFormat={(d: number) => fmt.number(d ? d : 0, 'kz')}
        tooltip={(d: any) => (
          <div className="flex flex-col rounded bg-white p-3 shadow-lg">
            <span className="block text-sm text-primary">
              {t(title)} {d.indexValue ? d.indexValue : ''} {t(`year`)}
            </span>
            <span className="block">{fmt.number(d.value ? d.value : 0, 'kz')}</span>
          </div>
        )}
        indexBy="year"
        minValue="auto"
        maxValue="auto"
        layout="vertical"
        isInteractive={true}
        margin={{ top: 20, right: 0, bottom: 20, left: 40 }}
        width={750}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        height={300}
        enableGridY={false}
        markers={[
          {
            axis: 'y',
            value: 0,
            lineStyle: {
              stroke: 'rgba(0, 0, 0, .35)',
              strokeWidth: 1,
            },
          },
        ]}
      />
    </div>
  )
}
