import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'

import { $userProfile } from '@/entities/user'
import fmt from '@/lib/fmt'

function updateClipboard(newClip: string) {
  navigator.clipboard.writeText(newClip).then(
    function () {},
    function (err) {
      console.info('Something get wrong', err)
    },
  )
}

function checkPlan(plan?: number) {
  if (!plan) return false

  return plan > 2
}
export const DevelopersInfoScreen = () => {
  const t = useTranslations()
  const [user] = useUnit([$userProfile])
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  const copyHandler = () => {
    if (user?.api_token) {
      const text = user?.api_token
      updateClipboard(text)
      setShowCopiedMessage(true)
      setTimeout(() => {
        setShowCopiedMessage(false)
      }, 3000)
    }
  }

  return (
    <>
      <div className="col-span-full grid grid-cols-4">
        <h1 className="col-span-full font-stem text-2xl">API</h1>
      </div>

      {checkPlan(user?.plan_id) ? (
        <>
          <div className="col-span-full grid grid-cols-4 border-b pb-2">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('api_copy')}</h2>
            <h2 className="col-span-full md:col-span-2">
              <button
                className="col-span-full rounded bg-blue-500 px-2 py-1 text-white focus:outline-none md:col-span-1"
                onClick={() => copyHandler()}
              >
                {t('api_get_key')}
              </button>
              {showCopiedMessage && <h2 className="col-span-full ml-2 self-center text-gray-500">{t('copied')}</h2>}
            </h2>
          </div>

          <div className="col-span-full grid grid-cols-4 pb-2 pt-2">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('your_balance')}</h2>
            <h2 className="col-span-full text-lg md:col-span-2 ">
              {user?.reports ? fmt.number(user.reports, 'kz', false) : '0'} {t(`length_checks`)}
            </h2>
          </div>
        </>
      ) : (
        <>
          <h2 className="col-span-full text-center text-lg ">{t('only_for_subscribers_premium')}</h2>
        </>
      )}
    </>
  )
}
