import { createEvent, createStore, sample } from 'effector'

import { loadOrderHistoryFx } from '@/api'
import { ResponseType } from 'src/shared/api/model'

export const $ordersHistory = createStore<ResponseType<'/user/reports', 'get'>['business']>([])
export const $totalOrders = createStore(0)
export const $page = createStore(1)
export const orderHistoryLoaded = createEvent<void>()

export const pageChanged = createEvent<number>()

sample({
  clock: pageChanged,
  target: $page,
})

sample({
  clock: [orderHistoryLoaded, $page],
  fn: (page) => ({
    query: {
      limit: 20,
      page: page || 1,
    },
  }),
  target: loadOrderHistoryFx,
})

sample({
  clock: loadOrderHistoryFx.doneData,
  fn: ({ business }) => business,
  target: $ordersHistory,
})

sample({
  clock: loadOrderHistoryFx.doneData,
  fn: ({ pagination_count }) => pagination_count ?? 0, // Укажите значение по умолчанию, если `pagination_count` не определен
  target: $totalOrders,
})
