import { createApiCall } from '@shared/api/model'
import { createEvent, createStore, sample } from 'effector'

export const $phoneNumber = createStore<string>('')
export const verifyNumber = createEvent<string>()
export const $verifyError = createStore('')

export const $verifyToken = createStore('')
export const sendDeleteRequestWithCode = createEvent<string>()
export const $deleteError = createStore('')

export const [verifyPhoneNumberFx] = createApiCall({
  method: 'GET',
  source: '/user/phone/unauthorized/verifying',
})

export const [deletePhoneNumberFx] = createApiCall({
  method: 'DELETE',
  source: '/user/phone/unauthorized',
})

$phoneNumber.reset(deletePhoneNumberFx.done)
$verifyToken.reset(deletePhoneNumberFx.done)
$deleteError.reset(deletePhoneNumberFx.done)

sample({
  clock: verifyNumber,
  fn: (phone) => ({ headers: { phone: phone } }),
  target: verifyPhoneNumberFx,
})

sample({
  clock: verifyNumber,
  target: $phoneNumber,
})

sample({
  clock: verifyPhoneNumberFx.inFlight,
  fn: () => '',
  target: $verifyError,
})

sample({
  clock: verifyPhoneNumberFx.fail,
  fn: () => 'error',
  target: $verifyError,
})

sample({
  clock: verifyPhoneNumberFx.failData,
  fn: (err) => err.response?.data?.message || 'error',
  target: $verifyError,
})

sample({
  clock: verifyPhoneNumberFx.doneData,
  fn: (data) => data.verify_token,
  target: $verifyToken,
})

sample({
  clock: sendDeleteRequestWithCode,
  source: $verifyToken,
  fn: (token, otpCode) => {
    return {
      headers: {
        sms_code: otpCode,
        verify_token: token,
      },
    }
  },
  target: deletePhoneNumberFx,
})

sample({
  clock: deletePhoneNumberFx.inFlight,
  fn: () => '',
  target: $deleteError,
})

sample({
  clock: deletePhoneNumberFx.fail,
  fn: () => 'error',
  target: $deleteError,
})

sample({
  clock: deletePhoneNumberFx.failData,
  fn: (err) => err.response?.data?.message || 'error',
  target: $deleteError,
})
