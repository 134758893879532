import { useTranslations } from 'next-intl'

import { apiTypes } from '@/api'
import { SafeView } from '@/features'

// priority of officer roles
// the higher the priority, the more important the role
const officerRolePriorities = {
  acting_director: 1,
  chief_executive_officer: 2,
  chairman: 3,
  director: 4,
  managing_director: 5,
  executive_director: 6,
  head: 7,
  president: 8,
  regional_director: 9,
  akim: 10,
} as const

export const ManagingName = ({
  company,
  withHighlighter = false,
}: {
  company: apiTypes.Company
  withHighlighter?: boolean
}) => {
  const t = useTranslations()

  function renderCompanyName() {
    if (company.managing_company_name) {
      return company.managing_company_name || ''
    }

    if (withHighlighter && company?.highlights['officers.name']) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: company.highlights['officers.name'][0],
          }}
        />
      )
    }

    if (!company.officers || company.officers?.length === 0) {
      return null
    } else if (company.officers?.length === 1) {
      return company.officers[0]?.name || company.officers[0]?.name_en
    }

    const officer = company.officers.sort((a, b) => {
      const aPriority = officerRolePriorities[a.role as keyof typeof officerRolePriorities] || 1
      const bPriority = officerRolePriorities[b.role as keyof typeof officerRolePriorities] || 1

      return bPriority - aPriority
    })[0]

    return officer?.name || officer?.name_en
  }

  return (
    <span>
      {renderCompanyName()}

      <span className="text-black">
        <SafeView
          for={company.is_branch && company.managing_company_name}
          otherwise={company.officers?.length ? ` — ${t('officer').toLowerCase()}` : null}
        >
          {` — ${t('management_company')}`}
        </SafeView>
      </span>
    </span>
  )
}
