import { generateHeaders } from '@/api/model'
import axios from 'axios'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import React, { useState } from 'react'
import ModalCreateList from '../../user-area/lists-page/ModalCreateList'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  companyId: number
  getExistCompany: () => void
}

interface List {
  id: number
  name: string
}

const ModalSelectList: React.FC<ModalProps> = ({ isOpen, onClose, companyId, getExistCompany }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [list, setList] = useState<List[]>([])
  const [selectedList, setSelectedList] = useState('')
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const t = useTranslations()

  if (!isOpen) return null

  const getLists = async () => {
    const token = localStorage.getItem('token')
    const headers = {
      ...generateHeaders(),
      Authorization: `Bearer ${token}`,
    }
    const res = await axios.get(`${process.env.NEXT_PUBLIC_API_URL}/api/v2/user/favourite/list`, { headers })
    setList(!res.data.list ? [] : res.data.list)
  }

  const setData = async (id: number) => {
    const token = localStorage.getItem('token')
    const headers = {
      ...generateHeaders(),
      Authorization: `Bearer ${token}`,
    }

    await axios.post(
      `${process.env.NEXT_PUBLIC_API_URL}/api/v2/user/favourite/items`,
      {
        list_id: id,
        company_id: companyId,
      },
      { headers },
    )

    await getExistCompany()
    onClose()
    setSelectedList('')
    setIsDropdownOpen(false)
  }

  if (!list.length) {
    getLists()
  }

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
      style={{ zIndex: '1000' }}
      onClick={() => {
        if (!isModalOpen) {
          onClose()
          setSelectedList('')
          setIsDropdownOpen(false)
        }
      }}
    >
      <ModalCreateList isOpen={isModalOpen} getData={getLists} onClose={() => setIsModalOpen(false)} />
      <div
        className="rounded-lg bg-white p-8"
        style={{ width: '350px', position: 'relative' }}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="text-md absolute right-0 top-0 mr-4 mt-4 rounded bg-blue-500 p-3 font-medium text-white transition hover:bg-blue-600"
          onClick={() => {
            setIsModalOpen(true)
            setIsDropdownOpen(false)
          }}
        >
          {t('add_list')}
        </button>
        <br />
        <div className="mt-10 flex flex-col items-center">
          <button
            className="flex w-full items-center justify-between rounded-lg bg-gray-200 px-4 py-2 text-left font-semibold text-gray-700"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            {selectedList || t('select_list')}
            <Image src={'/icons/dd_icon.png'} alt={'add to favorites icon'} width={12} height={12} />
          </button>
          {isDropdownOpen && (
            <div
              className={`absolute z-10 mt-10 max-h-60 overflow-auto bg-white shadow-lg transition-opacity duration-300 ease-out ${
                isDropdownOpen ? 'opacity-100' : 'opacity-0'
              } rounded-bl-lg rounded-br-lg`}
              style={{ width: '300px' }}
            >
              {list.map((item) => (
                <div
                  key={item.id}
                  className="text-l flex cursor-pointer items-center px-4 py-2 font-semibold text-gray-500 transition-colors duration-200 ease-in-out hover:bg-gray-100 hover:text-blue-500"
                  onClick={() => {
                    setSelectedList(item.name)
                    setIsDropdownOpen(false)
                    setData(item.id)
                  }}
                >
                  {item.name}
                </div>
              ))}
            </div>
          )}
        </div>
        {/*<div style={{ display: 'flex', margin:'10px 0 0 0', justifyContent:'space-between' }}>*/}
        {/*  <button*/}
        {/*    className={'mt-4 mr-4 font-medium text-md p-3 rounded text-white bg-red-500 hover:bg-red-600 transition'}*/}
        {/*    onClick={onClose}>Отменить*/}
        {/*  </button>*/}
        {/*  <button*/}
        {/*    className={'mt-4 mr-4 font-medium text-md p-3 rounded text-white bg-blue-500 hover:bg-blue-600 transition'}>*/}
        {/*    Добавить*/}
        {/*  </button>*/}
        {/*</div>*/}
      </div>
    </div>
  )
}

export default ModalSelectList
