import { useUnit } from 'effector-react'

import { useTranslations } from 'next-intl'

import { $isCompaniesExporting } from '@/api'
import { $isExcelLoaded, exportSettingsOpenedChanged } from '@/screens'
import { CircularProgress } from '@/ui'

export const ExportCompaniesButton = () => {
  const t = useTranslations()

  const [handleOpenExportSettings, isExcelLoaded, isCompaniesExporting] = useUnit([
    exportSettingsOpenedChanged,
    $isExcelLoaded,
    $isCompaniesExporting,
  ])

  const openExcel = () => {
    handleOpenExportSettings(true)
  }

  return (
    <button
      onClick={openExcel}
      disabled={isCompaniesExporting}
      className={`w-full rounded-3xl border bg-blue-500 px-4 py-2 text-sm font-medium text-white outline-none duration-300 hover:bg-blue-600 focus:outline-none disabled:bg-gray-400`}
    >
      {isCompaniesExporting && (
        <div className="mx-auto h-4 w-4">
          <CircularProgress />
        </div>
      )}
      {!isCompaniesExporting && (!isExcelLoaded ? t(`add_on_excel`).toUpperCase() : t(`excel_was_added`))}
    </button>
  )
}
