// @ts-nocheck
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { Map, Placemark, YMaps } from 'react-yandex-maps'

import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { CollapsibleBlock } from '@/widgets'
import { DataSection } from 'src/screens/company/ui/data-section'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

export function Address({ for: $data }: ForCompanyOrIndividualProps) {
  const [data] = useUnit([$data])
  const t = useTranslations()

  const { inView, ref } = useInView({
    threshold: 0,
    triggerOnce: true,
  })
  const [coordinates, setCoordinates] = useState<number[]>([55.75, 37.57])
  const geocode = async (ymaps: any) => {
    const result = await ymaps.geocode(
      fmt.address({
        company: data,
        jurisdiction: data?.jurisdiction || ('kz' as any),
        jurisdictionTranslate: t.raw('jurisdictions')[data?.jurisdiction || 'kz'],
      }),
    )

    if (result.geoObjects.get(0)) {
      setCoordinates(result.geoObjects.get(0).geometry.getCoordinates())
    }
  }

  const area = useMemo(() => {
    return fmt.getProperAreaFormat(
      (data?.addresses && data.addresses[0] && data.addresses[0]?.state) as string,
      data?.jurisdiction as string,
      t.raw('areas_by_jurisdiction'),
    )
  }, [data?.addresses, data?.jurisdiction, t])

  return (
    <CollapsibleBlock className="select-none" title={t('address')} dataType="address" id="address" ref={ref}>
      <DataSection title={t('country')}>{t.raw('jurisdictions')[data?.jurisdiction || 'kz']}</DataSection>
      <DataSection title={t('region')}>{area}</DataSection>
      <DataSection title={t('street_name_number')}>
        {data?.addresses?.length && data.addresses[0]?.street && data.addresses[0].building ? (
          <>
            {data.addresses[0]?.street + (data.addresses[0].building ? ', ' : '') || ''}
            {data.addresses[0].building || ''}
          </>
        ) : data?.addresses && data?.addresses[0]?.raw ? (
          data?.addresses[0].raw
        ) : (
          t(`not_found`)
        )}
      </DataSection>

      <SafeView for={data?.addresses?.length}>
        {data!.addresses?.[0]?.apt && (
          <DataSection title={t('apt')}>{data?.addresses![0]?.apt || t('not_found')}</DataSection>
        )}
      </SafeView>

      <DataSection title={t('locality')}>{data?.addresses?.[0]?.apt || t('not_found')}</DataSection>

      {data?.structure === 'se' ? (
        <a
          href="https://stat.gov.kz"
          target="_blank"
          rel="nofollow"
          className="col-span-full mt-4 text-xs text-gray-500 hover:text-gray-500"
        >
          {t('open_source', {
            name: 'stat.gov.kz',
          })}
        </a>
      ) : null}
      {inView && (
        <div id="map" className="col-span-full h-full w-full">
          <YMaps
            query={{
              ns: 'use-load-option',
              apikey: '60fcd87d-f5b9-465f-8f51-76d8cb2f4aec',
              load: 'package.full',
            }}
          >
            <Map
              width="100%"
              height="400px"
              onLoad={(ymaps) => geocode(ymaps)}
              state={{ center: coordinates, zoom: 15 }}
            >
              <Placemark geometry={coordinates} options={{ preset: 'islands#redDotIcon' }} />
            </Map>
          </YMaps>
        </div>
      )}
    </CollapsibleBlock>
  )
}
