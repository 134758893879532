import { Jurisdiction } from '@/entities/jurisdiction'
import { SafeView } from '@/features'
import { Company } from '@shared/api/types'
import { useTranslations } from 'next-intl'
import { CompanyItem } from './company-item'
import { CompanyItemLoading } from './company-item-loading'

interface CompaniesListProps {
  data: Company[]
  isLoading?: boolean
  jurisdiction: Jurisdiction
  withHighlighter?: boolean
  self?: boolean
  withDefaultLinks?: boolean
  hasNotFound?: boolean
  search?: string
}

const skeletonCount = 15

export const CompaniesList = ({
  data,
  isLoading = false,
  self = false,
  jurisdiction,
  hasNotFound = false,
  search,
  withDefaultLinks,
  withHighlighter,
}: CompaniesListProps) => {
  const t = useTranslations()
  return (
    <>
      <SafeView for={(!Array.isArray(data) || (data.length === 0 && hasNotFound)) && !isLoading}>
        <h2 className="font-stem text-xl font-medium">
          {t('search_not_found')} {':('}
        </h2>
      </SafeView>

      <ul className="space-y-2">
        {Array.isArray(data) &&
          data
            .filter((c) => c.jurisdiction === jurisdiction)
            .map((company) => (
              <CompanyItem
                company={company}
                jurisdiction={jurisdiction}
                self={self}
                search={search}
                key={company.id}
                withDefaultLinks={withDefaultLinks}
                withHighlighter={withHighlighter}
              />
            ))}
        <SafeView for={isLoading}>
          {Array(skeletonCount)
            .fill(0)
            .map((_, i) => (
              <CompanyItemLoading key={i} />
            ))}
        </SafeView>
      </ul>
    </>
  )
}
