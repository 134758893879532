import clsx from 'clsx'
import { useTranslations } from 'next-intl'

export const Tabs = ({
  tabs,
  onTabChange,
  selectedTab,
}: {
  tabs: { id: string; title: string }[]
  onTabChange: (id: string) => void
  selectedTab: string
}) => {
  const t = useTranslations()

  return (
    <ul className="tabs col-span-4 flex items-center justify-center self-center rounded-md bg-gray-100 p-1 text-sm">
      {tabs.map(({ id, title }) => (
        <li
          key={id}
          className={clsx(`cursor-pointer px-4 py-2 duration-300`, {
            'rounded bg-white shadow': selectedTab === id,
            'text-gray-400': selectedTab !== id,
          })}
          onClick={() => onTabChange(id)}
        >
          {t.rich(title, {
            highlighted: (chunk) => <span className="text-medium text-blue-500"> {chunk}</span>,
          })}
        </li>
      ))}
    </ul>
  )
}
