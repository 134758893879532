import clsx from 'clsx'
import { GetServerSidePropsContext } from 'next'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { ReactNode } from 'react'

import { $userProfile } from '@/entities/user'
import { Layout } from '@/ui'
import { loadUserAndTranslates } from 'src/shared/load-user-and-translates'

import styles from './styles.module.css'

const userProfileSections = [
  'personal-area',
  'profile',
  'order-history',
  'payment-history',
  'unloading-history',
  'developers-info',
  'lists-page',
]

export function UserProfileLayout({ children }: { children: ReactNode }) {
  const { route } = useRouter()
  const t = useTranslations()
  const locale = useLocale()

  return (
    <Layout>
      <main className="md:auto-rows-full container mx-auto my-4 grid h-full max-h-full flex-auto grid-cols-7 items-stretch gap-4 font-sans">
        <section className="col-span-full h-full space-y-4 p-4 shadow-md md:col-span-2">
          <ul className="flex w-4/5 flex-col divide-y">
            {userProfileSections.map((section) => (
              <Link locale={locale} href={`/me/${section}`} key={section}>
                <li
                  className={clsx('cursor-pointer', styles.userLayoutLink, {
                    [styles.active]: route.includes(section),
                  })}
                >
                  {t(section)}
                </li>
              </Link>
            ))}
          </ul>
        </section>

        <section className="md:max-h-85 col-span-full grid h-auto auto-rows-min grid-cols-4 gap-4 p-4 shadow-md md:col-span-5 md:h-full md:overflow-auto">
          {children}
        </section>
      </main>
    </Layout>
  )
}

export async function userProfileServerSideProps(ctx: GetServerSidePropsContext) {
  const { values, messages, scope } = await loadUserAndTranslates(ctx)
  const user = scope.getState($userProfile)

  if (!user) {
    return {
      redirect: {
        destination: '/auth/login',
        permanent: false,
      },
    }
  }

  return {
    props: {
      messages: messages,
      values,
    },
  }
}
