import clsx from 'clsx'
import { ReactNode } from 'react'

interface DataSectionProps {
  title: ReactNode
  children?: ReactNode
  colored?: boolean
}
export function DataSection({ title, children, colored = false }: DataSectionProps) {
  return (
    <>
      <div className="field_name col-span-full grid grid-cols-3 md:col-span-2">
        <h3 className="col-span-full mb-0.5 text-base leading-normal text-gray-500">{title}</h3>
      </div>

      <div
        className={clsx('field_result col-span-full grid grid-cols-4 md:col-span-10', {
          'text-primary': colored,
        })}
      >
        <h4 className="col-span-full my-auto font-sans text-base font-normal">{children}</h4>
      </div>
    </>
  )
}
