import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'

import { useEffect, useState } from 'react'

import { Seo } from '@/features'
import { $axiosInstance } from '@/shared-events'
import { CircularProgress, FloatInput } from '@/ui'

import { businessCheckInformation } from './common'
import { AnimationOnIndex, AnimationOnLast } from './ui'

export function BusinessCheck() {
  const t = useTranslations()
  const locale = useLocale()

  const [email, setEmail] = useState<string>('')
  const [phone, setPhone] = useState<string>('')
  const [ques, setQues] = useState<string>('')
  const [load, setLoad] = useState<boolean>(false)
  const [error, setError] = useState<null | string>(null)
  const [success, setSuccess] = useState<string | null>(null)

  const [axios] = useUnit([$axiosInstance])
  const fetch = async () => {
    try {
      if (axios) {
        axios
          .post(`/bot/helper`, {
            phone: phone,
            description: ques,
            email,
          })
          .then(() => {
            setSuccess(t(`excel_status_success`))
            setLoad(() => false)
          })
      }
    } catch (_err) {
      setLoad(() => false)
      setError(t(`for_server_error`))
    }
  }

  useEffect(() => {
    let time: any
    if (success) {
      time = setTimeout(() => {
        setSuccess(null)
      }, 2000)
    }

    return () => {
      clearTimeout(time)
    }
  }, [success])

  const onSubmit = (e: any) => {
    e.preventDefault()

    if (email && phone && ques) {
      setLoad(() => true)

      fetch()
    } else {
      setError(t(`write_in_all_field`))
    }
  }

  return (
    <main className="flex flex-auto flex-col justify-center">
      <Seo title={t(`check_business`)} />
      <div className="mb-20 bg-[#F6F6F4] md:h-[100vh]">
        <div
          className="container mx-auto flex flex-col justify-between gap-5 px-4 md:flex-row md:items-center"
          itemScope
          itemType="https://schema.org/WebSite"
        >
          <meta itemProp="url" content="https://statsnet.co/" />
          <div className="flex h-full w-full flex-col justify-center pt-10 text-left md:h-[90vh] md:w-2/3 md:pt-0 xl:w-3/6">
            <h1 className="mb-6 font-stem text-3xl sm:text-3xl md:text-5xl">{t(`check_business_title`)}</h1>
            <h2 className="text-lg">{t(`check_business_desc`)}</h2>
            <form
              className="relative mt-10 flex flex-col bg-transparent sm:flex-row"
              itemProp="potentialAction"
              itemScope
              itemType="https://schema.org/SearchAction"
            >
              <div className="h-12 w-[170px]">
                <Link locale={locale} href="/auth/sign-up">
                  <button className="btn flex h-full w-full items-center justify-center rounded-3xl bg-blue-500 text-sm text-white transition duration-500 ease-in-out hover:bg-blue-300 focus:border-blue-300 focus:outline-none focus:ring">
                    <span className="break-normal">{t(`create_account`)}</span>
                    <svg className="ml-2" width="7" height="10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1l4 4-4 4" stroke="white" stroke-width="1.5"></path>
                    </svg>
                  </button>
                </Link>
              </div>
            </form>
          </div>
          <div className="flex">
            <AnimationOnIndex />
          </div>
        </div>
      </div>
      <div className="container mx-auto px-4">
        <div className="text-left">
          <span>{t(`possibilities`)}</span>
        </div>
        <h1 className="mb-6 w-full py-5 text-left font-stem text-3xl sm:text-3xl md:w-5/6 md:text-5xl">
          {t(`possibilities_title`)}
        </h1>

        <div className="grid grid-cols-1 justify-between gap-4 md:grid-cols-3">
          {businessCheckInformation.map((item, idx) => {
            return (
              <div key={idx} className="mb-10 flex w-full flex-col items-center break-words text-left md:items-start">
                <Image width={350} height={150} src={item.image} alt={item.title} className="mb-2" />
                <h2 className="w-full py-5 font-medium">{t(item.title)}</h2>
                <p className="break-words text-sm">{t(item.description)}</p>
              </div>
            )
          })}
        </div>
      </div>
      <div className="h-full bg-[#F9F8F4] pb-20">
        <div className="container mx-auto flex h-[500px] flex-col gap-5 px-4 md:flex-row">
          <div className="flex w-full flex-col justify-end text-left md:w-2/6">
            <h1 className="mb-0 w-full py-5 font-stem text-2xl sm:text-3xl md:mb-6 md:w-5/6 md:text-4xl">
              {t(`company_know__title`)}
            </h1>
            <p className="break-words text-lg">{t(`company_know__desc`)}</p>
          </div>
          <div className="flex w-full flex-row justify-end md:w-4/6 md:flex-col">
            <AnimationOnLast className="md:w-[800px]" />
          </div>
        </div>
      </div>
      <div className="container mx-auto mb-10 mt-20 flex flex-col gap-5 px-4 md:mb-0 md:mt-10 md:h-[500px] md:flex-row md:px-20">
        <div className="flex w-full flex-col justify-center text-left md:w-3/6">
          <h1 className="w-full py-5 font-stem text-2xl sm:text-3xl md:w-5/6 md:text-4xl">{t(`api_block_title`)}</h1>
          <p className="break-words text-lg md:pr-20">{t(`api_block_desc`)}</p>
        </div>
        <div className="flex w-full flex-row justify-center md:w-3/6 md:flex-col">
          <Image width={800} height={300} src="/images/9.jpeg" alt="" />
        </div>
      </div>

      <div className="container mx-auto mb-10 flex flex-col justify-between gap-10 px-4 md:h-[500px] md:flex-row md:gap-0 md:px-20">
        <div className="flex w-full flex-col justify-center text-left md:w-3/6">
          <h1 className="w-full py-5 font-stem text-2xl sm:text-3xl md:w-5/6 md:text-4xl">
            Узнайте, что вам не хватает
          </h1>
          <p className="break-words text-lg md:pr-20">
            Сначала мы узнаем о ваших потребностях, ответим на ваши вопросы, а затем посмотрим, как Statsnet может
            помочь.
          </p>
        </div>
        <div className="flex w-full flex-row justify-center md:w-3/6 md:flex-col">
          <form onSubmit={onSubmit} className="flex flex-col gap-5 p-10 shadow-[rgba(7,_65,_210,_0.1)_0px_9px_30px]">
            {success !== null && <p className="text-lime-500">{success}</p>}

            <FloatInput
              label="Email"
              type="email"
              name="email"
              onChange={(e: any) => {
                setEmail(e.target.value)
                setError(null)
              }}
            />

            <FloatInput
              label={t(`phone`)}
              type="text"
              name="phone"
              onChange={(e: any) => {
                setPhone(e.target.value)
                setError(null)
              }}
            />

            <textarea
              rows={5}
              cols={45}
              style={{ resize: 'none' }}
              placeholder={t(`how_we_can_help_you`)}
              onChange={(e) => {
                setQues(e.target.value)
                setError(null)
              }}
              className="
                        block w-full border-b
                        border-gray-300 bg-transparent bg-white p-3.5 text-lg focus-within:border-blue-500 focus:outline-none"
            ></textarea>

            {error !== null && <p className="text-red-500">{error}</p>}
            <button
              className="btn mt-5 w-[150px] rounded-3xl bg-blue-500 p-3.5 text-white outline-none duration-300 hover:bg-blue-600 focus:outline-none  disabled:bg-gray-400"
              type="submit"
              disabled={load}
            >
              {load ? (
                <div className="mx-auto h-4 w-4">
                  <CircularProgress />
                </div>
              ) : (
                t('send_feedback')
              )}
            </button>
          </form>
        </div>
      </div>
    </main>
  )
}
