import { cn } from '@/lib/shadcn'
import { ButtonProps, buttonVariants } from '@shared/ui/shadcn-button'
import { ChevronLeft, ChevronRight, MoreHorizontal } from 'lucide-react'
import { useRouter } from 'next/router' // Import useRouter from Next.js
import * as React from 'react'
import { useCallback, useMemo } from 'react'

const Pagination = ({ className, ...props }: React.ComponentProps<'nav'>) => (
  <nav
    role="navigation"
    aria-label="pagination"
    className={cn('mx-auto flex w-full justify-center', className)}
    {...props}
  />
)
Pagination.displayName = 'Pagination'

const PaginationContent = React.forwardRef<HTMLUListElement, React.ComponentProps<'ul'>>(
  ({ className, ...props }, ref) => (
    <ul ref={ref} className={cn('flex flex-row items-center gap-1', className)} {...props} />
  ),
)
PaginationContent.displayName = 'PaginationContent'

const PaginationItem = React.forwardRef<HTMLLIElement, React.ComponentProps<'li'>>(({ className, ...props }, ref) => (
  <li ref={ref} className={cn('', className)} {...props} />
))
PaginationItem.displayName = 'PaginationItem'

type PaginationLinkProps = {
  isActive?: boolean
} & Pick<ButtonProps, 'size'> &
  React.ComponentProps<'a'>

const PaginationLink = ({ className, isActive, size = 'icon', ...props }: PaginationLinkProps) => (
  <a
    aria-current={isActive ? 'page' : undefined}
    className={cn(
      buttonVariants({
        variant: isActive ? 'outline' : 'ghost',
        size,
      }),
      className,
    )}
    {...props}
  />
)
PaginationLink.displayName = 'PaginationLink'

const PaginationPrevious = ({
  className,
  showLabel,
  ...props
}: React.ComponentProps<typeof PaginationLink> & { showLabel?: boolean }) => (
  <PaginationLink
    aria-label="Go to previous page"
    size="default"
    className={cn(
      'gap-1',
      {
        'pl-2.5': showLabel,
      },
      className,
    )}
    {...props}
  >
    <ChevronLeft className="h-4 w-4" />
    {showLabel && <span>Previous</span>}
  </PaginationLink>
)
PaginationPrevious.displayName = 'PaginationPrevious'

const PaginationNext = ({
  className,
  showLabel,
  ...props
}: React.ComponentProps<typeof PaginationLink> & { showLabel?: boolean }) => (
  <PaginationLink
    aria-label="Go to next page"
    size="default"
    className={cn(
      'gap-1',
      {
        'pr-2.5': showLabel,
      },
      className,
    )}
    {...props}
  >
    {showLabel && <span>Next</span>}
    <ChevronRight className="h-4 w-4" />
  </PaginationLink>
)
PaginationNext.displayName = 'PaginationNext'

const PaginationEllipsis = ({ className, ...props }: React.ComponentProps<'span'>) => (
  <span aria-hidden className={cn('flex h-9 w-9 items-center justify-center', className)} {...props}>
    <MoreHorizontal className="h-4 w-4" />
    <span className="sr-only">More pages</span>
  </span>
)
PaginationEllipsis.displayName = 'PaginationEllipsis'

type PaginationViewProps = {
  totalCount: number
  pageSize: number
  onPageChange: (p: number) => void
  currentPage: number
  showPrevNextLabels?: boolean
  maxVisiblePages?: number
}

export const PaginationViewStates = ({
  totalCount,
  onPageChange,
  currentPage: page,
  pageSize,
  showPrevNextLabels = false,
  maxVisiblePages = 6,
}: PaginationViewProps) => {
  const router = useRouter() // Initialize router
  const pages = useMemo(
    () => Array.from({ length: Math.ceil(totalCount / pageSize) }, (_, i) => i + 1),
    [totalCount, pageSize],
  )
  const pageNumLimit = Math.floor(maxVisiblePages / 2) // Number of pages to show before and after the current page
  const hasNextPage = page < pages.length
  const hasPrevPage = page > 1

  const startPage = Math.max(1, page - pageNumLimit)
  const endPage = Math.min(pages.length, page + pageNumLimit)

  const handlePageChange = (pageNumber: number) => {
    onPageChange(pageNumber)
    router.push({
      pathname: router.pathname,
      query: { ...router.query, page: pageNumber },
    })
  }

  const renderPages = useCallback(() => {
    const renderedPages = []

    // Show first page link and potentially an ellipsis if needed
    if (startPage > 1) {
      renderedPages.push(
        <PaginationItem key={1}>
          <PaginationLink isActive={page === 1} onClick={() => handlePageChange(1)}>
            1
          </PaginationLink>
        </PaginationItem>,
      )
      if (startPage > 2) {
        renderedPages.push(<PaginationEllipsis key="ellipsis-start" />)
      }
    }

    // Render pages between startPage and endPage
    for (let i = startPage; i <= endPage; i++) {
      renderedPages.push(
        <PaginationItem key={i}>
          <PaginationLink isActive={i === page} onClick={() => handlePageChange(i)}>
            {i}
          </PaginationLink>
        </PaginationItem>,
      )
    }

    // Show last page link and potentially an ellipsis if needed
    if (endPage < pages.length) {
      if (endPage < pages.length - 1) {
        renderedPages.push(<PaginationEllipsis key="ellipsis-end" />)
      }
      renderedPages.push(
        <PaginationItem key={pages.length}>
          <PaginationLink isActive={page === pages.length} onClick={() => handlePageChange(pages.length)}>
            {pages.length}
          </PaginationLink>
        </PaginationItem>,
      )
    }

    return renderedPages
  }, [pages, page, handlePageChange, startPage, endPage])

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem onClick={() => handlePageChange(page - 1)} aria-disabled={!hasPrevPage}>
          <PaginationPrevious showLabel={showPrevNextLabels} aria-disabled={!hasPrevPage} />
        </PaginationItem>

        {renderPages()}

        <PaginationItem onClick={() => handlePageChange(page + 1)} aria-disabled={!hasNextPage}>
          <PaginationNext showLabel={showPrevNextLabels} aria-disabled={!hasNextPage} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  )
}
