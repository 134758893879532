import { createStore, sample } from 'effector'

import { loadCompanyEventsFx } from '@/api'
import { createBindingForVisibility } from '@/features'

import { ResponseType } from 'src/shared/api/model'

export const { visibilityChanged, $inView, $isLoaded, $companyId } = createBindingForVisibility()
export const $registrationHistory = createStore<ResponseType<'/business/{id}/events', 'get'>>({})

sample({
  clock: $inView,
  source: { isLoaded: $isLoaded, id: $companyId },
  filter: ({ isLoaded, id }, inView) => inView && !isLoaded && !!id,
  fn: ({ id }) => ({
    path: {
      id: id as string,
    },
  }),
  target: [loadCompanyEventsFx],
})

sample({
  clock: loadCompanyEventsFx.doneData,
  target: $registrationHistory,
})

sample({
  clock: loadCompanyEventsFx.done,
  fn: () => true,
  target: $isLoaded,
})
