import { createEvent, createStore, sample } from 'effector'
import { createForm } from 'effector-forms'
import * as yup from 'yup'

import { $signupErrorData, signUpUserFx } from '@/api'
import { loadAuthenticatedUser } from '@/entities/user'
import { createRule } from '@/lib/effector-forms'

import tg from '@/lib/type-guards'
import { $router, pushFx } from '@/shared-events'

export const $$signUpForm = createForm<{
  email: string
  name: string
  phone: string
  company: string
  password: string
}>({
  fields: {
    email: {
      init: '',
      rules: [
        createRule<string>({
          schema: yup.string().test('custom-email', 'email_invalid', tg.isEmail),
          name: 'email_invalid',
        }),
      ],
    },
    name: {
      init: '',
      rules: [
        createRule<string>({
          schema: yup.string().test('custom-name', 'first_name_invalid', tg.isCorrectName),
          name: 'first_name_invalid',
        }),
        createRule<string>({
          schema: yup.string().test('name-capitalize', 'first_name_should_upper_case', tg.isCapitalize),
          name: 'first_name_should_upper_case',
        }),
      ],
    },
    phone: {
      init: '+7',
      rules: [
        createRule<string>({
          schema: yup.string().test('custom-phone', 'phone_invalid', tg.isPhone),
          name: 'phone_invalid',
        }),
      ],
    },
    company: {
      init: '',
    },
    password: {
      init: '',
      rules: [
        createRule<string>({
          schema: yup
            .string()
            .test('custom-password-length', 'password_length_low', tg.isCorrectPasswordLength)
            .required(),
          name: 'password_length_low',
        }),
        createRule({
          schema: yup.string().test('custom-password-valid', 'password_invalid', tg.isValidPassword),
          name: 'password_invalid',
        }),
      ],
    },
  },
})

export const $redirectURI = createStore('/me/personal-area')
export const setRedirectURI = createEvent<string>()

sample({
  clock: setRedirectURI,
  source: $redirectURI,
  fn: (uri) => {
    if (!uri) {
      $redirectURI.reset()
      return $redirectURI.getState()
    }
    return uri
  },
  target: $redirectURI,
})

sample({
  clock: $$signUpForm.formValidated,
  source: $router,
  fn: (router, formData) => ({
    data: {
      ...formData,
      language: router?.locale || 'ru',
      phone: formData.phone.replace('+', ''),
      ref_id: router?.query?.ref_id?.toString() || '',
    },
  }),
  target: signUpUserFx,
})

sample({
  //@ts-ignore
  clock: signUpUserFx.doneData,
  target: [loadAuthenticatedUser],
})

sample({
  clock: signUpUserFx.doneData,
  source: $redirectURI,
  fn: (uri) => uri,
  target: pushFx,
})

sample({
  clock: signUpUserFx.doneData,
  fn: () => $redirectURI.reset(),
})

//@ts-ignore
$signupErrorData.reset($$signUpForm.$values.updates)
