// @ts-nocheck
import { PaginationViewStates } from '@shared/ui/pagination-states'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'

import { Jurisdiction } from '@/entities/jurisdiction'
import { SafeView, Seo } from '@/features'
import { CompaniesList } from '@/ui'
import { Breadcrumbs } from '@/widgets'

import { $companiesByState, $stateName, $totalCompanies } from './model'

export function CompaniesByAreaScreen() {
  const { query, push, pathname } = useRouter()
  const { state, page = 1, area } = query
  const t = useTranslations()
  const [stateName, totalCompanies, companiesByState] = useUnit([$stateName, $totalCompanies, $companiesByState])

  const onPageChange = (page: number) => {
    const nextPage = page
    if (nextPage === 1 && +page !== 1) {
      push(`/states/${state}/${area}`)
      return
    }

    if (nextPage !== 1) {
      push({
        pathname: pathname,
        query: { ...query, page: nextPage },
      })
      return
    }
  }

  return (
    <main className="container mx-auto flex flex-auto flex-col items-start">
      <Seo
        title={
          page === 1
            ? t(`areas_title_first_page`, {
                area: stateName,
              })
            : page
              ? t(`areas_title`, {
                  area: stateName,
                  page: +page || 1,
                })
              : t(`areas_title_first_page`, {
                  area: stateName,
                })
        }
        description={t(`areas_desc`, {
          city: stateName,
        })}
        area={area as string}
      />
      <div className="my-4">
        <Breadcrumbs area={stateName} />
      </div>

      <h1 className="mb-5 space-x-2 font-stem text-2xl sm:text-3xl md:text-3xl">
        <span>
          {t('companies')} {stateName}
        </span>
        <SafeView for={page !== 1}>
          <span>
            — {t('page')} {page}
          </span>
        </SafeView>
      </h1>
      <h2 className="text-gray-500">
        <span className="mr-2">{t('found', { count: totalCompanies })}</span>
      </h2>

      {/* @ts-ignore*/}
      <CompaniesList data={companiesByState} jurisdiction={state as Jurisdiction} />

      <div className="my-6">
        <PaginationViewStates
          totalCount={totalCompanies}
          pageSize={20}
          onPageChange={onPageChange}
          currentPage={+page}
        />
      </div>
    </main>
  )
}
