import clsx from 'clsx'
import { HTMLInputTypeAttribute, InputHTMLAttributes, ReactNode, SelectHTMLAttributes } from 'react'

import styles from './styles.module.css'

export interface FloatInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: ReactNode
  type: HTMLInputTypeAttribute
  name: string
  className?: string
}

export interface FloatSelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  label: ReactNode
  type: HTMLInputTypeAttribute
  name: string
  className?: string
}
export const FloatInput = ({ label, name, onChange, value, className = '', ...inputProps }: FloatInputProps) => {
  return (
    <div className={clsx(styles.floatInputWrapper, className)}>
      <input
        className={clsx('peer', styles.floatInput)}
        name={name}
        value={value}
        onChange={onChange}
        placeholder=" "
        id={name}
        {...inputProps}
      />
      <label
        htmlFor={name}
        className={clsx(
          'absolute left-0 top-0 mx-3.5 translate-y-0 bg-white text-xs duration-100 ease-linear peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-lg',
          'peer-focus:p5-3.4 peer-placeholder-shown:text-gray-400 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-xs peer-focus:text-blue-500',
        )}
      >
        {label}
      </label>
    </div>
  )
}

export const FloatSelect = ({
  label,
  name,
  onChange,
  value,
  className = '',
  children = null,
  ...inputProps
}: FloatSelectProps) => {
  return (
    <div className={clsx(styles.floatInputWrapper, className)}>
      <select
        className={clsx('peer', styles.floatInput)}
        name={name}
        value={value}
        onChange={onChange}
        id={name}
        {...inputProps}
      >
        {children}
      </select>
      <label
        htmlFor={name}
        className={clsx(
          'absolute left-0 top-0 mx-3.5 translate-y-0 bg-white text-xs duration-100 ease-linear peer-placeholder-shown:top-1/2 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:text-lg',
          'peer-focus:p5-3.4 peer-placeholder-shown:text-gray-400 peer-focus:top-0 peer-focus:-translate-y-0 peer-focus:text-xs peer-focus:text-blue-500',
        )}
      >
        {label}
      </label>
    </div>
  )
}
