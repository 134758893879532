import { useStoreMap, useUnit } from 'effector-react'
import { remove } from 'lodash'
import { useTranslations } from 'next-intl'

import { SafeView } from '@/features'
import { CollapsibleBlock } from '@/widgets'

import { ForCompanyOrIndividualProps } from './types'

const Codes = ({
  title,
  list,
  jurisdiction,
}: {
  title: string
  list: Record<string, any>[]
  jurisdiction?: string
}) => {
  const t = useTranslations()
  return (
    <SafeView for={list} withProps>
      {(codes) => (
        <>
          <h4 className="kind_of_activity col-span-full font-bold">
            {t(title)} {jurisdiction === 'uz' ? 'ОКВЭД' : 'ОКЭД'}
          </h4>
          {codes.map((code) => (
            <div key={code.code} className="col-span-full mb-4 grid grid-cols-12 gap-0 font-normal">
              <span className="col-span-2 sm:col-span-1">{code.code}</span>
              <span className="col-span-10 sm:col-span-11">{code.description}</span>
            </div>
          ))}
        </>
      )}
    </SafeView>
  )
}

export function ActivityType({ for: $data }: ForCompanyOrIndividualProps) {
  const [data] = useUnit([$data])
  const t = useTranslations()
  const [mainIndustryCodes, primaryIndustryCodes] = useStoreMap({
    store: $data,
    fn: (data) => {
      const industryCodes = [...(data?.industry_codes || [])]
      return [remove(industryCodes || [], (v) => !!v.is_main), remove(industryCodes || [], (v) => !v.is_main)]
    },
    keys: [$data],
  })

  return (
    <CollapsibleBlock className="select-none" title={t('kind_of_activity')} dataType="activity_type" id="activity_type">
      <Codes title={'main_activity'} list={mainIndustryCodes} jurisdiction={data?.jurisdiction} />
      <Codes title={'additional_activities'} list={primaryIndustryCodes} jurisdiction={data?.jurisdiction} />
    </CollapsibleBlock>
  )
}
