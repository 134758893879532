import clsx from 'clsx'
import { ChangeEventHandler, ReactNode, useState } from 'react'

import { SafeView } from '@/features'

import { Input, InputProps } from '../input'

type AsyncInputProps<T = Array<any>> = Omit<InputProps, 'children'> & {
  asyncEvent: (v: string) => void
  data: T
  loading?: boolean
  children: (data: T) => ReactNode
  popupClassName?: string
  inputClassName?: string
  showResults?: boolean
}

export function AsyncInput<T = Record<string, unknown>[]>({
  asyncEvent,
  onChange,
  data,
  loading,
  children,
  popupClassName,
  inputClassName,
  showResults,
  ...props
}: AsyncInputProps<T>) {
  const [popupOpened, setPopupOpened] = useState<boolean>(false)
  const handleOnChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setPopupOpened(true)
    onChange?.(e)

    const value = e.target.value

    asyncEvent(value)
    if (value.length === 0) setPopupOpened(false)
  }

  return (
    <>
      <Input onChange={handleOnChange} className={inputClassName} {...props} />
      <SafeView for={showResults && popupOpened} otherwise={null}>
        <ul
          className={clsx(
            'absolute z-50 flex w-full flex-col overflow-auto rounded-b-md bg-white shadow-md',
            popupClassName,
          )}
        >
          {children(data)}
        </ul>
      </SafeView>
    </>
  )
}
