import clsx from 'clsx'
import { InputHTMLAttributes, useEffect, useState } from 'react'

export interface InputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'placeholder'> {
  label?: string
  placeholder?: string | string[]
}

export const Input = ({ onChange, value, label, name, className, ...rest }: InputProps) => {
  const placeholders: (string | undefined)[] =
    rest.placeholder && Array.isArray(rest.placeholder)
      ? rest.placeholder
      : rest.placeholder && typeof rest.placeholder === 'string'
        ? [rest.placeholder]
        : [undefined]

  const isPlaceholderMayAnimate = placeholders.length > 1

  return (
    <div className="relative flex flex-col">
      {label && <label htmlFor={name}>{label}</label>}
      {!value && isPlaceholderMayAnimate && (
        <VerticalStringsCarousel items={placeholders as string[]} interval={4000} />
      )}
      <input
        onChange={onChange}
        value={value}
        name={name}
        className={clsx('w-full', className)}
        {...rest}
        placeholder={isPlaceholderMayAnimate ? undefined : placeholders[0]}
      />
    </div>
  )
}

const VerticalStringsCarousel = ({ items, interval = 2000 }: { items: string[]; interval?: number }) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % items.length)
    }, interval)

    return () => clearInterval(timer)
  }, [items, interval])

  return (
    <div className="absolute w-full h-full text-gray-400 pointer-events-none select-none">
      <div className="w-full h-full relative overflow-hidden px-4">
        <div
          className="flex flex-col transition-transform duration-300"
          style={{ transform: `translateY(-${currentIndex * 3}rem)` }}
        >
          {items.map((item, index) => (
            <div key={index} className="h-12 flex items-center overflow-hidden whitespace-nowrap">
              {item}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
