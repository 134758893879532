/* eslint-disable prettier/prettier */
import { useTranslations } from 'next-intl'
import React from 'react'

interface ModalProps {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
  children: React.ReactNode
}

const Refund_Modal: React.FC<ModalProps> = ({ isOpen, onClose, children, onSubmit }) => {
  const t = useTranslations()
  if (!isOpen) return null

  return (
    <div
      className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 p-4"
      onClick={onClose}
      style={{ zIndex: '1000' }}
    >
      <div className="w-full max-w-md rounded-lg bg-white p-6 shadow-lg" onClick={(e) => e.stopPropagation()}>
        {children}
        <div className="flex items-center justify-between">
          <button className="rounded bg-red-500 px-4 py-2 text-white hover:bg-red-700" onClick={onSubmit}>
            {t('accept_refund')}
          </button>
          <button className="rounded bg-blue-500 px-4 py-2 text-white hover:bg-blue-700" onClick={onClose}>
            {t('close')}
          </button>
        </div>
      </div>
    </div>
  )
}

export default Refund_Modal
