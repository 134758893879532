import { createEvent, createStore, sample } from 'effector'

import { loadCompaniesByStateFx } from '@/api'
import { RequestParams, UnwrapApiResponse } from 'src/shared/api/model'

export const $companiesByState = createStore<UnwrapApiResponse<`/business/jurisdiction/state`, 'post'>['company']>([])
export const $totalPages = createStore<number>(0)
export const $stateName = createStore<string>('')
export const $totalCompanies = createStore<number>(0)

export const loadCompaniesByState = createEvent<{
  data: RequestParams<`/business/jurisdiction/state`, 'post'>
  headers: Record<string, any>
}>()

sample({
  clock: loadCompaniesByState,
  fn: (params) => {
    return {
      data: {
        ...params.data,
      },
      headers: params.headers,
      query: {
        limit: 20,
        page: params.data.page,
      },
    }
  },
  target: loadCompaniesByStateFx,
})

sample({
  clock: loadCompaniesByStateFx.doneData,
  fn: ({ company }) => company,
  target: $companiesByState,
})

sample({
  clock: loadCompaniesByStateFx.failData,
  fn: (error) => {
    console.error(error.message) // Виводимо текст помилки в консоль
    return '' // Повертаємо пустий рядок
  },
})

sample({
  clock: loadCompaniesByStateFx.doneData,
  //@ts-ignore
  fn: (response) => response.count / 15,
  target: $totalPages,
})

sample({
  clock: loadCompaniesByStateFx.doneData,
  fn: ({ state }) => state?.state ?? '',
  target: $stateName,
})

sample({
  clock: loadCompaniesByStateFx.doneData,
  fn: (response) => response.pagination_count as number,
  target: $totalCompanies,
})
