import clsx from 'clsx'

import styles from './styles.module.css'
export const CircularProgress = ({ color = 'primary' }: { color?: 'primary' | 'white' }) => {
  return (
    <div className={clsx(styles.ldsRing, color)}>
      <div className={styles[color]}></div>
      <div className={styles[color]}></div>
      <div className={styles[color]}></div>
      <div className={styles[color]}></div>
    </div>
  )
}
