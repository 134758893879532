import _ from 'lodash'

const renderLink = (href: string, text: string, key: string | number) => (
  <a key={key} className="text-primary" href={href} target="_blank" rel="nofollow">
    {text || '-'}
  </a>
)

const renderNameOrSpan = (id: string, name: string, jurisdiction: string) => {
  return id ? renderLink(`/companies/${jurisdiction}/${id}`, name, id) : <span>{name || '-'}</span>
}

export const renderTableData = (
  person: boolean | undefined,
  connections: any,
  all: any,
  jurisdiction: string,
  t: any,
) =>
  _.chain(connections)
    .map((e: Record<any, any>, idx) => {
      if (e.type === 'entry') return null

      const connection_type = e.connection || e.connection_type

      return {
        company: renderNameOrSpan(
          person ? e.company_id : e.id,
          e.company_name || e.name,
          e.jurisdiction || jurisdiction,
        ),
        connection_type: connection_type ? t.raw('connections_filters')[connection_type] : '-',
        parent: e.target_id
          ? renderLink(
              person
                ? `/companies/${e.jurisdiction || jurisdiction}/${e.target_id}`
                : e.connection_hash_identifier
                  ? `/individuals?identifier=${e.connection_hash_identifier}`
                  : `/companies/${e.jurisdiction || jurisdiction}/${e.target_id}`,
              e.target_name,
              idx,
            )
          : renderLink(
              `/companies/${e.jurisdiction || jurisdiction}/${person ? e.source_key : e.connection_id}`,
              person ? e.source_name : e.connection_name,
              idx,
            ),
      }
    })
    .value()
