import { allSettled, fork } from 'effector'
import { NextRequest, NextResponse } from 'next/server'

import { $refreshToken, $userToken, clientCookiesSet, refreshSet } from '@/shared-events'

export type TokenGeneric = {
  exp: number
  id: number
  role: string
}

export function parseJwt(token: string): TokenGeneric | null {
  try {
    return JSON.parse(Buffer.from(token.split('.')[1], 'base64').toString('utf-8'))
  } catch (e) {
    return null
  }
}

export function isTokenValid(token: string): boolean {
  if (!token) return false
  const nowUnix = (+new Date() / 1e3) | 0
  const decodedToken = parseJwt(token)
  if (decodedToken === null) return false
  return decodedToken.exp > nowUnix
}

export const config = {
  matcher: ['/((?!api|_next/static|_next/image|favicon.ico|fonts|images).*)'],
}

export async function middleware(req: NextRequest) {
  const { cookies } = req
  const nowUnix = (+new Date() / 1e3) | 0

  const token = cookies?.get('access')
  const refresh = cookies?.get('refresh')

  const newResponse = NextResponse.next()
  let tokenIsValid = isTokenValid(token?.value as string)

  if (!!refresh && !tokenIsValid) {
    const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/user/me/refresh`, {
      body: JSON.stringify({
        refresh_token: refresh.value,
      }),
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'POST',
    })
    if (!response.ok) {
      return newResponse
    }

    const { access_token, refresh_token } = await response.json()

    const access_token_decoded: { exp: number } = parseJwt(access_token) as TokenGeneric
    const refresh_token_decoded: { exp: number } = parseJwt(refresh_token) as TokenGeneric

    const scope = fork({
      values: [
        [$userToken, access_token],
        [$refreshToken, refresh_token as string],
      ],
    })

    await allSettled(clientCookiesSet, {
      params: access_token,
      scope,
    })

    await allSettled(refreshSet, {
      params: refresh_token,
      scope,
    })

    newResponse.cookies.set('access', access_token, {
      path: '/',
      maxAge: access_token_decoded.exp - nowUnix,
    })

    newResponse.cookies.set('refresh', refresh_token, {
      path: '/',
      maxAge: refresh_token_decoded.exp - nowUnix,
      httpOnly: true,
    })

    tokenIsValid = true
  }

  return newResponse
}
