import { Store } from 'effector'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { SafeView } from '@/features'
import { CompaniesList } from '@/ui'
import { StickyForm } from '@/widgets'
import { ResponseType } from 'src/shared/api/model'

export function Alternatives({
  alternatives: $alternatives,
  isFull: $isFull,
  data: $data,
}: {
  alternatives: Store<
    | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['alternatives']
    | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['alternatives']
  >
  data: Store<
    | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['company']
    | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['company']
  >
  isFull: Store<boolean>
}) {
  const [isFull, alternatives, data] = useUnit([$isFull, $alternatives, $data])
  const { ref, inView } = useInView({
    threshold: 0,
  })
  const [hideSimilar, setHideSimilar] = useState(false)

  const t = useTranslations()
  return (
    <>
      <SafeView for={!isFull && !inView}>
        <StickyForm />
      </SafeView>

      <div ref={ref} className="row-auto select-none mt-6 grid grid-cols-6" datatype="similar_companies">
        {alternatives ? (
          <>
            <h2
              className={`'text-xl relative col-span-full mb-4 border-b pb-2 font-sans font-semibold md:col-span-4`}
              onClick={() => setHideSimilar((prev) => !prev)}
            >
              {t('similar_companies')}
              <span className="ml-2 text-sm font-normal">{alternatives.length}</span>
              <span className={`triangle ${hideSimilar && 'rotated'}`}></span>
            </h2>

            <div className="col-span-6">
              {!hideSimilar && (
                <CompaniesList withDefaultLinks data={alternatives as any} jurisdiction={data?.jurisdiction as any} />
              )}
            </div>
          </>
        ) : null}
      </div>
    </>
  )
}
