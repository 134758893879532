import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { merge } from 'lodash'

import { useTranslations } from 'next-intl'

import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { $individual } from '@/screens'

import styles from 'src/screens/company/ui/main-information/styles.module.css'
import { TableSection } from 'src/screens/company/ui/main-information/ui'

function Officer({ officer, inactive }: { officer: Record<string, any>; inactive?: boolean }) {
  const individual = useUnit($individual)
  const redirectToShareholderCompany = (person: boolean, id: string | number, jurisdiction?: string) => {
    if (id && !person && jurisdiction) {
      window.open(`/companies/${jurisdiction}/${id}`, '_blank', 'noopener,noreferrer')
    }
  }

  const t = useTranslations()
  return (
    <div
      className={clsx(`block w-full cursor-pointer text-primary hover:text-flamingo`, styles.officers)}
      onClick={() => redirectToShareholderCompany(false, officer.id, officer.jurisdiction)}
    >
      <span
        className={clsx({
          'text-gray-500 line-through': inactive,
        })}
      >
        {officer?.name}
      </span>

      <SafeView for={officer.identifier} withProps>
        {(identifier) =>
          individual.jurisdiction ? (
            <span className={`text-gray-500 hover:text-gray-500`}>
              {' '}
              · {t.raw('identifier')[individual.jurisdiction]?.individual} · {identifier}
            </span>
          ) : (
            <span className={`text-gray-500 hover:text-gray-500`}> · {identifier}</span>
          )
        }
      </SafeView>

      <SafeView for={officer.role !== 'ceo'}>
        <span className="text-gray-500 hover:text-gray-500">{t.raw('officers_role')[officer.role]}</span>
      </SafeView>
    </div>
  )
}

function Shareholder({ shareholder, inactive }: { shareholder: Record<string, any>; inactive?: boolean }) {
  const t = useTranslations()
  const individual = useUnit($individual)
  const redirectToShareholderCompany = (person: boolean, id: string | number, jurisdiction?: string) => {
    if (id && !person && jurisdiction) {
      window.open(`/companies/${jurisdiction}/${id}`, '_blank', 'noopener,noreferrer')
    } else {
      window.open(`/individuals/${id}`, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <div onClick={() => redirectToShareholderCompany(shareholder.is_person, shareholder.id, shareholder.jurisdiction)}>
      <span className={`cursor-pointer text-primary hover:text-flamingo`}>
        <span
          className={clsx({
            'text-gray-500 line-through': inactive,
          })}
        >
          {shareholder.is_person ? fmt.toProperCase(shareholder.name) : shareholder.name}
        </span>

        {shareholder.identifier ? (
          individual.jurisdiction ? (
            <span className={`text-gray-500 hover:text-gray-500`}>
              &nbsp;·&nbsp;
              {
                t.raw('identifier')[individual.jurisdiction][
                  !shareholder.is_person
                    ? 'legal'
                    : shareholder.is_person && individual.jurisdiction === 'ru'
                      ? 'ИИН'
                      : 'individual'
                ]
              }
              &nbsp;
              {shareholder.identifier}
            </span>
          ) : (
            <span className={`text-gray-500 hover:text-gray-500`}>
              &nbsp;·&nbsp;
              {shareholder.identifier}
            </span>
          )
        ) : null}

        <SafeView for={shareholder.share}>
          <span className="text-gray-500 hover:text-gray-500">
            &nbsp;·&nbsp;
            {shareholder.share + '%'}
          </span>
        </SafeView>

        <SafeView for={shareholder.start_date} withProps>
          {(date) => (
            <span className="text-gray-500 hover:text-gray-500">
              &nbsp;·&nbsp;
              {fmt.date(new Date(date))}
            </span>
          )}
        </SafeView>
      </span>
    </div>
  )
}

export function IndividualMainInfo() {
  const individual = useUnit($individual)
  const t = useTranslations()

  const [ceo, inactive, _chief, shareholders, shareholdersInactive] = useStoreMap({
    store: $individual,
    fn: (person) => {
      const officersCeo: any[] = []
      const officersInactive: any[] = []
      const officersChief: any[] = []
      const officersChiefInactive: any[] = []
      const shareholders: any[] = []
      const shareholdersInactive: any[] = []

      person?.shareholders?.forEach((shareholder: any) => {
        if (shareholder.source_inactive) {
          shareholdersInactive.push(shareholder)
        } else {
          shareholders.push(shareholder)
        }
      })

      person?.officers?.forEach((officer: any) => {
        if (officer.source_inactive && officer.role !== 'chief_accountant') {
          officersInactive.push({
            ...officer,
            role: 'ceo',
          })
        }
        if (officer.role === 'chief_accountant' && !officer.source_inactive) {
          officersChief.push(officer)
        }
        if (officer.role === 'chief_accountant' && officer.source_inactive) {
          officersChiefInactive.push(officer)
        }
        if (officer.role !== 'chief_accountant' && !officer.source_inactive) {
          officersCeo.push(officer)
        }
      })

      return [
        officersCeo,
        officersInactive,
        merge(officersChief, officersChiefInactive),
        shareholders,
        shareholdersInactive,
      ]
    },
    keys: [individual?.identifier],
  })

  if (!individual) {
    return null // or a loading indicator / fallback UI
  }

  return (
    <div className="max-w-screen lg:max-w-screen relative mt-6" id="info" datatype="information">
      <table className={styles.mainInfoTable}>
        <tbody className="flex flex-col md:gap-2">
          <SafeView for={individual.name_en}>
            <TableSection title={t('name_en')}>{individual.name_en}</TableSection>
          </SafeView>

          <TableSection title={t('officer_in_company')}>
            <SafeView for={ceo}>
              {ceo.map((of, idx) => (
                <Officer officer={of} key={idx} />
              ))}
            </SafeView>
          </TableSection>

          <SafeView for={inactive}>
            <TableSection title={t('was_officer')}>
              {inactive.map((of, idx) => (
                <Officer officer={of} key={idx} inactive />
              ))}
            </TableSection>
          </SafeView>

          <SafeView for={shareholders}>
            <TableSection title={t('shareholder_in_company')}>
              {shareholders.map((shareholder, idx) => (
                <Shareholder shareholder={shareholder} key={idx} />
              ))}
            </TableSection>
          </SafeView>

          <SafeView for={shareholdersInactive}>
            <TableSection title={t('was_shareholder')}>
              {shareholdersInactive.map((shareholder, idx) => (
                <Shareholder shareholder={shareholder} key={idx} inactive />
              ))}
            </TableSection>
          </SafeView>
        </tbody>
      </table>
    </div>
  )
}
