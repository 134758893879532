import { ROUTES } from '@/constants'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'

export interface SubscriptionBannerProps {
  textKey: string
  count?: number
  to?: string
  dateEnd?: string
  days?: number
}

export const SubscriptionBanner = ({
  textKey = '',
  to = ROUTES.PRICING,
  count = 0,
  days = 0,
  dateEnd = '',
}: SubscriptionBannerProps) => {
  const t = useTranslations()
  const locale = useLocale()

  return (
    <div className="flex h-14 w-full items-center justify-center bg-[#acc6d8] font-stem font-medium text-white md:h-10">
      <Link locale={locale} className="banner-link p-3 text-center text-base md:p-0" rel="nofollow" href={to}>
        {t(textKey, {
          count,
          dateEnd,
          days,
        })}
      </Link>
    </div>
  )
}
