export const businessCheckInformation = [
  {
    title: 'auto_process_title',
    description: 'auto_process_desc',
    image: '/images/business/1.svg',
  },
  {
    title: 'court_cases_title',
    description: 'court_cases_title_desc',
    image: '/images/business/5.svg',
  },
  {
    title: 'bankruptcy_title',
    description: 'bankruptcy_desc',
    image: '/images/business/6.svg',
  },
  {
    title: 'documents_title',
    description: 'documents_desc',
    image: '/images/business/2.svg',
  },
  {
    title: 'in_internet_title',
    description: 'in_internet_desc',
    image: '/images/business/3.svg',
  },
  {
    title: 'monitoring_title',
    description: 'monitoring_desc',
    image: '/images/business/4.svg',
  },
  {
    title: 'risks_title',
    description: `risks_desc`,
    image: '/images/business/7.svg',
  },
  {
    title: 'manual_review_title',
    description: 'manual_review_desc',
    image: '/images/business/8.svg',
  },
]
