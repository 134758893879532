//@ts-nocheck
import { SafeView } from '@/features'
import { Placeholder, VirtualizedTable } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'
import { SetRequired } from 'type-fest'
import { renderTableData } from '../connections/common'
import { $courtCases, $courtCasesBar } from '../court-cases/model'
import { $browser, visibilityChanged } from './model'

export function BrowserActivity({ for: $data, isFull: $isFull }: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) {
  const t = useTranslations()
  const [filterValue] = useState<string | null>(null)

  const [onVisible, data, courtCasesBar, courtCases, isFull, browserData] = useUnit([
    visibilityChanged,
    $data,
    $courtCasesBar,
    $courtCases,
    $isFull,
    $browser,
  ])

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (visible) =>
      onVisible({
        visible,
        id: data?.id,
      }),
  })

  //@ts-ignore
  const totalBrowser = browserData?.length

  const transformedList = useMemo(() => {
    if (!browserData) return []
    if (!browserData.length) return []

    return browserData.map((item) => ({
      browser_title: item.title || '-',
      browser_url: item.url || '-',
      browser_descr: item.description || '-',
    }))
  }, [browserData])

  const list = useMemo(
    //@ts-ignore
    () => renderTableData(courtCases?.court_cases, t, filterValue),
    [courtCases?.court_cases, t],
  )

  return (
    <CollapsibleBlock
      title={
        <>
          {t('browser_info')}
          <span className="ml-2 text-sm font-normal">{totalBrowser}</span>
        </>
      }
      dataType="broswer_info"
      id="browsers"
      ref={ref}
    >
      <SafeView for={!isFull}>
        <div className="col-span-full mb-2 flex gap-x-4">
          <Placeholder withGeneration />
          <Placeholder withGeneration />
        </div>

        <div className="col-span-full flex">
          <VirtualizedTable mocked />
        </div>
      </SafeView>

      <SafeView for={isFull}>
        <SafeView for={list.length === 0 && !courtCasesBar.totalSum}>
          <span className="col-span-full">{t('not_found')}</span>
        </SafeView>

        <SafeView for={transformedList?.length}>
          <div className="col-span-full">
            {transformedList.map((item, idx) => (
              <div key={idx} className="mx-2">
                <a href={item.browser_url} type="_blank" style={{ color: 'rgb(1 96 160 )' }}>
                  <div dangerouslySetInnerHTML={{ __html: item.browser_title }} />
                </a>
                <div style={{ color: 'rgb(107 114 128)' }}>
                  <div dangerouslySetInnerHTML={{ __html: item.browser_descr }} />
                </div>
              </div>
            ))}
          </div>
        </SafeView>
      </SafeView>

      {data?.structure === 'se' ? (
        <a
          href="https://office.sud.kz/courtActs/index.xhtml"
          rel="nofollow"
          target="_blank"
          className="text-light col-span-full mt-4 text-xs text-gray-500 md:col-span-3"
        >
          {t('open_source', {
            name: 'office.sud.kz',
          })}
        </a>
      ) : null}
    </CollapsibleBlock>
  )
}
