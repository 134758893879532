import { createEvent, sample } from 'effector'

import { postFeedbackFx, postNewContactsFx } from '@/api'

import { ChangeList, Request } from './types'

export const createContactsAction = createEvent<ChangeList>()

export const postFeedback = createEvent<Request>()

export const feedbackLoading = postFeedbackFx.pending

sample({
  clock: postFeedback,
  fn: ({ bin, data, email, phone }) => {
    return {
      data: { bin, data, phone, email },
    }
  },
  target: postFeedbackFx,
})

sample({
  clock: createContactsAction,
  fn: (contacts) => {
    return {
      data: { contacts },
    }
  },
  target: postNewContactsFx,
})
