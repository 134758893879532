import * as Popover from '@radix-ui/react-popover'
import { PopoverContentProps } from '@radix-ui/react-popover'
import clsx from 'clsx'
import { setCookie } from 'cookies-next'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { useRef, useState } from 'react'

import { CookieFields } from '@/T'

interface LangSelectionProps {
  side: Exclude<PopoverContentProps['side'], 'left' | 'right'>
}

interface LocaleInfo {
  lang: string
  src: string
}

type Locale = 'ru' | 'en'

const locales: Record<Locale, LocaleInfo> = {
  ru: {
    lang: 'Русский',
    src: '/images/ru_flag.svg',
  },
  en: {
    lang: 'English',
    src: '/images/en_flag.svg',
  },
}

export const LangSelection = ({ side }: LangSelectionProps) => {
  const [isOpen, updateIsOpen] = useState(false)
  const trigger = useRef<HTMLButtonElement>(null)
  const router = useRouter()

  const toggleOpen = () => updateIsOpen((isOpen) => !isOpen)
  const onInteractOutside: PopoverContentProps['onInteractOutside'] = (e) => {
    if (!trigger) return

    const isClickOnTrigger = e.target === trigger.current
    if (isClickOnTrigger) return

    updateIsOpen(false)
  }

  const onClick = (locale: string) => {
    setCookie(CookieFields['locale'], locale, {
      maxAge: 31536000,
      path: '/',
    })
  }

  return (
    <Popover.Root open={isOpen}>
      <Popover.Trigger onClick={toggleOpen} className="cursor-pointer text-sm text-primary" ref={trigger}>
        {locales[router.locale as keyof typeof locales]?.lang}
      </Popover.Trigger>
      <Popover.Content
        side={side}
        className="w-32 rounded-md bg-white py-4 shadow-lg"
        onInteractOutside={onInteractOutside}
      >
        <ul className="flex flex-col text-center">
          {Object.entries(locales).map(([locale, localeInfo]) => (
            <li
              key={locale}
              className={clsx('cursor-pointer px-4 py-1.5 ', {
                'text-primary': locale === router.locale,
              })}
            >
              <Link
                onClick={() => onClick(locale)}
                as={router.asPath}
                href={router.pathname}
                locale={locale}
                className="justify-center-center flex"
              >
                <div className="mr-auto h-5 w-5">
                  <Image
                    className="h-full w-full rounded-full object-cover"
                    width={20}
                    height={20}
                    src={localeInfo.src}
                    alt={locale}
                  />
                </div>
                <span className="mr-auto">{localeInfo.lang}</span>
              </Link>
            </li>
          ))}
        </ul>
      </Popover.Content>
    </Popover.Root>
  )
}
