import { appStarted, clientCookiesSet } from '@shared/events'
import { getCookie, setCookie } from 'cookies-next'
import { useUnit } from 'effector-react'
import { ReactNode, useEffect } from 'react'

import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react'
import { $userProfile } from './model'

export function AuthProvider({ children }: { children: ReactNode }) {
  const [clientSideAppStarted, cookiesSet, user] = useUnit([appStarted, clientCookiesSet, $userProfile])
  const { getData } = useVisitorData({ extendedResult: true }, { immediate: true })

  useEffect(() => {
    if (!user) {
      cookiesSet((getCookie('access') as string) ?? '')
    }

    if (user) {
      getData({ ignoreCache: true })
        .then((visitorData) => {
          const visitorId = visitorData.visitorId
          if (visitorId) {
            setCookie('visitorId', visitorId, {
              secure: true,
              sameSite: 'strict',
            })
          }
        })
        .catch(() => {})
    }
  }, [user])

  useEffect(() => {
    clientSideAppStarted()
  }, [])

  return <>{children}</>
}
