import { createApiCall } from './model'

export const [suggestCompaniesFx, $isSnippetsLoading] = createApiCall({
  method: 'POST',
  source: '/business/search',
})

export const [loginUserFx, $loginUserProcessing, __, $loginErrorData] = createApiCall({
  method: 'POST',
  source: '/auth/sign_in',
})

export const [authWithGoogleFx, $authInGoogleInProcess] = createApiCall({
  method: 'POST',
  source: '/auth/google',
})

export const [signUpUserFx, $signUpInProcess, _, $signupErrorData] = createApiCall({
  method: 'POST',
  source: '/auth/sign_up',
})

export const [forgetPasswordFx, $isForgetPasswordLoading] = createApiCall({
  method: 'POST',
  source: '/auth/recover/password',
})

export const [recoveryPasswordFx, $isRecoveryPasswordLoading] = createApiCall({
  method: 'POST',
  source: '/auth/recover/password',
})

export const [loadUserProfileFx] = createApiCall({
  method: 'GET',
  source: '/user/me',
})

export const [loadPaymentUrlFx, $isPaymentLoading] = createApiCall({
  method: 'GET',
  source: '/billing/order/{id}',
})

export const [updateUserProfileFx] = createApiCall({
  method: 'POST',
  source: '/user/me/update',
})

export const [loadOrderHistoryFx, $isOrderHistoryLoading] = createApiCall({
  method: 'GET',
  source: '/user/reports',
})

export const [loadCompaniesByStateFx, $isCompaniesByStateProcessing] = createApiCall({
  method: 'POST',
  source: '/business/jurisdiction/state',
})

export const [loadAreasFx] = createApiCall({
  method: 'GET',
  source: '/business/locations/states/{jurisdiction}',
})

export const [loadPaymentsFx, $isPaymentsLoading] = createApiCall({
  method: 'GET',
  source: '/billing/cloudpayments/history',
})

export const [requestRefundFx, $isRefundRequestLoading] = createApiCall({
  method: 'GET',
  source: '/billing/cloudpayments/refund/{recurrent_id}',
})

export const [
  cancelSubscriptionFx,
  cancelSubscriptionFxPending,
  cancelSubscriptionFxStatus,
  cancelSubscriptionFxError,
] = createApiCall({
  method: 'DELETE',
  source: '/billing/subscriptions',
})

export const [loadExportsHistoryFx, $isExportsHistoryLoading] = createApiCall({
  method: 'GET',
  source: '/export/list',
})

export const [exportCompaniesFx, $isCompaniesExporting] = createApiCall({
  method: 'POST',
  source: '/export/create',
  responseType: 'arraybuffer',
})

export const [getSearchLimitsByIdentifierFx] = createApiCall({
  method: 'GET',
  source: '/limits',
})

export const [findCompaniesFx, $findCompaniesLoading, $findCompaniesStatus, $findCompaniesError] = createApiCall({
  method: 'POST',
  source: '/business/search/highlights',
})

export const [loadCompanyBetaInformationFx] = createApiCall({
  method: 'GET',
  source: `/business/{jurisdiction}/{id}/view`,
})

export const [loadCompanyFullInformationFx] = createApiCall({
  method: 'GET',
  source: '/business/{jurisdiction}/{id}/paid',
})

export const [loadCompanySocialNetworksFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/social_networks',
})

export const [loadContactsReactionListFx] = createApiCall({
  method: 'POST',
  source: '/business/get/reaction/contacts',
})

export const [postContactsReactionFx] = createApiCall({
  method: 'POST',
  source: '/business/create/reaction/contacts',
})

export const [loadCompanyDataFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/data',
})

export const [loadCompanyTaxRegimeFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/tax_declarations',
})

export const [loadGovContractsFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/gov_contracts',
})

export const [loadQuasiContractsFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/quasi_contracts',
})

export const [loadContractsMetaFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/contracts/meta',
})

export const [loadCompanyEventsFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/events',
})

export const [loadSubsidiariesFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/subsidiaries',
})

export const [loadCompanyMetaFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/view/meta',
})

export const [loadCompanyRelationsFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/relations/table',
})

export const [loadCompanyRelationsGraphFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/relations',
})

export const [loadCompanyRelationsFizGraphFx] = createApiCall({
  method: 'GET',
  source: '/business/individuals/relations',
})

export const [loadIndividualRelationsFx] = createApiCall({
  method: 'GET',
  source: '/business/individual/relation',
})

export const [loadIndividualFx] = createApiCall({
  method: 'GET',
  source: '/business/individuals',
})

export const [loadCourtCasesFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/court_cases',
})

export const [loadBrowserInfoFx] = createApiCall({
  method: 'GET',
  source: '/business/{id}/browser/preview',
})

export const [loadEmployeesFx] = createApiCall({
  method: 'GET',
  source: '/business/{company_id}/employers',
})

export const [loadDepartmentsFx] = createApiCall({
  method: 'GET',
  source: '/business/{company_id}/department',
})

export const [postFeedbackFx] = createApiCall({
  method: 'POST',
  source: '/bot/feedback',
})

export const [postNewContactsFx] = createApiCall({
  method: 'POST',
  source: '/contacts/create',
})

export * as apiModel from './model'
export * as apiTypes from './types'
