import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { min } from 'lodash'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import { useRouter } from 'next/router'

import { useEffect, useState } from 'react'

import { $isCompaniesExporting } from '@/api'
import { $userProfile } from '@/entities/user'
import fmt from '@/lib/fmt'
import {
  $facets,
  $isExcelLoaded,
  $isExportSettingsOpened,
  $withContacts,
  companiesToExportCountChanged,
  exportDownloaded,
  exportSettingsOpenedChanged,
  withContactsChanged,
} from '@/screens'
import { CircularProgress } from '@/ui'

export function ExportSettings() {
  const t = useTranslations()
  const lang = useLocale()
  const [
    user,
    setOpen,
    open,
    isExcelLoaded,
    withContacts,
    handleChangeWithContacts,
    handleCompaniesToExportCountChanged,
    handleDownloadExport,
    isCompaniesExporting,
  ] = useUnit([
    $userProfile,
    exportSettingsOpenedChanged,
    $isExportSettingsOpened,
    $isExcelLoaded,
    $withContacts,
    withContactsChanged,
    companiesToExportCountChanged,
    exportDownloaded,
    $isCompaniesExporting,
  ])
  const router = useRouter()
  const { state: jurisdiction } = router.query

  const count = parseInt(
    useStoreMap({
      store: $facets,
      keys: [],
      fn: (facets) => facets.jurisdiction?.find((value) => value.name === jurisdiction)?.count?.toString() || '0',
    }),
  )

  const maxCompanies = min([count, user?.reports || 0]) || 0

  const [companiesCountToExport, setCompaniesCountToExport] = useState(maxCompanies)

  useEffect(() => {
    handleCompaniesToExportCountChanged(companiesCountToExport)
  }, [companiesCountToExport, handleCompaniesToExportCountChanged])

  const handleClose = () => {
    setOpen(false)
  }

  return open ? (
    <div
      className="fixed z-[100] flex h-full w-full flex-col items-end justify-center bg-gray-900 bg-opacity-50 md:items-center"
      onClick={handleClose}
    >
      <div
        className="relative mt-10 w-full rounded-md bg-white px-1 py-5 sm:px-10 md:mt-0 md:w-auto"
        onClick={(e) => e.stopPropagation()}
      >
        <Image
          src="/images/cancel-cross.svg"
          width="11"
          height="11"
          alt=""
          className="absolute right-4 top-4 cursor-pointer"
          onClick={handleClose}
        />
        <div className="mt-5 w-full md:min-w-[400px]">
          {user?.reports ? (
            <>
              <div className="flex flex-col">
                <h1 className="mb-4 w-full text-start font-stem text-xl">{t('download_on_excel')}</h1>
              </div>
              <div className="mb-4 flex items-center gap-2 font-golos text-sm">
                <input
                  type="radio"
                  name="export_type"
                  id="with_contacts"
                  defaultChecked={withContacts}
                  onClick={() => handleChangeWithContacts(true)}
                />
                <label htmlFor="with_contacts" className="text-black">
                  <p className="flex gap-2 text-base">{t(`with_contacts`)}</p>
                  <p>
                    {t.rich(`you_have_with_contact`, {
                      total:
                        count < 10000
                          ? count
                          : `${fmt.number(10000, 'kz', false)} ${lang === 'ru' ? 'из' : 'of'} ${fmt.number(
                              count,
                              'kz',
                              false,
                            )}`,
                    })}
                  </p>
                  <p>
                    {t.rich(`you_have_active_orders`, {
                      count: user?.reports ? fmt.number(user?.reports || 0, 'kz', false) : 0,
                    })}
                  </p>
                  <p
                    className={clsx('text-xs', {
                      'text-red-700': (user?.reports || 0) < count,
                      'text-green': !((user?.reports || 0) < count),
                    })}
                  >
                    {(user?.reports || 0) < count ? t(`not_have_balance`) : t(`have_balance`)}
                  </p>
                </label>
              </div>
              <div className="mb-4 flex items-center gap-2 font-golos text-sm">
                <input
                  type="radio"
                  name="export_type"
                  id="without_contacts"
                  defaultChecked={!withContacts}
                  onClick={() => handleChangeWithContacts(false)}
                />
                <label htmlFor="without_contacts" className="text-black">
                  <p className="text-base">{t(`without_contacts`)}</p>
                  <p>{t(`without_contacts_desc`)}</p>
                </label>
              </div>
              {maxCompanies ? (
                <div className="mb-4 flex flex-col gap-2 font-golos text-sm">
                  <label htmlFor="company_limit_to_export" className="text-black">
                    <p className={'text-base'}>{t(`how_much_company_to_export`)}</p>
                  </label>
                  <input
                    id="company_limit_to_export"
                    type="number"
                    max={maxCompanies}
                    min={1}
                    value={!isNaN(companiesCountToExport) ? companiesCountToExport : ''}
                    onChange={(e) => setCompaniesCountToExport(e.target.valueAsNumber)}
                    className="w-full rounded-lg border-2 border-gray-300 p-2 outline-none transition hover:border-blue-300 focus:border-blue-300"
                  />
                </div>
              ) : null}
              <div className="font-golos text-sm"></div>
              <div className="mt-4 flex flex-col gap-1">
                <div className="flex flex-col items-start font-golos">
                  <button
                    onClick={handleDownloadExport}
                    disabled={!user?.plan_id || isCompaniesExporting || isNaN(companiesCountToExport)}
                    className={`w-full ${
                      isExcelLoaded ? 'bg-gray-400' : 'bg-blue-500'
                    } mt-1 w-auto rounded-3xl p-2 text-white outline-none duration-300 hover:bg-blue-600 focus:outline-none disabled:bg-gray-400 md:px-5`}
                  >
                    {isCompaniesExporting && (
                      <div className="mx-auto h-4 w-4">
                        <CircularProgress color="white" />
                      </div>
                    )}

                    {!isCompaniesExporting &&
                      (!isExcelLoaded ? `${lang === 'ru' ? 'Скачать' : 'Download'}` : t(`excel_was_added`))}
                  </button>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="flex flex-col">
                <h1 className="mb-4 w-full text-start font-stem text-xl">{t('download_on_excel')}</h1>
              </div>
              <div className="mt-4 flex flex-col gap-1">
                <h2>{t(`full_filter_search`)}</h2>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  ) : null
}
