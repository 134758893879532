export const CompanyItemLoading = () => {
  return (
    <li className="flex flex-col border-b py-2">
      <div className="col-span-full">
        <div className="mb-3 h-5 w-1/2 animate-pulse rounded-md bg-gray-200"></div>
        <div className="mb-1 h-4 w-2/6 animate-pulse rounded-md bg-gray-200"></div>
        <div className="mb-1 h-4 w-1/4 animate-pulse rounded-md bg-gray-200"></div>
        <div className="mb-1 h-4 w-1/4 animate-pulse rounded-md bg-gray-200"></div>
        <div className="mb-1 h-4 w-1/6 animate-pulse rounded-md bg-gray-200"></div>
      </div>
    </li>
  )
}
