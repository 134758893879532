import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'

export function ScrollToTop() {
  const scrollRef = useRef<HTMLDivElement | null>(null)
  const [width, setWidth] = useState(0)
  const [active, setActive] = useState(false)

  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (!scrollRef.current) return

      if (document.documentElement.scrollTop <= 256) {
        scrollRef.current.classList.add('hidden')
      } else {
        scrollRef.current.classList.remove('hidden')
      }
    })
    const calculateWidth = () => {
      const containerWidth = document.querySelector('main.container')?.getBoundingClientRect().width
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      if (containerWidth! <= 1022) {
        setWidth(40)
        return
      }
      const screenWidth = document.body.getBoundingClientRect().width
      const resultWidth = (screenWidth - (containerWidth || 0)) / 2
      setWidth(resultWidth)
      if (!scrollRef.current) return
    }
    window.addEventListener('resize', calculateWidth)
    calculateWidth()

    return () => {
      setWidth(0)
    }
  }, [scrollRef])
  const handleScroll = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const t = useTranslations()
  return (
    <div
      ref={scrollRef}
      className="fixed bottom-10 right-10 z-20 hidden h-10 w-10 cursor-pointer items-center justify-center rounded-full bg-blue-600
      duration-300  hover:animate-bounce lg:bottom-0 lg:left-0 lg:right-0 lg:top-0 lg:h-full lg:min-w-min lg:items-start lg:justify-start lg:rounded-none lg:bg-transparent lg:hover:animate-none "
      onClick={handleScroll}
      style={{ width }}
      onMouseOver={() => setActive(true)}
      onMouseOut={() => setActive(false)}
    >
      {' '}
      <div
        className={`flex h-full cursor-pointer items-center justify-center transition-all duration-300 lg:w-24 lg:items-start lg:justify-start ${
          active ? 'lg:bg-blue-500 lg:bg-opacity-10' : ''
        }`}
      >
        <span className="hidden text-primary lg:ml-2 lg:mt-4 lg:block">{t(`to_top`)}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="#fff"
          width="20"
          height="20"
          className="lg:mt-4 lg:fill-primary"
        >
          <path d="M0 0h24v24H0z" fill="none" />
          <path d="M7.41 15.41 12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
        </svg>
      </div>
    </div>
  )
}
