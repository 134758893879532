// @ts-nocheck
import { PaginationView } from '@shared/ui/pagination'
import { Cell } from '@tanstack/table-core'
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useEffect, useMemo, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { countOfCategorySet } from '@/screens'
import { Placeholder, TableFilterItem, TableFilters } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import { CompanyItemLoading } from '@shared/ui/companies/company-item-loading'
import { $employees, $employeesPage, employeesPageChanged, visibilityChanged } from './model'

const VirtualizedTable = dynamic(() => import('@/ui').then((c) => c.VirtualizedTable), {
  ssr: false,
})

export function Employees({
  for: $data,
  isFull: $isFull,
  meta: $meta,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull' | 'meta'>) {
  const t = useTranslations()
  const [isFull, onVisible, data, employees, page, setPage, setCountOfCategory, meta] = useUnit([
    $isFull,
    visibilityChanged,
    $data,
    $employees,
    $employeesPage,
    employeesPageChanged,
    countOfCategorySet,
    $meta,
  ])
  const [filter, setFilter] = useState<string | null>(null)

  const { ref } = useInView({
    onChange: (visible) =>
      onVisible({
        visible,
        id: data?.id,
        shouldBeLoaded: isFull,
      }),
  })

  const filters = useStoreMap({
    store: $employees,
    keys: [isFull, data?.id],
    fn: (employees) => {
      return employees?.meta?.filter(({ total = 0 }) => !!total || total >= 1)
    },
  })

  const list = useStoreMap({
    store: $employees,
    keys: [data?.id],
    fn: (employees) =>
      employees?.employees?.map((e: any) => ({
        full_name: fmt.toProperCase(e.name || e.name_en || '-'),
        age: e.age === '-1' ? '-' : e.age || '-',
        expirience: fmt.toTitleCase(e.work_experience || '-'),
        role: e.position || '-',
        salary: e.salary || '-',
        source:
          (
            <a
              className="text-statsnet hover:text-orange"
              href={`${e.url || e.source_url}`}
              target="_blank"
              rel="nofollow"
            >
              {e.url || e.source_url}
            </a>
          ) || '-',
      })) || [],
  })

  const cells = useMemo(() => {
    return t.raw('employees_head').map((cell: Record<string, any>) =>
      cell.accessorKey === 'source'
        ? {
            ...cell,
            cell: (c: Cell<any, any>) => c.renderValue(),
          }
        : cell,
    )
  }, [t])

  const employeesCount = employees?.employees?.length || meta?.employees || 0

  useEffect(() => {
    setCountOfCategory({
      category: 'employees',
      count: employeesCount,
    })
  }, [employeesCount, setCountOfCategory])

  return (
    <CollapsibleBlock
      ref={ref}
      title={
        <>
          {t('employees')}
          <span className="ml-2 text-sm font-normal">{employeesCount}</span>
        </>
      }
      dataType="employees"
      id="employees"
    >
      {employees ? (
        <>
          <div className="col-span-full">
            <div className="mb-2">
              <SafeView
                for={isFull}
                otherwise={
                  <div className="flex gap-x-4">
                    <Placeholder withGeneration />
                    <Placeholder withGeneration />
                  </div>
                }
              >
                <SafeView for={filters}>
                  <TableFilters applyFilter={setFilter} isFull={isFull}>
                    {({ isFull, applyFilter, ...props }) => (
                      <>
                        <TableFilterItem
                          {...props}
                          className={clsx({
                            'font-bold': !filter,
                          })}
                          onClick={() => applyFilter(null)}
                        >
                          {employees?.pagination_total} {t('all')}
                        </TableFilterItem>

                        {filters?.map((f) => (
                          <TableFilterItem
                            {...props}
                            className={clsx({
                              'font-bold': f?.type === filter,
                            })}
                            onClick={() => applyFilter(f?.type || null)}
                            key={f?.type}
                          >
                            {f?.total} {f?.type}
                          </TableFilterItem>
                        ))}
                      </>
                    )}
                  </TableFilters>
                </SafeView>
              </SafeView>
            </div>
            <SafeView
              for={list}
              otherwise={isFull ? t('not_found') : <VirtualizedTable list={[]} mocked columns={cells} />}
            >
              <VirtualizedTable list={list} columns={cells} mocked={!isFull} filterKey="role" globalFilter={filter} />

              {isFull && (employees?.pagination_total || 0) > 20 ? (
                <div className="my-6 w-fit">
                  <PaginationView
                    totalCount={employees.pagination_total}
                    pageSize={20}
                    onPageChange={setPage}
                    currentPage={page}
                  />
                </div>
              ) : null}
            </SafeView>
          </div>
        </>
      ) : (
        <div className="col-span-full md:col-span-6">
          <CompanyItemLoading />
        </div>
      )}
    </CollapsibleBlock>
  )
}
