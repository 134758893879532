import clsx from 'clsx'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { useRouter } from 'next/router'
import { useMemo } from 'react'

import tg from '@/lib/type-guards'
import { Nullable } from '@/T'

import { Crumb } from './ui'

const generatePathParts = (pathStr: string) => {
  const pathWithoutQuery = pathStr.split('?')[0]
  return pathWithoutQuery.split('/').filter((v) => v.length > 0)
}

const getTextGenerator = (param: string, query: NextParsedUrlQuery) => {
  if (param === 'state') return { trans: 'jurisdictions', param: query.state }

  return { trans: param, param: query[param] }
}
const getDefaultTextGenerator = (path: string, _href: string) =>
  ['search', 'states'].includes(path) ? 'jurisdictions' : path

export interface BreadcrumbsProps {
  marginTop?: Nullable<string>
  className?: string
  area?: string
  customCrumbs?: {
    text: string
    href: string
  }[]
}

export function Breadcrumbs({ marginTop = null, className, area }: BreadcrumbsProps) {
  const router = useRouter()
  const breadcrumbs = useMemo(
    function generateBreadcrumbs() {
      const asPathNestedRoutes = generatePathParts(router.asPath)
      const pathnameNestedRoutes = generatePathParts(router.pathname)

      const crumbList = asPathNestedRoutes
        .map((subPath, idx) => {
          // Pull out and convert "[post_id]" into "post_id"
          const param = pathnameNestedRoutes[idx].replace('[', '').replace(']', '')
          if (param === 'value') return null
          const href = '/' + asPathNestedRoutes.slice(0, idx + 1).join('/')

          const isCustomText = param === 'area' && router.pathname === '/states/[state]/[area]'

          return {
            href: href === '/search' ? '/states' : href,
            textGenerator: getTextGenerator(
              ['search', 'states'].includes(param) ? 'jurisdiction_title' : param,
              router.query,
            ),
            text: getDefaultTextGenerator(
              ['search', 'states'].includes(subPath) ? 'jurisdiction_title' : subPath,
              href,
            ),
            customText: isCustomText ? area : null,
          }
        })
        .filter(tg.isNotNull)

      return crumbList
    },
    [area, router.asPath, router.pathname, router.query],
  )

  return (
    <ul
      className={clsx('mb-4 flex text-base', className, {
        [`mt-${marginTop}`]: marginTop,
      })}
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
    >
      {breadcrumbs.map((crumb, idx) => (
        <Crumb
          text={crumb.text}
          textGenerator={crumb.textGenerator}
          customText={crumb.customText}
          href={crumb.href}
          last={idx === breadcrumbs.length + 1}
          key={crumb.href}
        />
      ))}
    </ul>
  )
}
