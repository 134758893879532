import { useField, useForm } from 'effector-forms'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import Link from 'next/link'

import { $isRecoveryPasswordLoading } from '@/api'
import { Back, CircularProgress, FloatInput } from '@/ui'

import { $$recoveryPasswordForm } from './model'

export interface RecoveryPasswordScreenProps {
  token: string
  email: string
}

export const RecoveryPasswordScreen = () => {
  const t = useTranslations()
  const [load] = useUnit([$isRecoveryPasswordLoading])

  const { submit } = useForm($$recoveryPasswordForm)
  const passwordField = useField($$recoveryPasswordForm.fields.password)
  const repeatPasswordField = useField($$recoveryPasswordForm.fields.repeatPassword)

  return (
    <main className="container flex h-screen flex-col items-center justify-center">
      <Back />

      <Link href="/" rel="nofollow" className="font-avenir text-3xl">
        Statsnet
      </Link>
      <h1 className="mb-4 text-lg sm:text-xl">{t('restore_password')}</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault()
          submit()
        }}
        className="w-full max-w-sm space-y-5"
      >
        <FloatInput
          label={t(`password`)}
          type="password"
          name="password"
          className={'rounded-md border-b'}
          onChange={(e: any) => passwordField.onChange(e.target.value)}
          value={passwordField.value}
        />
        <FloatInput
          label={t(`confirm_password`)}
          type="password"
          name="password_confirmation"
          className={'rounded-md border-b'}
          onChange={(e: any) => repeatPasswordField.onChange(e.target.value)}
          value={repeatPasswordField.value}
        />

        <button
          className="btn mt-5 rounded-md bg-blue-500 p-3.5 text-white outline-none duration-300 hover:bg-blue-600 focus:outline-none  "
          type="submit"
          disabled={load}
        >
          {' '}
          {load ? (
            <div className="mx-auto h-4 w-4">
              <CircularProgress color="white" />
            </div>
          ) : (
            t('restore_password_link_text')
          )}
        </button>
      </form>
    </main>
  )
}
