import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'

import { usePlan } from '@/features'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui/tooltip'

const numberMasks = {
  kz: '+7 (7░░) ░░░-░░-░░',
  uz: '+998 (░░░) ░░-░░░░',
  kg: '+996 (░░░) ░░-░░░░',
  az: '+994 (░░░) ░░-░░░░',
  ru: '+7 (░░░) ░░░-░░-░░',
}

export function Placeholder({
  isPhone = false,
  isMail = false,
  isWebSite = false,
  withCuntrySgin = false,
  small = false,
  text = '',
  withGeneration = false,
  jurisdiction = 'kz',
  forceModal = false,
}: any) {
  const [tooltipVisible, setTooltipVisible] = useState(false)
  const t = useTranslations()
  const generatedTextCache = useRef<string | null>(null)
  const [dummy, updateDummy] = useState('')
  const { openPlanModalWindow } = usePlan()
  const hoverHandler = () => {
    setTooltipVisible(true)
  }
  const mouseOutHandler = () => {
    setTooltipVisible(false)
  }

  useEffect(() => {
    const generateDummy = () => {
      if (generatedTextCache.current) {
        return generatedTextCache.current
      }

      let result = ''
      for (let j = 0; j < 5; j++) {
        const wordLength = Math.round(Math.random() * 5) + 2
        let word = ''
        for (let i = 0; i < wordLength; i++) {
          word += '░'
        }
        result += word + ' '
      }

      generatedTextCache.current = result

      return result
    }

    updateDummy(generateDummy())
  }, [])

  return (
    <span
      className="relative inline-flex cursor-pointer font-arial text-primary hover:text-flamingo"
      onMouseOver={() => hoverHandler()}
      onMouseOut={() => mouseOutHandler()}
      onClick={() => {
        if (!forceModal) {
          openPlanModalWindow()
        }
      }}
    >
      <Tooltip>
        <TooltipTrigger>
          {isPhone
            ? numberMasks[jurisdiction as keyof typeof numberMasks]
            : isMail
              ? '░░░@░░░.░░'
              : isWebSite
                ? '░░░.░░░.░░░'
                : small
                  ? `░░░░░░░`
                  : withGeneration
                    ? dummy
                    : `░░░░░░░░░░░░░ ${withCuntrySgin ? ' ₸' : ''}`}
        </TooltipTrigger>
        <TooltipContent>{t(text || 'service_access_in_full_order')}</TooltipContent>
      </Tooltip>
    </span>
  )
}
