import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'

import { ROUTES } from '@/constants'
import { $userProfile } from '@/entities/user'
import { usePlan } from '@/features'

export function StickyForm() {
  const t = useTranslations()
  const locale = useLocale()

  const user = useUnit($userProfile)
  const router = useRouter()

  const [showForm, setShowForm] = useState(false)
  const { planModalWindow } = usePlan()

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setShowForm(document.documentElement.scrollTop > 515)
    })

    return () => {
      window.removeEventListener('scroll', () => {})
    }
  }, [])

  useEffect(() => {
    setShowForm(!planModalWindow && document.documentElement.scrollTop > 515)
  }, [planModalWindow])

  const payHandler = (isUser: boolean, have?: boolean) => {
    if (isUser) {
      router.push(ROUTES.PRICING)
    } else {
      router.push(`/auth?type=${have ? 'signin' : 'signup'}&redirect=${encodeURIComponent(router.asPath)}`)
    }
  }

  const generatePath = (isUser: boolean, have?: boolean) => {
    return isUser ? ROUTES.PRICING : `/auth/${have ? 'signin' : 'signup'}&redirect=${encodeURIComponent(router.asPath)}`
  }

  return (
    <div
      style={{ left: 0 }}
      className={`sticky_container fixed flex w-full flex-col items-center rounded ${showForm ? 'show' : 'hide'}`}
    >
      <div className="sticky_form flex flex-col items-center gap-3 md:flex-row">
        <button
          className="buy_order_btn w-full rounded-lg p-3 text-white md:w-1/6"
          style={{ fontSize: '15px', fontWeight: '500' }}
          onClick={() => payHandler(!!user, false)}
        >
          {t('get_access')}
        </button>
        <Link locale={locale} href={generatePath(!!user, true)} legacyBehavior>
          <a rel="nofollow" className="cursor-pointer font-sans text-sm text-primary hover:text-flamingo">
            {t(`i_have_account`)}
          </a>
        </Link>
      </div>
    </div>
  )
}
