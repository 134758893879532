import clsx from 'clsx'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'

import React from 'react'

import { apiTypes } from '@/api'
import { Jurisdiction } from '@/entities/jurisdiction'

import { SafeView } from '@/features'

import fmt from '@/lib/fmt'

import { CompanyStatus } from '@/ui'

import { cn } from '@lib/shadcn'
import { checkCompanyStatus } from './common'
import { CompanyHighlight } from './highlight'
import { IndustryCode } from './industry-code'
import { ManagingName } from './managing-name'
import styles from './styles.module.css'

interface CompanyProps {
  company: apiTypes.Company
  self?: boolean
  jurisdiction: Jurisdiction
  withHighlighter?: boolean
  withDefaultLinks?: boolean
  search?: string
  className?: string
}

const POSTFIX_COMPANY_NAMES = [
  'АКЦИОНЕРНОЕ ОБЩЕСТВО',
  'ТОВАРИЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ',
  'ОБЩЕСТВО С ОГРАНИЧЕННОЙ ОТВЕТСТВЕННОСТЬЮ',
  'ГОСУДАРСТВЕННОЕ УЧРЕЖДЕНИЕ',
]

const excludedIdentifiers = ['iin', 'иин']

const excludedHighlightsFields = ['highlight', 'officers.name', 'name', 'addresses.full', 'title']

export const CompanyItem = ({
  company,
  jurisdiction,
  self,
  withHighlighter = false,
  withDefaultLinks = false,
  search,
  className,
}: CompanyProps) => {
  const correctName = withHighlighter
    ? company.highlights?.title ||
      company.highlights?.name ||
      company.highlights?.name_native ||
      company.title ||
      company.name ||
      company.name_native
    : company.title || company.name || company.highlights?.name

  const companyName = company.title || company.name_en || company.full_name_en || company.name

  const isPostfix = POSTFIX_COMPANY_NAMES.includes(company.name ?? '')

  const t = useTranslations()
  const locale = useLocale()

  if (company.identifier && !company.identifiers?.find((el) => el.value === company.identifier)) {
    company.identifiers = [...(company.identifiers ?? []), { number: '', type: '', value: company.identifier }]
  }

  return (
    <li className={cn('flex flex-col border-b py-2', className)}>
      <SafeView for={self}>
        <div className="col-span-full mb-2">
          <p className="col-span-full text-sm text-gray-500">
            {t(`view_time`)}
            <span className="text-sm" suppressHydrationWarning>
              {company.view_date ? ' — ' + fmt.date(new Date(company.view_date)) + ' ' : '—'}
              {company.view_date ? new Date(company.view_date).toLocaleTimeString() : ''}
            </span>
          </p>
        </div>
      </SafeView>

      <SafeView
        for={withDefaultLinks}
        otherwise={
          <Link
            style={{ fontFamily: 'stem' }}
            className="flex items-center gap-1 font-stem text-lg text-primary hover:text-flamingo sm:text-xl"
            locale={locale}
            href={`/companies/${jurisdiction}/${company.id}`}
          >
            {company.status && !search ? (
              <span
                className={`${styles.status} ${
                  checkCompanyStatus(company.status) || !company.inactive ? styles.statusActive : styles.statusInActive
                }`}
              />
            ) : (
              <CompanyStatus inActive={company.inactive} />
            )}

            <h2>
              {withHighlighter && search ? (
                <div
                  className="font-stem-medium-b text-lg sm:text-xl"
                  dangerouslySetInnerHTML={{
                    __html: correctName,
                  }}
                />
              ) : locale === 'ru' ? (
                correctName
              ) : (
                companyName
              )}
              {company.legal_form &&
                company.legal_form !== 'Юридическое лицо' &&
                isPostfix &&
                ', ' +
                  (company.legal_form.length > 3
                    ? company.legal_form
                        .split(' ')
                        .map((e: any) => (e.length > 3 ? e[0].toUpperCase() : ''))
                        .join('')
                    : company.legal_form)}
            </h2>
          </Link>
        }
      >
        <Link
          locale={locale}
          className="font-stem text-lg text-primary hover:text-flamingo sm:text-xl"
          href={`/companies/${jurisdiction}/${company.id}`}
        >
          <h2>
            {withHighlighter && search ? (
              <CompanyHighlight query={search} correctName={correctName} companyName={companyName ?? ''} />
            ) : (
              correctName
            )}
          </h2>
        </Link>
      </SafeView>

      <p className="text-sm">
        <SafeView
          for={withHighlighter && company.highlights?.['addresses.full']}
          otherwise={fmt.address({
            //@ts-ignore
            company,
            jurisdiction,
            jurisdictionTranslate: t.raw('jurisdictions')[jurisdiction] as string,
          })}
        >
          <span className="bold_b" dangerouslySetInnerHTML={{ __html: company.highlights?.['addresses.full'] || '' }} />
        </SafeView>
      </p>

      <IndustryCode company={company} />

      <p className="flex flex-wrap gap-1 text-sm text-primary">
        <ManagingName company={company} withHighlighter={withHighlighter} />
      </p>

      <p className="text-sm text-gray-500">
        <SafeView for={company.company_size} otherwise={t.raw('company_size_labels')['small']}>
          {company.company_size}
        </SafeView>

        {/* @ts-ignore */}
        {` ${fmt.headCount({ company })} ${t('people')}`}
      </p>

      <div className="flex space-x-1">
        {company.identifiers
          ?.filter((el) => !excludedIdentifiers.includes(el.type.toLowerCase()))
          ?.map((el, index, array) => (
            <span className="text-sm text-gray-500" key={`${el.type}-${index}-${el.value}`}>
              <span className="space-x-1">
                <span>{el.type}</span>
                <span className={clsx({ 'font-bold': el.value && search?.includes(el.value) })}>{el.value}</span>
              </span>

              {index < array.length - 1 && ' · '}
            </span>
          ))}
      </div>

      <div className="mt-2">
        <SafeView for={company.highlights} withProps>
          {(highlights) =>
            Object.entries(highlights)
              .filter(([key, value]) => Boolean(value) && !excludedHighlightsFields.includes(key))
              .map(([key, value], idx) => (
                <React.Fragment key={idx}>
                  <p className="text-sm text-gray-500">
                    {key === 'name_native' ? t.raw('name_native')[jurisdiction] : t(key)}
                  </p>
                  <p
                    className="text-sm"
                    dangerouslySetInnerHTML={{
                      __html: value as string,
                    }}
                  />
                </React.Fragment>
              ))
          }
        </SafeView>
      </div>
    </li>
  )
}
