/* eslint-disable prettier/prettier */
import { cn } from '@lib/shadcn'
import { PaginationView } from '@shared/ui/pagination'
import { useStoreMap, useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Image from 'next/image'
import Link from 'next/link'

import { useEffect, useState } from 'react'

import { $isPaymentsLoading, $isRefundRequestLoading } from '@/api'
import { ROUTES, extractPlanInfoById } from '@/constants'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'

import { CircularProgress, Table } from '@/ui'

import { CompanyItemLoading } from '@shared/ui/companies/company-item-loading'
import Refund_Modal from '../personal/Refund_Modal'
import {
  $displayPaymentsPerPage,
  $paymentsPage,
  $slicedPayments,
  $totalPayments,
  paymentsLoaded,
  paymentsPageChanged,
  requestRefund,
} from './model'

export const PaymentHistoryScreen = () => {
  const t = useTranslations()
  const locale = useLocale()

  const [
    payments,
    loading,
    loadPayments,
    rpcRefund,
    loadingRequestRefund,
    onPageChanged,
    page,
    totalPayments,
    paymentsPerPage,
  ] = useUnit([
    $slicedPayments,
    $isPaymentsLoading,
    paymentsLoaded,
    requestRefund,
    $isRefundRequestLoading,
    paymentsPageChanged,
    $paymentsPage,
    $totalPayments,
    $displayPaymentsPerPage,
  ])

  const [isModalOpen, setModalOpen] = useState(false)

  const [recurrent_id, setRecurrent_id] = useState('0')

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  const refund = () => rpcRefund({ recurrent_id })

  useEffect(() => {
    loadPayments()
  }, [loadPayments])

  const tablePayments = useStoreMap({
    store: $slicedPayments,
    keys: [],
    fn: (payments) =>
      payments.map((payment) => {
        return {
          id: payment.id,
          created_at: payment.created_at ? fmt.date(new Date(payment.created_at)) : null,
          status: (
            <span
              className={cn('rounded-full p-1 text-xs font-medium text-white', {
                'bg-destructive': payment.status === 'UNPAID',
                'bg-active': payment.status === 'PAID',
              })}
            >
              {t('payment_status', {
                status: payment.status || 'PAID',
              })}
            </span>
          ),
          payment_method: (
            <SafeView for={payment.card_type && payment.card_first_six && payment.card_last_four} otherwise="--">
              <Image
                src={`/icons/payment-methods/${payment.card_type?.toLowerCase()}.svg`}
                alt={`${payment.card_type} icon`}
                width={36}
                height={36}
              />
              <span className="cursor-pointer">
                {payment.card_first_six} •••• {payment.card_last_four}
              </span>
            </SafeView>
          ),
          type: t('buy_plan', {
            plan: extractPlanInfoById(payment.plan_id || 0)?.title || '',
            month: extractPlanInfoById(payment.plan_id || 0)?.months,
          }),
          refund: (
            <SafeView for={payment.recurrent && !payment.refund_status}>
              <div className="grid w-full grid-cols-4">
                <h2 className="col-span-full text-lg md:col-span-2">{t(`request_refund`)}</h2>
                <button
                  onClick={() => {
                    openModal()
                    if (payment.recurrent_id) {
                      setRecurrent_id(payment.recurrent_id)
                    }
                  }}
                  disabled={loadingRequestRefund}
                  className="col-span-full w-full items-center rounded bg-blue-500 text-white transition hover:bg-blue-600 disabled:bg-gray-600 md:col-span-1 md:w-auto"
                >
                  {loadingRequestRefund ? (
                    <div className="m-auto h-8 w-8 p-2 md:h-4 md:w-4 md:p-0">
                      <CircularProgress color={'white'} />
                    </div>
                  ) : (
                    t(`request_refund`)
                  )}
                </button>
              </div>
            </SafeView>
          ),
        }
      }),
  })

  return (
    <>
      <div className="col-span-full grid grid-cols-4 pb-4">
        <Refund_Modal isOpen={isModalOpen} onClose={closeModal} onSubmit={refund}>
          <div className="text-center text-lg font-bold" style={{ margin: '0 0 20px 0' }}>
            {t('warning_refund')}
          </div>
        </Refund_Modal>
        <SafeView for={payments.length > 0 && !loading}>
          <div className="col-span-full divide-y">
            <Table column={t.raw('payment_history_table_columns')} data={tablePayments} isFull withCustomHide />
            <div className="block w-full">
              <div className="my-6 w-fit">
                <PaginationView
                  totalCount={totalPayments}
                  pageSize={paymentsPerPage}
                  onPageChange={onPageChanged}
                  currentPage={page}
                />
              </div>
            </div>
          </div>
        </SafeView>
        <SafeView for={payments.length === 0 && !loading}>
          <h2 className="col-span-full text-xl font-medium">{t(`no_payments`)}</h2>
          <Link
            locale={locale}
            href={ROUTES.PRICING}
            className="col-span-full mt-4 text-lg font-medium text-primary hover:text-flamingo"
            rel="nofollow"
          >
            {t('go_to_plans')}
          </Link>
        </SafeView>
      </div>
      {loading ? (
        <div style={{ width: '600px' }}>
          <CompanyItemLoading />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
