import { useTranslations } from 'next-intl'

import styles from './styles.module.css'

interface FullLabelI {
  transKey?: string
}

export const FullLabel = ({ transKey = 'full' }: FullLabelI) => {
  const t = useTranslations()
  return <span className={styles.fullOrderLabel}>{t(transKey)}</span>
}
