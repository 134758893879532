import { createEvent, createStore, sample } from 'effector'

import { Nullable } from '@/T'
import { loadCompanyRelationsFx, loadIndividualRelationsFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { $individualHash } from '@screens/individuals'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, $isLoaded, $companyId, $shouldBeLoaded, $isPerson, visibilityChanged } =
  createBindingForVisibility()

export const $relationsPage = createStore(0)
export const relationsPageChanged = createEvent<number>()
export const $relationsType = createStore<Nullable<string>>(null)
export const relationsTypeChanged = createEvent<Nullable<string>>()
export const $relationsQuery = createStore<string>('')
export const relationsQueryChanged = createEvent<string>()
export const $isLoading = createStore<boolean>(false)

export function isCompanyRelations(
  x: ResponseType<'/business/{id}/relations/table', 'get'> | ResponseType<'/business/individual/relation', 'get'>,
): x is ResponseType<'/business/{id}/relations/table', 'get'> {
  return !Array.isArray(x.meta)
}

export const $relations = createStore<
  ResponseType<'/business/{id}/relations/table', 'get'> | ResponseType<'/business/individual/relation', 'get'>
>({} as never)

sample({
  clock: $inView,
  source: {
    isLoaded: $isLoaded,
    id: $companyId,
    shouldBeLoaded: $shouldBeLoaded,
    isPerson: $isPerson,
  },
  filter: ({ isLoaded, id, shouldBeLoaded, isPerson }, inView) =>
    inView && !!id && !isLoaded && shouldBeLoaded && !isPerson,
  fn: ({ id }) => ({
    path: { id: id as string },
  }),
  target: loadCompanyRelationsFx,
})

sample({
  clock: $inView,
  source: {
    isLoaded: $isLoaded,
    id: $individualHash,
    shouldBeLoaded: $shouldBeLoaded,
    isPerson: $isPerson,
  },
  filter: ({ isLoaded, id, shouldBeLoaded, isPerson }, inView) =>
    inView && !!id && !isLoaded && shouldBeLoaded && isPerson,
  fn: ({ id }) => ({
    query: { identifier: id },
  }),
  target: loadIndividualRelationsFx,
})

sample({
  clock: [loadIndividualRelationsFx.inFlight, loadCompanyRelationsFx.inFlight],
  fn: () => true,
  target: $isLoading,
})

sample({
  clock: [loadIndividualRelationsFx.done, loadCompanyRelationsFx.done],
  fn: () => false,
  target: $isLoading,
})

sample({
  clock: [loadIndividualRelationsFx.done, loadCompanyRelationsFx.done],
  fn: () => true,
  target: $isLoaded,
})

sample({
  clock: [loadIndividualRelationsFx.doneData, loadCompanyRelationsFx.doneData],
  target: $relations,
})

export const $totalRelations = createStore(0)

sample({
  clock: $relations,
  source: { person: $isPerson, isFull: $shouldBeLoaded },
  fn: ({ person }, relations) => {
    let totalCount = 0
    if (relations && relations.meta) {
      if (!isCompanyRelations(relations) && person) {
        relations.meta.forEach((c: any) => (totalCount += c.count))
      } else {
        Object.values(relations?.meta).forEach((count) => (totalCount += count as number))
      }

      return totalCount
    }

    return totalCount
  },
  target: $totalRelations,
})

sample({
  clock: relationsPageChanged,
  target: $relationsPage,
})

sample({
  clock: relationsTypeChanged,
  target: $relationsType,
})

sample({
  clock: relationsQueryChanged,
  target: $relationsQuery,
})

sample({
  clock: [$relationsPage, $relationsType, $relationsQuery],
  source: {
    companyId: $companyId,
    isFull: $shouldBeLoaded,
    person: $isPerson,
    relationType: $relationsType,
    page: $relationsPage,
    query: $relationsQuery,
  },
  filter: ({ companyId, isFull, person }) => !!companyId && isFull && !person,
  fn: ({ companyId, relationType, page, query }) => ({
    path: {
      id: companyId as string,
    },
    query: {
      type: relationType,
      limit: '20',
      page,
      query,
    },
  }),
  target: loadCompanyRelationsFx,
})

sample({
  clock: [$relationsPage, $relationsType],
  source: {
    identifier: $individualHash,
    isFull: $shouldBeLoaded,
    person: $isPerson,
    relationType: $relationsType,
    page: $relationsPage,
    query: $relationsQuery,
  },
  filter: ({ identifier, isFull, person }) => !!identifier && isFull && person,
  fn: ({ identifier, relationType, page, query }) => ({
    query: {
      type: relationType,
      limit: '20',
      page,
      query,
      identifier: identifier,
    },
  }),
  target: loadIndividualRelationsFx,
})
