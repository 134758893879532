import { createEvent, createStore, sample } from 'effector'

import { loadBrowserInfoFx, loadCompanyMetaFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { ResponseType } from 'src/shared/api/model'
export const $browserData = createStore(null)
export const browserInfoRequested = createEvent<string>()

sample({
  clock: browserInfoRequested,
  fn: (id) => ({ path: { id } }),
  target: loadBrowserInfoFx,
})

sample({
  //@ts-ignore
  clock: loadBrowserInfoFx.doneData,
  target: $browserData,
})
export const { $inView, $isLoaded, $companyId, visibilityChanged, $shouldBeLoaded } = createBindingForVisibility()

export const $viewMeta = createStore<ResponseType<'/business/{id}/view/meta', 'get'>>({})

sample({
  clock: $inView,
  source: {
    isLoaded: $isLoaded,
    id: $companyId,
    shouldBeLoaded: $shouldBeLoaded,
  },
  filter: ({ isLoaded, id, shouldBeLoaded }, visible) => !isLoaded && !!id && visible && shouldBeLoaded,
  fn: ({ id }) => ({
    path: {
      id: id as string,
    },
  }),
  target: loadCompanyMetaFx,
})

sample({
  clock: loadCompanyMetaFx.doneData,
  target: $viewMeta,
})

sample({
  clock: loadCompanyMetaFx.done,
  fn: () => true,
  target: $isLoaded,
})
