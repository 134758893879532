import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import _ from 'lodash'
import { useTranslations } from 'next-intl'
import Image from 'next/image'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import RangeSlider from 'react-range-slider-input'

import { multipleFacets as baseMultipleFacets, filterNameToTranslateMap } from '@/constants'
import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { $appliedFacets, $areas, $facets } from '@/screens'
import { Nullable } from '@/T'

import { FacetTitle } from './facet-title'

import { LockClosedIcon } from '@radix-ui/react-icons'
import Icon from '@shared/ui/icon'
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import 'react-range-slider-input/dist/style.css'

export function FacetField({ field }: { field: string }) {
  const router = useRouter()
  const searchParams = useSearchParams()

  const [areas, userProfile] = useUnit([$areas, $userProfile])
  const [showMore, setShowMore] = useState(false)
  const [search, updateSearch] = useState('')
  const facet = useStoreMap({
    store: $facets,
    keys: [field],
    fn: (facets, [field]) => facets[field],
  })
  const isAreas = field === 'addresses.state'
  const isUserAdmitted = (userProfile?.plan_id || 0) > 2

  const appliedFacet: Nullable<Record<string, any[]>> = useStoreMap({
    store: $appliedFacets,
    keys: [field],
    fn: (facets, [field]) => {
      if (facets?.length > 0) {
        const applied = facets.find(([_, f]) => field === f)
        if (applied) {
          const [facetValue, facetName] = applied
          return {
            [facetName]: facetValue,
          }
        }
      }
      return null
    },
  })

  const sortedFacet = useMemo(() => {
    if (appliedFacet && facet?.length > 0) {
      const arrayOfAppliedFacet = Object.entries(appliedFacet)
      const appliedAreas = appliedFacet[field]
      const hasAppliedAreas = appliedAreas?.length > 0
      const appliedMultipleFacets = appliedFacet[field]
      const hasAppliedMultipleFacets = appliedMultipleFacets?.length > 0

      let facetToSort = facet

      const multipleFacets = baseMultipleFacets[field as keyof typeof baseMultipleFacets] ?? null

      if (isAreas && hasAppliedAreas) {
        facetToSort = appliedAreas
      }

      if (multipleFacets && hasAppliedMultipleFacets) {
        facetToSort = multipleFacets.map((facet) => {
          const appliedFacet = appliedMultipleFacets.find((f) => f.name.toUpperCase() === facet.name.toUpperCase())
          if (appliedFacet) return appliedFacet

          return facet
        })
      }

      return facetToSort
        .sort((x: any, y: any) => {
          const a = arrayOfAppliedFacet?.indexOf(x.value) !== -1
          const b = arrayOfAppliedFacet?.indexOf(y.value) !== -1
          return a === b ? 0 : a ? -1 : 1
        })
        .filter((f: any) => {
          if (search.length) {
            return !!f?.value?.toUpperCase()?.includes(search.toUpperCase())
          } else {
            return true
          }
        })
    }
    return facet?.filter((f: any) => {
      if (search.length) {
        return !!f?.name?.toUpperCase()?.includes(search.toUpperCase())
      } else {
        return true
      }
    })
  }, [appliedFacet, facet, field, isAreas, areas, search])

  const translated = filterNameToTranslateMap[field as keyof typeof filterNameToTranslateMap]
  const isInputField = !!translated?.placeholder
  const t = useTranslations()

  const toggleFacet = useCallback(
    async (facet: string, value: string) => {
      if (!isUserAdmitted) return

      const state = [...new Set(searchParams.get(facet)?.split(',').filter(String))]

      if (state.includes(value)) {
        _.remove(state, (item) => item.toUpperCase() === value.toUpperCase())
      } else {
        state.push(value)
      }

      const result = state.join(',')

      const query = { ...router.query, [facet]: result }
      if (result.length === 0) {
        Reflect.deleteProperty(query, facet)
      }

      await router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [router, searchParams, userProfile?.plan_id],
  )

  const clearFacet = async (facet: string) => {
    const query = { ...router.query, [facet]: undefined }
    Reflect.deleteProperty(query, facet)
    setShowMore(false)
    await router.push(
      {
        pathname: router.pathname,
        query,
      },
      undefined,
      { shallow: true },
    )
  }

  const toggleShowMore = async () => {
    if (!isUserAdmitted) return

    setShowMore((prev) => !prev)
  }

  const isChecked = (facet: string, value: string) => {
    return searchParams.get(facet)?.split(',')?.includes(value)
  }

  const isFacetNotEmpty = (field: string) => Boolean(searchParams.get(field))

  if (!translated?.title) {
    return <div className="invisible hidden">{field}</div>
  }

  return (
    <div className="flex flex-col flex-wrap justify-between border-b-[1px] last:border-b-0">
      <div className="flex items-center justify-between pb-2 align-middle">
        <button className="flex flex-row space-x-2 text-gray-800 outline-0" onClick={toggleShowMore}>
          <FacetTitle title={t(translated.title)} />
        </button>

        <button
          className={`ml-auto mr-1 px-2 text-sm text-gray-400 ${isFacetNotEmpty(field) ? 'block' : 'hidden'}`}
          onClick={() => clearFacet(field)}
        >
          Clear
        </button>

        <Tooltip>
          <TooltipTrigger asChild>
            <button
              type="button"
              className="rounded-md bg-gray-100 px-4 py-1 outline-0 transition hover:bg-gray-200 active:bg-gray-300"
              onClick={toggleShowMore}
            >
              {isUserAdmitted && <Icon name="plus" className={clsx('transition-all', { 'rotate-45': showMore })} />}
              {!isUserAdmitted && (
                <div className="my-1">
                  <LockClosedIcon />
                </div>
              )}
            </button>
          </TooltipTrigger>
          {!isUserAdmitted && <TooltipContent>{t('access_for_subscribers_pro')}</TooltipContent>}
        </Tooltip>
      </div>

      <SafeView for={translated.type === 'choice' && showMore && isInputField}>
        <div
          className={`text-md my-2 flex cursor-pointer justify-between rounded border border-[#dcdcdc] px-4 py-1 text-sm text-black transition-all duration-200 focus-within:border-[#0072CE]`}
        >
          <input
            placeholder={translated.placeholder ? t(translated.placeholder as string) : ''}
            className="w-full outline-none"
            onChange={(e) => updateSearch(e.target.value)}
          />
          <button type="button" onClick={toggleShowMore}>
            <Image
              className={`transition ${showMore ? 'rotate-90' : '-rotate-90'}`}
              src={'/images/arrow-head.svg'}
              width={24}
              height={24}
              alt={'arrow-head'}
            />
          </button>
        </div>
      </SafeView>

      <ul
        className={clsx('', {
          'flex max-h-64 flex-col gap-y-1 overflow-y-auto px-2': isInputField && translated.type !== 'range-double',
          'flex flex-wrap items-center gap-y-2': !isInputField,
          'pb-2': isFacetNotEmpty(field) || showMore,
        })}
      >
        <SafeView for={translated.type === 'range-double' && (isFacetNotEmpty(field) || showMore)}>
          <FacetFieldRangeSlider field={field} sortedFacet={sortedFacet} />
        </SafeView>

        <SafeView for={translated.type === 'choice'}>
          {sortedFacet?.length > 0 &&
            sortedFacet.map((f: Record<string, any>, idx: number) => {
              const companySize = field === 'company_size' && t.raw('company_size_labels')[f.value]?.length > 0

              const facetElementClasses = clsx(
                'flex flex-wrap gap-2 items-center text-sm transition-all duration-200 cursor-pointer',
                {
                  [clsx('rounded-md px-4 mx-0.5', {
                    'bg-[#2171D1] hover:bg-blue-700 text-white hover:text-white': isChecked(field, f.value),
                    'bg-[#E9F1FA] hover:bg-blue-300 text-[#2171D1]': !isChecked(field, f.value),
                  })]: !isInputField,
                },
              )

              const facetElement = (
                <li
                  className={clsx(facetElementClasses, {
                    hidden: !showMore && !isChecked(field, f.value),
                  })}
                  key={`${f.name}_${idx}`}
                  onClick={() => toggleFacet(field, f.value)}
                >
                  <span className="leading-xl flex w-full items-center space-x-1 py-1">
                    <SafeView for={isInputField}>
                      <label className="flex w-1/12 items-center">
                        <input
                          id={f.name}
                          name={f.value}
                          type="checkbox"
                          className="h-full w-full rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={isChecked(field, f.value)}
                          readOnly
                        />
                      </label>
                    </SafeView>

                    <span className={clsx({ 'w-10/12': isInputField })}>
                      {field === 'inactive' && (f.value === 'true' ? t('inactiv_person') : t('active_person'))}
                      {!['inactive', 'company_size', 'contact_type'].includes(field) && f.name}
                      {field === 'company_size' && t.raw('company_size_labels')[f.value]}
                      {field === 'contact_type' && t(`contact_type.${f.value}`)}
                    </span>

                    {f?.count && <span className="whitespace-pre italic">{fmt.number(f.count, 'kz', false)}</span>}
                  </span>
                </li>
              )

              if (field === 'company_size' && !companySize) {
                return null
              }

              return facetElement
            })}
        </SafeView>
      </ul>
    </div>
  )
}

const FacetFieldRangeSlider = ({ field, sortedFacet }: { field: string; sortedFacet: FacetValues[] }) => {
  const router = useRouter()
  const searchParams = useSearchParams()
  const [userProfile, appliedFacets] = useUnit([$userProfile, $appliedFacets])

  const [min, max] = [
    sortedFacet?.find((x: { name: string }) => x.name === 'min')?.count || 0,
    sortedFacet?.find((x: { name: string }) => x.name === 'max')?.count || 4294967295,
  ]

  const [aggregatedParamsMin, aggregatedParamsMax] = searchParams.get(field)?.split(',').map(Number) || [min, max]

  const [minVal, setMinVal] = useState(aggregatedParamsMin)
  const [maxVal, setMaxVal] = useState(aggregatedParamsMax)

  const updateRangeFacet = useCallback(
    async (facet: string, value: [number, number]) => {
      if (!userProfile?.plan_id) return

      if (searchParams.get(facet) === value.join(',')) return

      const query = { ...router.query, [facet]: value.join(',') }

      if (value[0] === min && value[1] === max && !searchParams.get(facet)) {
        return
      }

      if (value[0] === min && value[1] === max) {
        Reflect.deleteProperty(query, facet)
      }

      await router.push(
        {
          pathname: router.pathname,
          query,
        },
        undefined,
        { shallow: true },
      )
    },
    [max, min, router, searchParams, userProfile?.plan_id],
  )

  useEffect(() => {
    const t = setTimeout(async () => {
      await updateRangeFacet(field, [minVal, maxVal])
    }, 500)

    return () => {
      clearTimeout(t)
    }
  }, [minVal, maxVal, field, updateRangeFacet])

  return (
    <div className="mb-2 flex py-2">
      <div className="flex w-full flex-col space-y-2">
        <div className="flex flex-row space-x-2">
          <input
            className="text-md mx-0.5 flex w-full cursor-pointer items-center gap-2 rounded-md border border-[#dcdcdc] bg-transparent p-1 px-2 text-[#929292] outline-0 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600"
            type="number"
            min={min}
            max={max}
            placeholder={'min'}
            value={minVal}
            onChange={(e) =>
              !isNaN(e.target.valueAsNumber) &&
              setMinVal(e.target.valueAsNumber > maxVal ? maxVal : e.target.valueAsNumber)
            }
          />
          <input
            className="text-md mx-0.5 flex w-full cursor-pointer items-center gap-2 rounded-md border border-[#dcdcdc] bg-transparent p-1 px-2 text-end text-[#929292] outline-0 transition-all duration-200 hover:border-blue-500 hover:bg-blue-50 hover:text-blue-600"
            type="number"
            min={min}
            max={max}
            placeholder={'max'}
            value={maxVal}
            onChange={(e) =>
              !isNaN(e.target.valueAsNumber) &&
              setMaxVal(e.target.valueAsNumber < minVal ? minVal : e.target.valueAsNumber)
            }
          />
        </div>
        <div>
          <RangeSlider
            min={min}
            max={max}
            value={[minVal, maxVal]}
            className="range-slider-search"
            onInput={([min, max]) => {
              setMinVal(min)
              setMaxVal(max)
            }}
          />
        </div>
      </div>
    </div>
  )
}
