import { createEvent, sample } from 'effector'
import { createForm } from 'effector-forms'

import { cancelSubscriptionFx, loadUserProfileFx, updateUserProfileFx } from '@/api'
import { $userProfile, loadAuthenticatedUser } from '@/entities/user'

export const $$profileForm = createForm<{
  phone: string
  email: string
  companyName: string
  companyPosition: string
  password: string
}>({
  fields: {
    phone: { init: '' },
    email: { init: '' },
    companyName: { init: '' },
    companyPosition: { init: '' },
    password: { init: '' },
  },
})

export const profileFieldUpdated = createEvent<{
  field: 'phone' | 'email' | 'company_name' | 'position' | 'password'
  value: string
}>()

export const cancelSubscriptionEvent = createEvent()

sample({
  //@ts-ignore
  clock: $userProfile,
  filter: Boolean,
  //@ts-ignore
  fn: (user) => ({
    phone: user.phone || '',
    email: user.email || '',
    password: '',
    companyName: user.company_name || '',
    companyPosition: user.position || '',
  }),
  target: $$profileForm.setInitialForm,
})

sample({
  clock: profileFieldUpdated,
  source: $userProfile,
  filter: (user, { field, value }) => field === 'password' || (!!user && user[field] !== value),
  fn: (_, { field, value }) => ({
    data: {
      [field]: value,
    },
  }),
  target: updateUserProfileFx,
})

sample({
  //@ts-ignore
  clock: updateUserProfileFx.done,
  target: loadAuthenticatedUser,
})

sample({
  clock: cancelSubscriptionEvent,
  fn: () => ({}),
  target: cancelSubscriptionFx,
})

sample({
  clock: cancelSubscriptionFx.finally,
  fn: () => ({}),
  target: loadUserProfileFx,
})
