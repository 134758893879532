import { useLocale, useTranslations } from 'next-intl'

import { FormEvent, useState } from 'react'

import { ShadcnButton } from '@/shared/ui/shadcn-button'
import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui/tooltip'
import { $userProfile } from '@entities/user/model'
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@shared/ui/dialog'
import Icon from '@shared/ui/icon'
import { Input } from '@shared/ui/input'
import { Label } from '@shared/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@shared/ui/select'
import { Textarea } from '@shared/ui/textarea'
import { useUnit } from 'effector-react'
import type { CompanyFeedbackModalProps } from './types'

const isEmail = (email: string) =>
  !!(
    email &&
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    )
  )

export const CompanyFeedbackModal = ({ for: $data }: CompanyFeedbackModalProps) => {
  const t = useTranslations()
  const locale = useLocale()

  const [company, user] = useUnit([$data, $userProfile])

  const [connectionEmail, setConnectionEmail] = useState(user?.email || '')
  const [connectionPhone, setConnectionPhone] = useState(user?.phone || '')

  const [message, setMessage] = useState('')
  const [subject, setSubject] = useState('')

  const submitDisabled = message.length === 0 || subject === ''

  const [isPending, setIsPending] = useState(false)
  const [open, setOpen] = useState(false)

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    setIsPending(true)

    setTimeout(() => {
      setIsPending(false)
      setOpen(false)
    }, 150)
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <button className="bg-lightgray hidden-button rounded-lg p-2 text-sm text-gray-400 transition">
          {t('company_feedback_give_feedback')}
        </button>
      </DialogTrigger>
      <DialogContent className="min-w-fit">
        <DialogTitle>{t('company_feedback_title')}</DialogTitle>
        <DialogDescription className="overflow-y-auto px-1">
          <form onSubmit={onSubmit} className="flex w-full flex-col space-y-2">
            <div className="flex w-full flex-col items-start space-y-2">
              <div className="space-y-2">
                <div className="flex flex-col">
                  <Label>{t('company_name')}</Label>
                  <span>{company?.title}</span>
                </div>
                <div className="flex flex-col">
                  <Label>{t('organizationForms')}</Label>
                  <span>{company?.legal_form}</span>
                </div>
                {company?.officers?.length && (
                  <div className="flex flex-col">
                    <Label>{t('officer')}</Label>
                    <span>{company?.officers[0].name}</span>
                  </div>
                )}
              </div>

              <div className="w-full">
                <Label>{t('subject')}</Label>
                <Select required onValueChange={setSubject}>
                  <SelectTrigger className="w-full">
                    <SelectValue placeholder={t('subject')} />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="company_feedback_data_not_actual">
                      {t('company_feedback_data_not_actual')}
                    </SelectItem>
                    <SelectItem value="company_feedback_change_company_information">
                      {t('company_feedback_change_company_information')}
                    </SelectItem>
                    <SelectItem value="company_feedback_delete_ie">{t('company_feedback_delete_ie')}</SelectItem>

                    <SelectItem value="edit_contacts">{t('edit_contacts')}</SelectItem>
                    <SelectItem value="company_feedback_no_data">{t('company_feedback_no_data')}</SelectItem>
                    <SelectItem value="other">{t('other')}</SelectItem>
                  </SelectContent>
                </Select>
              </div>

              <div className="w-full space-y-1">
                <Textarea
                  required
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  className="max-h-36 w-full"
                  rows={5}
                  minLength={40}
                />
                <span>40 / {new Intl.NumberFormat(locale).format(message.length)}</span>
              </div>
            </div>

            <div>
              <Label htmlFor="feedback-connection-email">{t('feedback_user_email')}</Label>
              <Input
                id={'feedback-connection-connectionEmail'}
                className={
                  'w-full border-2 p-2 outline-0 invalid:border-red-400 hover:border-blue-200 focus:border-blue-200 disabled:animate-pulse disabled:bg-gray-100'
                }
                type={'email'}
                value={connectionEmail}
                onChange={(e) => setConnectionEmail(e.target.value)}
                placeholder={t('feedback_user_email')}
                required
              />
            </div>

            <div>
              <Label htmlFor="feedback-connection-phone">{t('feedback_user_phone')}</Label>
              <Input
                id={'feedback-connection-connection-phone'}
                className={
                  'w-full border-2 p-2 outline-0 invalid:border-red-400 hover:border-blue-200 focus:border-blue-200 disabled:animate-pulse disabled:bg-gray-100'
                }
                type={'tel'}
                value={connectionPhone}
                onChange={(e) => setConnectionPhone(e.target.value)}
                placeholder={t('feedback_user_phone')}
                required
              />
            </div>

            <div className="w-full">
              <Tooltip>
                <TooltipTrigger asChild>
                  <ShadcnButton type={'submit'}>
                    {isPending ? <Icon name="loader-circle" className="animate-spin" /> : t('send')}
                  </ShadcnButton>
                </TooltipTrigger>
                {submitDisabled ? <TooltipContent>{t('fields_empty')}</TooltipContent> : null}
              </Tooltip>
            </div>
          </form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

export default CompanyFeedbackModal
