import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useInView } from 'react-intersection-observer'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import { CollapsibleBlock } from '@/widgets'
import { DataSection } from 'src/screens/company/ui/data-section'
import { MiniBar } from 'src/screens/company/ui/mini-bar'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import { CompanyItemLoading } from '../../../../shared/ui/companies/company-item-loading'
import { $applicationsYears, $govContractsYears, $quasiYears, visibilityChanged } from './model'

const GovContractsTabs = dynamic(() => import('./ui').then((def) => def.GovContractsTabs), { ssr: false })

export function GovContracts({ for: $data, isFull: $isFull }: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) {
  const t = useTranslations()
  const [data, govContractsYears, handleVisible, quasiYears, applicationYears, isFull] = useUnit([
    $data,
    $govContractsYears,
    visibilityChanged,
    $quasiYears,
    $applicationsYears,
    $isFull,
  ])

  const { ref } = useInView({
    onChange: (inView) =>
      handleVisible({
        visible: inView,
        id: data?.id,
        shouldBeLoaded: isFull,
      }),
    threshold: 0,
    triggerOnce: true,
  })

  const defaultContract = {
    sumByContract: {},
    sumByYear: {},
    totalByContracts: 0,
    totalByYear: 0,
    totalCount: 0,
  }

  return (
    <CollapsibleBlock
      ref={ref}
      title={
        <>
          {t('government_contracts')}
          <span className="ml-2 text-sm font-normal">{govContractsYears.totalCount || ''}</span>
        </>
      }
      id="government_contracts"
      dataType="government_contracts"
    >
      {JSON.stringify(govContractsYears) !== JSON.stringify(defaultContract) ? (
        <>
          <DataSection title={t('executed_on')}>
            <SafeView for={govContractsYears?.totalByYear} otherwise={t('not_found')}>
              {/*//@ts-ignore*/}
              <MiniBar
                type="estimate"
                data={govContractsYears.sumByYear}
                totalSum={govContractsYears.totalByYear}
                jurisdiction={data?.jurisdiction || 'kz'}
                style={{
                  '--min-width': govContractsYears.totalByYear.toString()?.length > 6 ? '150px' : '50px',
                }}
                additonalStyles="customMargins"
              />
            </SafeView>
          </DataSection>

          <DataSection title={t('contracts_by_years')}>
            <SafeView for={govContractsYears} withProps>
              {({ sumByContract, totalByContracts, totalByYear }) =>
                Object.keys(sumByContract).length > 0 ? (
                  //@ts-ignore
                  <MiniBar
                    type="estimate"
                    data={sumByContract}
                    totalSum={totalByContracts}
                    jurisdiction={data?.jurisdiction || 'kz'}
                    withCurrency={false}
                    style={{
                      '--min-width': totalByYear.toString()?.length > 6 ? '150px' : '50px',
                    }}
                    additonalStyles="customMargins"
                  />
                ) : (
                  t('not_found')
                )
              }
            </SafeView>
          </DataSection>

          <DataSection title={t('quasi_amounts')}>
            <SafeView for={quasiYears} withProps>
              {({ totalAmount, amounts }) =>
                Object.keys(amounts).length > 0 ? (
                  //@ts-ignore
                  <MiniBar
                    type="estimate"
                    data={amounts}
                    totalSum={totalAmount}
                    jurisdiction={data?.jurisdiction || 'kz'}
                    style={{
                      '--min-width': govContractsYears?.totalByYear.toString()?.length > 6 ? '150px' : '50px',
                    }}
                    additonalStyles="customMargins"
                  />
                ) : (
                  t('not_found')
                )
              }
            </SafeView>
          </DataSection>

          <DataSection title={t('quasi_contracts')}>
            <SafeView for={quasiYears} withProps>
              {({ years, totalYear }) =>
                Object.keys(years).length > 0 ? (
                  //@ts-ignore
                  <MiniBar
                    type="estimate"
                    data={years}
                    totalSum={totalYear}
                    jurisdiction={data?.jurisdiction || 'kz'}
                    style={{
                      '--min-width': govContractsYears?.totalByYear.toString()?.length > 6 ? '150px' : '50px',
                    }}
                    additonalStyles="customMargins"
                    withCurrency={false}
                  />
                ) : (
                  t('not_found')
                )
              }
            </SafeView>
          </DataSection>

          <DataSection title={t('gov_applications_year')}>
            <SafeView for={applicationYears} withProps>
              {({ years, totalYear }) =>
                Object.keys(years).length > 0 ? (
                  //@ts-ignore
                  <MiniBar
                    type="estimate"
                    data={years}
                    totalSum={totalYear}
                    jurisdiction={data?.jurisdiction || 'kz'}
                    withCurrency={false}
                    style={{
                      '--min-width': totalYear.toString()?.length > 6 ? '150px' : '50px',
                    }}
                    additonalStyles="customMargins"
                  />
                ) : (
                  t('not_found')
                )
              }
            </SafeView>
          </DataSection>

          <DataSection title={t('gov_applications_amount')}>
            <SafeView for={applicationYears} withProps>
              {({ amounts, totalAmount }) =>
                Object.keys(amounts).length > 0 ? (
                  //@ts-ignore
                  <MiniBar
                    type="estimate"
                    data={amounts}
                    totalSum={totalAmount}
                    jurisdiction={data?.jurisdiction || 'kz'}
                    style={{
                      '--min-width': totalAmount.toString()?.length > 6 ? '150px' : '50px',
                    }}
                    additonalStyles="customMargins"
                  />
                ) : (
                  t('not_found')
                )
              }
            </SafeView>
          </DataSection>

          <GovContractsTabs for={$data} isFull={$isFull} />
        </>
      ) : (
        <div className="col-span-full md:col-span-6">
          <CompanyItemLoading />
        </div>
      )}
    </CollapsibleBlock>
  )
}
