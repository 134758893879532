import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { MutableRefObject, useEffect, useRef, useState } from 'react'

import { filterKeys, filterNameToTranslateMap } from '@/constants'
import { $userProfile } from '@/entities/user'
import fmt from '@/lib/fmt'
import { $facets } from '@/screens'

import { FacetField } from './facet-field'
import { FacetTitle } from './facet-title'

const supportedFacetList: (typeof filterKeys)[number][] = [
  'addresses.state',
  'main_industry_code',
  'structure',
  'inactive',
  'jurisdiction',
  'company_size',
  'legal_form',
  'fin_taxes',
  'revenue',
  'contact_type',
]

const hiddenFacetList = ['jurisdiction']

const getCountOfFacet = (facet: string, facets: Record<any, any>) => {
  return facets[facet]?.[0]?.count
}

export function Facets({ listRef }: { listRef: MutableRefObject<HTMLDivElement | null> }) {
  const [facets, userProfile] = useUnit([$facets, $userProfile])
  const t = useTranslations()
  const router = useRouter()
  const { state: jurisdiction } = router.query
  const filterBlock = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)

  const isUserAdmitted = (userProfile?.plan_id || 0) > 2

  useEffect(() => {
    const setOffset = () => {
      const screenWidth = document.body.clientWidth
      const headerWidth = document.querySelector('header > div')?.clientWidth ?? 0
      const offset = (screenWidth - (headerWidth || 0)) / 2

      if (listRef.current && filterBlock.current) {
        listRef.current.style.paddingLeft = `${screenWidth <= 1140 && screenWidth > 990 ? 16 : offset + 16}px`

        listRef.current.style.minWidth = `${
          screenWidth < 1140 && screenWidth >= 989
            ? headerWidth - 400
            : screenWidth >= 1140 && screenWidth <= 1350
              ? headerWidth - 300
              : headerWidth
        }px`

        filterBlock.current.style.width = `${
          screenWidth <= 1140 && screenWidth > 990
            ? 400
            : screenWidth >= 1140 && screenWidth <= 1350
              ? 300 + offset * 2
              : screenWidth < 990
                ? 0
                : offset * 2.5
        }px`
        if (screenWidth <= 990 || jurisdiction === 'global') {
          filterBlock.current.style.display = 'none'
          listRef.current.style.paddingLeft = 'auto'
          listRef.current.style.width = 'auto'
          listRef.current.style.minWidth = 'auto'
        } else {
          filterBlock.current.style.display = 'block'
          listRef.current.style.width = `100%`
        }

        setWidth(filterBlock.current?.getBoundingClientRect().width)
      }
    }
    setOffset()
    window.addEventListener('resize', setOffset)
    return () => window.removeEventListener('resize', setOffset)
  }, [listRef, jurisdiction])

  const handleResetFacets = async () => {
    if (!isUserAdmitted) return

    const { state: jurisdiction, value } = router.query

    await router.push(
      {
        pathname: `/search/${jurisdiction}/${value || ''}`,
      },
      undefined,
      { shallow: true },
    )
  }

  return (
    <section className={`relative w-[${width}px]`} ref={filterBlock}>
      <div
        className="top-15 fixed right-0 flex h-[calc(100vh_-_102px)] w-full flex-col overflow-auto border-l pb-6"
        style={{
          width,
        }}
      >
        <div className="flex h-full flex-col overflow-auto px-4 py-2">
          <FacetTitle
            title={t.raw(`countries_explain`)[(jurisdiction as string).toUpperCase()]}
            count={fmt.number(getCountOfFacet('jurisdiction', facets), jurisdiction as any, false)}
          />

          <div className="flex flex-col gap-2 pb-2">
            <div className="mt-2 flex flex-col flex-wrap justify-between border-b-[1px] first:border-b-0 last:border-b-0">
              <button
                type="button"
                className="rounded-md bg-gray-100 p-1 font-medium transition hover:bg-blue-100 hover:text-blue-600 active:bg-blue-200"
                onClick={handleResetFacets}
              >
                <span className="w-full text-center">{t(`reset_all`)}</span>
              </button>
            </div>

            {Object.keys(facets)
              .filter(
                (field) =>
                  supportedFacetList.includes(field as (typeof filterKeys)[number]) && !hiddenFacetList.includes(field),
              )
              .reverse()
              .sort((field) => {
                return filterNameToTranslateMap[field]?.placeholder ? 1 : -1
              })
              .reverse()
              .map((field) => (
                <FacetField key={field} field={field} />
              ))}
          </div>
        </div>
      </div>
    </section>
  )
}
