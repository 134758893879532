import { ReactNode } from 'react'

import { SafeView } from '@/features'

export function FacetTitle({ title, count }: { title: ReactNode; count?: ReactNode }) {
  return (
    <div className="flex items-center gap-2">
      <h1 className="font-normal">{title}</h1>
      <SafeView for={count}>
        <i className="whitespace-pre break-normal text-sm text-gray-500">{count}</i>
      </SafeView>
    </div>
  )
}
