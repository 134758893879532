import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'
import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'

import { VirtualizedTable } from '@/ui'
import { CollapsibleBlock } from '@/widgets'

import { ForCompanyOrIndividualProps } from '../types'
import { $departments, visibilityChanged } from './model'

const topManagementList = ['Топ-менеджмент']

export const Departments = ({
  for: $data,
  isFull: $isFull,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull' | 'meta'>) => {
  const t = useTranslations()

  const [isFull, onVisible, data, departmentsList] = useUnit([$isFull, visibilityChanged, $data, $departments])

  const { ref } = useInView({
    onChange: (visible) => {
      onVisible({
        visible,
        id: data?.id,
        shouldBeLoaded: isFull,
      })
    },
  })

  const [topManagementDepartments, departments] = useMemo(() => {
    if (!isFull) {
      return [[], Array(5).fill('dummy')]
    }

    return [
      departmentsList.departments?.filter((department: string) => topManagementList.includes(department)) || [],
      departmentsList.departments?.filter((department: string) => !topManagementList.includes(department)) || [],
    ]
  }, [isFull, departmentsList])

  if (!data) {
    return null
  }

  return (
    <CollapsibleBlock
      ref={ref}
      title={
        <>
          {t('departments')}
          <span className="ml-2 text-sm font-normal">{(departmentsList?.departments?.length || 0) + 1}</span>
        </>
      }
      dataType="departments"
      id="departments"
      className="relative"
    >
      <div className={'col-span-full mb-2'}>
        <span className={'text-sm text-gray-400'}>{t(`data_was_modeled_by_ai`)}</span>
      </div>

      <SafeView for={!isFull}>
        <div className="absolute z-50 m-auto flex h-full w-full flex-col justify-center align-middle">
          <VirtualizedTable list={[]} columns={[]} mocked></VirtualizedTable>
        </div>
      </SafeView>

      <SafeView for={true}>
        <div className={`relative col-span-full`}>
          <div className={`${!isFull ? 'pointer-events-none select-none rounded blur-lg' : ''}`}>
            <div className={'flex-start flex flex-row flex-wrap justify-center'}>
              <div className={'flex w-full flex-col p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'}>
                <span className={'rounded border-2 p-1 text-center'}>{data.name || data.name_en}</span>
              </div>
            </div>

            <ListDepartments departments={topManagementDepartments} />
            <ListDepartments departments={departments} />
          </div>
        </div>
      </SafeView>
    </CollapsibleBlock>
  )
}

const ListDepartments = ({ departments }: { departments: string[] }) => {
  return (
    <div className={'flex-start flex flex-row flex-wrap justify-center'}>
      {departments.map((department: string, index: number) => (
        <div className={'flex w-full flex-col p-2 sm:w-1/2 md:w-1/3 lg:w-1/4'} key={index}>
          <span className={'rounded border-2 p-1 text-center'}>{department}</span>
        </div>
      ))}
    </div>
  )
}
