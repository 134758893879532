import { AxiosError, AxiosInstance } from 'axios'
import crypto from 'crypto'
import { Effect, Store, attach, createEffect, createStore, sample } from 'effector'
import md5 from 'md5'
import { EffectState, status } from 'patronum/status'
import { Simplify } from 'type-fest'
import { v4 } from 'uuid'

import { Nullable } from '@/T'
import { $axiosInstance, axiosBaseInstance } from '@/shared-events'
import { paths } from './api.types'

type Path = keyof paths
type PathMethod<T extends Path> = keyof paths[T]
export type RequestParams<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  requestBody: any
}
  ? paths[P][M]['requestBody']['content']['application/json']
  : undefined

type RequestHeaders<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  parameters: { header: NonNullable<unknown> }
}
  ? paths[P][M]['parameters']['header']
  : undefined

export type RequestPath<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  parameters: any
}
  ? Omit<paths[P][M]['parameters']['path'], 'header'>
  : undefined

export type ResponseType<P extends Path, M extends PathMethod<P>> = paths[P][M] extends {
  responses: { 200: { content: any } }
}
  ? paths[P][M]['responses'][200]['content']['application/json']
  : undefined

export type UnwrapApiResponse<P extends Path, M extends PathMethod<P>> = paths[P][M] extends { responses: any }
  ? paths[P][M]['responses'][200]['content']['application/json']
  : undefined

type CreateApiCallConfig<P, M> = {
  method: Uppercase<M extends string ? string : string>
  source: P
  xApiKey?: Nullable<string>
  responseType?: Nullable<'arraybuffer' | 'blob' | 'document' | 'json' | 'text' | 'stream'>
}

const goAesBlockSize = 16
const aesKeyBytes = Buffer.from(process.env.NEXT_PUBLIC_AES_KEY || '', 'utf8')
const algorithmList = {
  16: 'aes-128-ctr',
  24: 'aes-196-ctr',
  32: 'aes-256-ctr',
}

const decrypt = (hashedData: string) => {
  if (!hashedData || !/^[0-9a-fA-F]+$/i.test(hashedData)) {
    return hashedData
  }

  const contents = Buffer.from(hashedData, 'hex')

  const iv = contents.subarray(0, goAesBlockSize)
  const encryptedText = contents.subarray(goAesBlockSize)
  const decipher = crypto.createDecipheriv(
    algorithmList[aesKeyBytes.length as keyof typeof algorithmList] as string,
    aesKeyBytes as unknown as crypto.CipherKey,
    // @ts-ignore
    iv,
    {},
  )

  // @ts-ignore
  const decrypted = decipher.update(encryptedText, undefined, 'utf8') + decipher.final('utf8')

  return decrypted.toString()
}

// @ts-ignore
function traverseAndDecryptHexFields<T>(source: Path, obj, decryptFunction: (arg0: any) => any): T {
  switch (source) {
    case '/business/search':
      for (let i = 0; i < obj.company.length; i++) {
        if (!obj.company[i]) continue

        if (obj.company[i].id) obj.company[i].id = decryptFunction(obj.company[i].id)
        if (obj.company[i].name) obj.company[i].name = decryptFunction(obj.company[i].name)
        if (obj.company[i].name_en) obj.company[i].name_en = decryptFunction(obj.company[i].name_en)
        if (obj.company[i].name_native) obj.company[i].name_native = decryptFunction(obj.company[i].name_native)
        if (obj.company[i].title) obj.company[i].title = decryptFunction(obj.company[i].title)

        for (let j = 0; j < (obj.company[i].identifiers?.length || 0); j++) {
          if (obj.company[i].identifiers && obj.company[i].identifiers[j].value)
            obj.company[i].identifiers[j].value = decryptFunction(obj.company[i].identifiers[j].value)
        }
      }
      break
    case '/business/search/highlights':
      for (let i = 0; i < obj.business.length; i++) {
        if (!obj.business[i]) continue

        if (obj.business[i].company.id) obj.business[i].company.id = decryptFunction(obj.business[i].company.id)
        if (obj.business[i].company.name) obj.business[i].company.name = decryptFunction(obj.business[i].company.name)
        if (obj.business[i].company.name_en)
          obj.business[i].company.name_en = decryptFunction(obj.business[i].company.name_en)
        if (obj.business[i].company.name_native)
          obj.business[i].company.name_native = decryptFunction(obj.business[i].company.name_native)
        if (obj.business[i].company.title)
          obj.business[i].company.title = decryptFunction(obj.business[i].company.title)

        if (obj.business[i].highlights.title)
          obj.business[i].highlights.title = decryptFunction(obj.business[i].highlights.title)
        if (obj.business[i].highlights.highlight)
          obj.business[i].highlights.highlight = decryptFunction(obj.business[i].highlights.highlight)

        for (let j = 0; j < (obj.business[i].company.identifiers?.length || 0); j++) {
          if (obj.business[i].company.identifiers && obj.business[i].company.identifiers[j].value)
            obj.business[i].company.identifiers[j].value = decryptFunction(obj.business[i].company.identifiers[j].value)
        }

        for (let j = 0; j < (obj.business[i].company.officers?.length || 0); j++) {
          obj.business[i].company.officers[j].name = decryptFunction(obj.business[i].company.officers[j].name)
          obj.business[i].company.officers[j].name_en = decryptFunction(obj.business[i].company.officers[j].name_en)
        }
      }
      break
  }

  return obj as T
}

export const generateHeaders: any = (key: Nullable<string>, ua?: string): Record<any, any> => {
  const HASH_CODE = process.env.NEXT_PUBLIC_HASH
  const userAgent = ua || (typeof window === 'undefined' ? ua : window?.navigator.userAgent)
  const uuid = v4()

  return {
    'X-SUM': md5(`${uuid}${userAgent}${HASH_CODE}`),
    'X-REQUEST-ID': uuid,
    ...(typeof window === 'undefined'
      ? {
          'User-Agent': userAgent,
        }
      : {}),
  }
}

const encryptedAPIs: (keyof paths)[] = ['/business/search', '/business/search/highlights']

export const createApiCall = <P extends Path, M extends PathMethod<P>>({
  method,
  source,
  xApiKey = null,
  responseType = 'json',
}: CreateApiCallConfig<P, M>): [
  Effect<
    {
      data?: Simplify<RequestParams<P, M>>
      headers?: Nullable<RequestHeaders<P, M> | Record<string, any>>
      cookies?: Nullable<string>
      query?: Nullable<Record<string, any>>
      path?: Nullable<RequestPath<P, M>>
    },
    Simplify<ResponseType<P, M>>,
    AxiosError<{ message: string; status: number }>
  >,
  Store<boolean>,
  Store<EffectState>,
  Store<Nullable<Record<string, any>>>,
] => {
  const baseFx = createEffect<
    {
      data?: Simplify<RequestParams<P, M>>
      headers?: Nullable<Record<string, any>>
      cookies?: Nullable<string>
      query?: Nullable<Record<string, any>>
      path?: Nullable<RequestPath<P, M>>
      instance: AxiosInstance | null
    },
    Simplify<ResponseType<P, M>>,
    AxiosError<{ message: string; status: number }>
  >({
    name: `$fx_${source}`,
    handler: async (params) => {
      const { data, cookies, query, headers = null, path, instance } = params || {}
      const hasReplaceableParam = Boolean(path)
      let formattedQuery = source as string
      if (hasReplaceableParam && path) {
        //TODO: extract regex
        for (const prop in path) {
          const regex = new RegExp(`{${prop}}`, 'g')
          // @ts-ignore
          formattedQuery = formattedQuery.replace(regex, path[prop])
        }
      }

      const { data: responseData } = await (instance || axiosBaseInstance)<ResponseType<P, M>>(
        formattedQuery as string,
        {
          method,
          data,
          params: query,
          headers: Object.assign(headers || generateHeaders(xApiKey), cookies ? { Cookie: cookies } : {}),
          responseType: responseType || ('json' as const),
        },
      )

      if (encryptedAPIs.includes(source) && responseData) {
        try {
          return traverseAndDecryptHexFields(source, responseData, decrypt)
        } catch (e) {
          console.log(`${formattedQuery}: error: ${e}`)
        }
      }
      return responseData
    },
  })

  const effectFx = attach({
    source: $axiosInstance,
    effect: baseFx,
    mapParams: (
      params: {
        data?: Simplify<RequestParams<P, M>>
        headers?: Nullable<Record<string, any>>
        cookies?: Nullable<string>
        query?: Nullable<Record<string, any>>
        path?: Nullable<RequestPath<P, M>>
      },
      instance,
    ) => ({
      ...params,
      instance,
    }),
  })

  const $status = status({ effect: effectFx })
  const $error = createStore<Record<string, any> | null>(null, {
    name: `$apiError_${source}`,
  })

  sample({
    clock: effectFx.failData,
    fn: ({ response }) => response?.data ?? null,
    target: $error,
  })

  const $isLoading = $status.map((status) => status === 'pending')
  return [effectFx, $isLoading, $status, $error]
}
