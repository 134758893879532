import { useField } from 'effector-forms'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import { EditableField } from '@/ui'

import { $$profileForm, profileFieldUpdated } from './model'

export const ProfileScreen = () => {
  const t = useTranslations()
  const [user, updateProfile] = useUnit([$userProfile, profileFieldUpdated])

  const phoneField = useField($$profileForm.fields.phone)
  const companyField = useField($$profileForm.fields.companyName)
  const positionField = useField($$profileForm.fields.companyPosition)
  const passwordField = useField($$profileForm.fields.password)

  const onEditEnd = (field: 'phone' | 'email' | 'company_name' | 'position' | 'password') => (value: string) => {
    updateProfile({
      field,
      value,
    })
  }

  return (
    <SafeView for={user} otherwise={null} withProps>
      {(user) => (
        <div className="col-span-full grid grid-cols-4 pb-4">
          <div className="col-span-full grid grid-cols-4 border-b py-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('phone')}</h2>
            <h2 className="col-span-full flex flex-wrap justify-between space-x-4 md:col-span-2">
              <EditableField
                editTitle={t('edit_phone')}
                onEditEnd={onEditEnd('phone')}
                type="tel"
                name="phone"
                label={t('phone')}
                onChange={(e) => phoneField.onChange(e.target.value)}
                value={phoneField.value}
              >
                {user.phone || '-'}
              </EditableField>
            </h2>
          </div>
          <div className="col-span-full grid grid-cols-4 border-b py-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('company')}</h2>
            <h2 className="col-span-full flex flex-wrap justify-between space-x-4 md:col-span-2">
              <EditableField
                editTitle={t('edit_company')}
                onEditEnd={onEditEnd('company_name')}
                label={t('edit_company')}
                type="text"
                name="companyName"
                onChange={(e) => companyField.onChange(e.target.value)}
                value={companyField.value}
              >
                {user.company_name || '-'}
              </EditableField>
            </h2>
          </div>
          <div className="col-span-full grid grid-cols-4 border-b py-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('position_company')}</h2>
            <h2 className="col-span-full flex flex-wrap justify-between space-x-4 md:col-span-2">
              <EditableField
                editTitle={t('edit_position')}
                onEditEnd={onEditEnd('position')}
                label={t('position_company')}
                type="text"
                name="position"
                onChange={(e) => positionField.onChange(e.target.value)}
                value={positionField.value}
              >
                {user.position || '-'}
              </EditableField>
            </h2>
          </div>
          <div className="col-span-full grid grid-cols-4 border-b py-4">
            <h2 className="col-span-full text-lg md:col-span-2 ">{t('password')}</h2>
            <h2 className="col-span-full flex flex-wrap justify-between space-x-4 md:col-span-2">
              <EditableField
                editTitle={t('edit_password')}
                onEditEnd={onEditEnd('password')}
                label={t('password')}
                type="password"
                name="password"
                onChange={(e) => passwordField.onChange(e.target.value)}
                value={passwordField.value}
              >
                ********
              </EditableField>
            </h2>
          </div>
        </div>
      )}
    </SafeView>
  )
}
