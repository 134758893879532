import _ from 'lodash'

import { ResponseType } from 'src/shared/api/model'

type Meta = ResponseType<'/business/{id}/contracts/meta', 'get'>
type MetaKeys = Exclude<keyof Meta, 'id'>

export const processMetaContracts = (metaKey: MetaKeys, meta: Meta) => {
  const yearlyData = _.chain(meta[metaKey])
    // @ts-ignore
    .groupBy('year')
    .mapValues((yearGroup: any) => ({
      count: _.sumBy(yearGroup, 'count'),
      sum: _.sumBy(yearGroup, 'sum'),
    }))
    .value()

  const total = _.reduce(
    yearlyData,
    (total, val, year) => {
      total.count += val.count
      total.sum += val.sum
      total.years[year] = val.count
      total.amounts[year] = val.sum
      return total
    },
    { years: {}, amounts: {}, count: 0, sum: 0 } as {
      years: Record<string, number>
      amounts: Record<string, number>
      count: number
      sum: number
    },
  )

  return { ...total, totalYear: total.count, totalAmount: total.sum }
}
