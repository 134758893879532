// @ts-nocheck
import { Cell } from '@tanstack/table-core'
import { useStoreMap, useUnit } from 'effector-react'
import _ from 'lodash'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useMemo } from 'react'
import { useInView } from 'react-intersection-observer'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { Placeholder } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import { $subsidiaries, visibilityChanged } from './model'

const VirtualizedTable = dynamic(() => import('@/ui').then((d) => d.VirtualizedTable), {
  ssr: false,
})

export function EquityParticipationInCompanies({
  for: $data,
  isFull: $isFull,
  meta: $meta,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull' | 'meta'>) {
  const t = useTranslations()
  const [onVisible, data, isFull, meta] = useUnit([visibilityChanged, $data, $isFull, $meta])
  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (visible) =>
      onVisible({
        visible,
        id: data?.id,
        shouldBeLoaded: isFull,
      }),
  })

  const tableData = useStoreMap({
    store: $subsidiaries,
    fn: (subsidiaries) => {
      const formatCompanyName = (company: SetRequired<typeof subsidiaries, 'companies'>['companies'][0]) => {
        const name = _.get(company, 'name') || _.get(company, 'name_native') || _.get(company, 'name_en')
        const link = `${window.location.origin}/companies/${data?.jurisdiction ?? 'kz'}/${company.id}`

        return (
          <a className="cursor-pointer text-primary" href={link} rel="nofollow">
            {name}
          </a>
        )
      }

      const formatRegistrationDate = (company: SetRequired<typeof subsidiaries, 'companies'>['companies'][0]) => {
        const incorporation_date = _.get(company, 'incorporation_date')
        return incorporation_date ? fmt.date(new Date(incorporation_date)) : '-'
      }

      const formatEmployeeCount = (company: SetRequired<typeof subsidiaries, 'companies'>['companies'][0]) => {
        const headcount = _.get(company, 'headcount', [])
        const lastCount = _.get(_.last(headcount), 'count')
        return lastCount ? `${lastCount} ${t('people')}` : '-'
      }

      if (!_.get(subsidiaries, 'companies.length')) return []
      return _.map(subsidiaries?.companies, (company: any) => ({
        owner_company: formatCompanyName(company),
        registration_date: formatRegistrationDate(company),
        company_size: _.defaultTo(company.company_size, '-'),
        employee_count: formatEmployeeCount(company),
      }))
    },
    keys: [data?.id],
  })

  const cells = useMemo(
    () =>
      t.raw('equity_participation_head').map((cell: Record<string, any>) =>
        cell.accessorKey === 'owner_company'
          ? {
              ...cell,
              cell: (c: Cell<any, any>) => c.renderValue(),
            }
          : cell,
      ),
    [t],
  )

  return (
    <CollapsibleBlock
      ref={ref}
      title={
        <>
          {t('equity_participation')}{' '}
          {isFull && <span className="ml-2 text-sm font-normal">{meta?.subsidiaries || tableData?.length || ''}</span>}
        </>
      }
      dataType="equity_participation"
      id="equity_participation"
      className="select-none"
    >
      <div className="col-span-full select-none">
        <SafeView for={isFull}>
          <SafeView for={tableData?.length > 0} otherwise={t('company_is_not_an_owner')}>
            <VirtualizedTable list={tableData} columns={cells} />
          </SafeView>
        </SafeView>

        <SafeView for={!isFull}>
          <Placeholder withGeneration />
          <Placeholder withGeneration />
          <VirtualizedTable list={tableData} columns={cells} mocked />
        </SafeView>
      </div>
    </CollapsibleBlock>
  )
}
