import Image from 'next/image'
import React, { useEffect, useState } from 'react'

interface NotificationProps {
  message: string
  color: string
}

const ErrorNotification: React.FC<NotificationProps> = ({ message, color }) => {
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    setIsVisible(true)
    const timer = setTimeout(() => {
      setIsVisible(false)
    }, 2000)
    return () => clearTimeout(timer)
  }, [message])

  return (
    <div
      className={`fixed left-1/2 top-0 w-auto max-w-sm -translate-x-1/2 transform rounded-lg p-6 text-white shadow-lg transition-opacity duration-1000 ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{
        transition: 'opacity 1s ease-in-out',
        zIndex: '1000',
        // fontWeight: 'bold',
        fontSize: '16px',
        color: 'white',
        background: 'black',
        margin: '20px 0 0 0',
        boxShadow: '0 0 1px rgba(67,90,111,.3), 0 5px 8px -4px rgba(67,90,111,.3)',
        borderRadius: '12px',
        height: '50px',
        width: '320px',
      }}
    >
      <div style={{ display: 'flex', transform: 'translate(0, -10px)' }}>
        <div style={{ padding: ' 0 10px 0 0' }}>
          <Image src={'/icons/error.svg'} alt={'add to favorites icon'} width={20} height={20} />
        </div>
        {message}
      </div>
    </div>
  )
}

export default ErrorNotification
