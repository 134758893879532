import { sample } from 'effector'
import { createForm } from 'effector-forms'
import { string } from 'yup'

import { recoveryPasswordFx } from '@/api'
import { createRule } from '@/lib/effector-forms'
import { $router, pushFx } from '@/shared-events'

export const $$recoveryPasswordForm = createForm({
  fields: {
    password: {
      init: '',
      rules: [
        createRule({
          schema: string().required().min(4),
          name: 'required',
        }),
      ],
    },
    repeatPassword: {
      init: '',
      rules: [
        createRule({
          schema: string().required().min(4),
          name: 'required',
        }),
      ],
    },
  },
})

sample({
  //@ts-ignore
  clock: $$recoveryPasswordForm.formValidated,
  source: $router,
  filter: (_, { password, repeatPassword }) => password === repeatPassword,
  fn: (router, { password }) => {
    return { data: { password, token: router?.query.token as string } }
  },
  target: recoveryPasswordFx,
})

sample({
  clock: recoveryPasswordFx.done,
  target: pushFx.prepend(() => '/auth/login'),
})
