import { useTranslations } from 'next-intl'
import { ReactNode, useState } from 'react'

import { SafeView } from '@/features'

import { FloatInput, FloatInputProps } from './float-input'

export interface EditableFieldProps extends FloatInputProps {
  children: ReactNode
  editTitle: string
  onEditEnd: (v: any) => void
}
export const EditableField = ({
  children,
  editTitle,
  onEditEnd,
  onChange,
  value,
  label,
  placeholder,
  type,
  name,
}: EditableFieldProps) => {
  const [edit, updateEdit] = useState(false)
  const t = useTranslations()

  const onEditClick = () => {
    if (edit) {
      onEditEnd(value)
      updateEdit(false)
    } else {
      updateEdit(true)
    }
  }

  return (
    <>
      <SafeView for={edit} otherwise={children}>
        <FloatInput
          onChange={onChange}
          value={value}
          label={label}
          placeholder={placeholder}
          type={type}
          name={name}
          className="rounded"
        />
      </SafeView>

      <span className="cursor-pointer text-sm text-primary" onClick={onEditClick}>
        {!edit ? editTitle : t('save')}
      </span>
    </>
  )
}
