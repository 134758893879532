// @ts-nocheck
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { chain, reduce } from 'lodash'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useCallback, useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'
import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { countOfCategorySet } from '@/screens'
import { TableFilterItem, TableFilters } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import { CompanyItemLoading } from '../../../../shared/ui/companies/company-item-loading'
import { $isLoaded, $registrationHistory, visibilityChanged } from './model'

const VirtualizedTable = dynamic(() => import('@/ui').then((d) => d.VirtualizedTable), {
  ssr: false,
})

export function RegistrationHistory({
  for: $data,
  isFull: $isFull,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) {
  const [handleVisible, data, isFull, registrationHistory, isLoaded, setCountOfCategory] = useUnit([
    visibilityChanged,
    $data,
    $isFull,
    $registrationHistory,
    $isLoaded,
    countOfCategorySet,
  ])
  const t = useTranslations()
  const [filter, setFilter] = useState<string | null>(null)

  const { ref } = useInView({
    triggerOnce: true,
    onChange: (visible) =>
      handleVisible({
        visible,
        id: data?.id,
      }),
  })

  const filters = t.raw('registration_history_change_values')

  const totalCount = useStoreMap({
    store: $registrationHistory,
    fn: ({ meta }) => reduce(meta, (prev, curr) => (curr?.count || 0) + prev, 0),
    keys: [],
  })

  useEffect(() => {
    setCountOfCategory({
      category: 'registration_history',
      count: totalCount,
    })
  }, [setCountOfCategory, totalCount])

  const checkIfOfficer = useCallback((history: Record<string, any>, field: string) => {
    return history.attr === 'officer' ? fmt.toProperCase(history && history[field]) : history[field] || '-'
  }, [])

  const list = useStoreMap({
    store: $registrationHistory,
    fn: (data) =>
      chain(data?.events)
        .map((t) => ({
          date_change: t.date ? new Date(t.date).toLocaleDateString() : '-',
          attr_value_was: t.before ? checkIfOfficer(t, 'before') : '-',
          attr_value_now: t.after ? checkIfOfficer(t, 'after') : '-',
          attr_name: t.attr ? filters[t.attr] || '-' : '-',
        }))
        .orderBy(['date_change'], ['desc'])
        .value(),
    keys: [data?.id],
  })

  return (
    <CollapsibleBlock
      title={
        <>
          {t('registration_history')}
          {isFull && <span className="ml-2 text-sm font-normal">{totalCount || ''}</span>}
        </>
      }
      dataType="registration_history"
      id="registration_history"
      ref={ref}
      className="select-none"
    >
      <SafeView
        for={isLoaded}
        otherwise={
          <div className="col-span-full">
            <CompanyItemLoading />
          </div>
        }
      >
        <div className="col-span-full">
          <TableFilters applyFilter={setFilter} isFull={isFull}>
            {({ onMouseLeave, onMouseOver, applyFilter }) => (
              <>
                <TableFilterItem
                  onMouseOver={onMouseOver}
                  onMouseLeave={onMouseLeave}
                  className=""
                  onClick={() => applyFilter('-1')}
                >
                  {totalCount}&nbsp;
                  {t('all')}
                </TableFilterItem>
                {Object.entries(filters).map(([type, name]) => {
                  if (!registrationHistory) return null
                  const byLength = registrationHistory.meta?.filter((e: any) => e?.type === type)[0]?.count

                  if (!byLength) {
                    return null
                  }
                  return (
                    <TableFilterItem
                      key={type}
                      onMouseOver={onMouseOver}
                      onMouseLeave={onMouseLeave}
                      className={clsx({
                        'font-bold': filters[type] === filter,
                      })}
                      onClick={() => applyFilter(filters[type])}
                    >
                      {byLength}&nbsp;{name}
                    </TableFilterItem>
                  )
                })}
              </>
            )}
          </TableFilters>
          <VirtualizedTable
            list={list}
            columns={t.raw('registration_history_head')}
            mocked={!isFull}
            globalFilter={filter}
            filterKey="attr_name"
          />
          {data?.structure === 'se' && (
            <a
              href="https://stat.gov.kz/jur-search/filter"
              rel="nofollow"
              target="_blank"
              className="text-light col-span-full mt-4 text-xs text-gray-500 md:col-span-3"
            >
              {t('open_source', {
                name: 'stat.gov.kz',
              })}
            </a>
          )}
        </div>
      </SafeView>
    </CollapsibleBlock>
  )
}
