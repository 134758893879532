import { createEvent, createStore, sample } from 'effector'

import { loadCompanySocialNetworksFx, loadContactsReactionListFx, postContactsReactionFx } from '@/api'
import { createBindingForVisibility } from '@/features'

import { ResponseType } from 'src/shared/api/model'

export const { $inView, visibilityChanged, $companyId, $isLoaded, $shouldBeLoaded } = createBindingForVisibility()
export const $socialNetworksContacts = createStore<ResponseType<'/business/{id}/social_networks', 'get'>>(
  {} as unknown as ResponseType<'/business/{id}/social_networks', 'get'>,
)
export const $contactsReactions = createStore<ResponseType<'/business/get/reaction/contacts', 'post'>>([])
export const $contactsReactionsIDs = createStore<number[]>([])
export const setContactsReactionsIDs = createEvent<number[]>()
export const getContactsReactions = createEvent()
export const postContactsReaction = createEvent<{
  contact_id: number
  is_like: boolean
}>()

sample({
  clock: $inView,
  source: { companyId: $companyId, isLoaded: $isLoaded, isFull: $shouldBeLoaded },
  filter: ({ isLoaded, companyId, isFull }, inView) => isFull && inView && !isLoaded && !!companyId,
  fn: ({ companyId }) => {
    return {
      path: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: companyId! as string,
      },
    }
  },
  target: loadCompanySocialNetworksFx,
})

sample({
  clock: setContactsReactionsIDs,
  target: $contactsReactionsIDs,
})

sample({
  clock: [getContactsReactions, postContactsReactionFx.done],
  source: $contactsReactionsIDs,
  filter: (data) => data.length > 0,
  fn: (data) => {
    return {
      data: {
        ids: data,
      },
    }
  },
  target: loadContactsReactionListFx,
})

sample({
  clock: loadContactsReactionListFx.doneData,
  target: $contactsReactions,
})

sample({
  clock: postContactsReaction,
  fn: ({ contact_id, is_like }) => {
    return {
      data: {
        contact_id,
        is_like,
      },
    }
  },
  target: postContactsReactionFx,
})

sample({
  clock: loadCompanySocialNetworksFx.doneData,
  fn: (data) => {
    return data
  },
  target: $socialNetworksContacts,
})

sample({
  clock: loadCompanySocialNetworksFx.done,
  fn: () => true,
  target: $isLoaded,
})
