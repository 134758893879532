import { useUnit } from 'effector-react'
import _, { omit } from 'lodash'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { useEffect } from 'react'

import fmt from '@/lib/fmt'

import { $appliedFacets, $companiesFindPending, $facets, appliedFacetsCalculated } from '@/screens'
import { SafeView } from '@features'
import { ExportCompaniesButton } from '@screens/search/export'
import { filterKeys } from '@shared/constants'

const translateMap = {
  fin_taxes: 'taxes',
  revenue: 'revenue',
} as Record<(typeof filterKeys)[number], string>

export function AppliedFacets() {
  const [facets, appliedFacets, calculateAppliedFacets, findCompaniesPending] = useUnit([
    $facets,
    $appliedFacets,
    appliedFacetsCalculated,
    $companiesFindPending,
  ])
  const router = useRouter()
  const t = useTranslations()

  const { state: jurisdiction } = router.query

  useEffect(() => {
    if (router.query) {
      calculateAppliedFacets(router.query)
    }
  }, [calculateAppliedFacets, router.query])

  const handleClick = async (filter: string, filterName: (typeof filterKeys)[number]) => {
    const currentFacets = { ...router.query }
    const allAppliedFromCategory = currentFacets[filterName]

    if (allAppliedFromCategory) {
      const arrayOfApplied = (allAppliedFromCategory as string).split(',')
      const cleared = (() => {
        if (['revenue', 'fin_taxes'].includes(filterName)) {
          return []
        }
        return _.remove(arrayOfApplied, (item) => item.toUpperCase() !== filter.toUpperCase())
      })()

      if (cleared.length === 0) {
        Reflect.deleteProperty(currentFacets, filterName)

        await router.push(
          {
            pathname: router.pathname,
            query: omit(currentFacets, filterName),
          },
          undefined,
          {
            shallow: true,
          },
        )

        return
      }

      await router.push(
        {
          pathname: router.pathname,
          query: _.omitBy(
            { ...currentFacets, [filterName]: cleared.length ? cleared.join(',') : undefined },
            _.isUndefined,
          ),
        },
        undefined,
        { shallow: true },
      )
    }
  }

  const count = (facets?.jurisdiction as Array<{ value: string; count: number }>)?.find(
    (x) => x.value === jurisdiction,
  )?.count

  const hasFilters = appliedFacets.filter(([_, key]) => !['state', 'value'].includes(key)).length !== 0

  return (
    <>
      <SafeView for={hasFilters}>
        <div className="w-full pb-4">
          <ul className="flex flex-wrap gap-2">
            {appliedFacets?.map(([facets, key]) =>
              facets?.map((facet) => {
                if (['revenue', 'fin_taxes'].includes(key)) {
                  return (
                    <li
                      key={`${key}_${facet.value}`}
                      className={`facets-center active-search-filter flex w-auto cursor-pointer gap-2 rounded-md border border-transparent bg-[#e8f1fa] px-4 py-2 text-sm text-[#2271d1] transition-all duration-200`}
                      onClick={() => handleClick(facet.value, key as any)}
                    >
                      <span>{t(translateMap[key])}</span>
                      <span>
                        {facet.value
                          .split('-')
                          .map((a) =>
                            new Intl.NumberFormat(router.locale || 'ru', {
                              notation: 'compact',
                              compactDisplay: 'short',
                            }).format(parseInt(a)),
                          )
                          .join(' - ')}
                      </span>
                    </li>
                  )
                } else if (['true', 'false'].includes(facet.value) && key === 'inactive') {
                  return (
                    <li
                      key={`${key}_${facet.value}`}
                      className={`facets-center active-search-filter flex w-auto cursor-pointer gap-2 rounded-md border border-transparent bg-[#e8f1fa] px-4 py-2 text-sm text-[#2271d1] transition-all duration-200`}
                      onClick={() => handleClick(facet.value, key as any)}
                    >
                      {facet.value === 'true' ? t(`inactiv_person`) : t(`active_person`)}
                    </li>
                  )
                } else if (!['true', 'false'].includes(facet.value) && facet.value?.length > 1) {
                  return (
                    <li
                      key={`${key}_${facet.value}`}
                      className={`facets-center active-search-filter flex w-auto cursor-pointer gap-2 rounded-md border border-transparent bg-[#e8f1fa] px-4 py-2 text-sm text-[#2271d1] transition-all duration-200`}
                      onClick={() => handleClick(facet.value, key as any)}
                    >
                      {key === 'company_size'
                        ? t.raw('company_size_labels')[facet.name] || facet.label
                        : key === 'contact_type'
                          ? t(`contact_type.${facet.name}`) || null
                          : facet.name || facet.label}
                    </li>
                  )
                }

                return null
              }),
            )}
          </ul>
        </div>
      </SafeView>
      <div className="flex w-full items-center justify-between gap-2 pb-4 pr-[16px]">
        <h2 className="w-full text-gray-500">
          {findCompaniesPending ? (
            <div className="relative flex w-full flex-col">
              <span className="block h-4 w-4/6 animate-pulse rounded bg-gray-300"></span>
            </div>
          ) : (
            t.rich(`found`, { count: fmt.rawNumber(count || 0) })
          )}
        </h2>

        <div className="hidden w-1/4 md:block">
          <ExportCompaniesButton />
        </div>
      </div>
    </>
  )
}
