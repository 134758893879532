import { createEvent, createStore, sample } from 'effector'

import { Nullable } from '@/T'

export type Jurisdiction = 'kz' | 'uz' | 'gl' | 'global'

export enum Jurisdictions {
  KZ = 'kz',
  UZ = 'uz',
  GLOBAL = 'gl',
}

export const $jurisdiction = createStore<Nullable<Jurisdiction>>(null)

export const jurisdictionSet = createEvent<Jurisdictions>()

sample({
  clock: jurisdictionSet,
  target: $jurisdiction,
})
