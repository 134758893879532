import { createEvent, createStore, sample } from 'effector'

import { loadUserProfileFx } from '@/api'
import { cookiesClear, cookiesSet } from '@/lib/cookies-manager'
import { loadUserFx } from '@/shared-events'
import { Nullable } from '@/T'

import { User } from './types'

export const loadAuthenticatedUser = createEvent<{ cookies?: string }>()
export const userLoggedOut = createEvent()
export const $userProfile = createStore<Nullable<User>>(null)
export const $access = createStore<
  Nullable<{
    name: 'access'
    value: string
    expiresAt: Date
  }>
>(null)
export const $refresh = createStore<
  Nullable<{
    name: 'refresh'
    value: string
    expiresAt: Date
  }>
>(null)
export const $expiresAt = createStore<Nullable<Date>>(null)

export const $isUserAuthenticated = $userProfile.map(Boolean)
//@ts-ignore

sample({
  //@ts-ignore
  clock: loadAuthenticatedUser,
  filter: ({ cookies }) => Boolean(cookies),
  fn: ({ cookies }) => {
    return { cookies }
  },
  target: loadUserProfileFx,
})

sample({
  clock: loadUserFx.doneData,
  target: $userProfile,
})

sample({
  clock: [$access, $refresh],
  filter: (refreshOrAccess) => Boolean(refreshOrAccess),
  fn: (refreshOrAccess) =>
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    Object.assign(refreshOrAccess!, {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      expires: refreshOrAccess!.expiresAt,
    }),
  target: cookiesSet,
})

sample({
  clock: userLoggedOut,
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  target: [$userProfile.reinit!, $access.reinit!, $refresh.reinit!, $expiresAt.reinit!, cookiesClear],
})
