import clsx, { ClassValue } from 'clsx'
import { forwardRef, ReactNode, useState } from 'react'

interface CollapsibleBlockProps {
  className?: ClassValue
  title: ReactNode
  children: ReactNode
  dataType: string
  id: string
}

const ForwarderRefCollapsibleBlock = forwardRef<HTMLDivElement, CollapsibleBlockProps>(
  ({ className, title, children, dataType, id }, ref) => {
    const [collapsed, setCollapsed] = useState(false)
    const handleCollapse = () => setCollapsed((collapsed) => !collapsed)

    return (
      <div
        className={clsx('max-w-screen relative row-auto mt-6 grid grid-cols-8', className)}
        ref={ref}
        datatype={dataType}
      >
        <div id={id} className="absolute -top-12" />
        <h2
          className="relative col-span-full mb-4 border-b pb-2 font-sans text-xl font-semibold md:col-span-4"
          onClick={handleCollapse}
        >
          {title}

          <span
            className={clsx('triangle', {
              rotated: collapsed,
            })}
          ></span>
        </h2>

        <div
          className={clsx('for_pdf col-span-full grid grid-cols-12 lg:gap-1', {
            hidden: collapsed,
          })}
        >
          {children}
        </div>
      </div>
    )
  },
)

ForwarderRefCollapsibleBlock.displayName = 'CollapsibleBlock'

export const CollapsibleBlock = ForwarderRefCollapsibleBlock
