import React, { useState } from 'react'
import Notification from '../notification'

interface CopyToClipboardProps {
  text: string
}

const CopyToClipboard: React.FC<CopyToClipboardProps> = ({ text }) => {
  const [showNotification, setShowNotification] = useState(false)
  const [orangeTest, setOrangeText] = useState(false)

  const copyTextToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(text)
      setShowNotification(true)
      setOrangeText(true)
      setTimeout(() => {
        setShowNotification(false)
        setOrangeText(false)
      }, 2500)
    } catch (err) {
      console.error('Failed to copy text: ', err)
    }
  }

  return (
    <>
      {text ? (
        <span className="relative">
          <h4
            onClick={copyTextToClipboard}
            className="col-span-full mb-0.5 break-words font-sans text-base font-normal"
            style={{ display: 'inline', cursor: 'pointer' }}
          >
            {orangeTest ? <span style={{ color: '#f60' }}>{text}</span> : text}
          </h4>
          {showNotification ? <Notification message={'Текст Скопирован'} color={'green'} /> : ''}
        </span>
      ) : (
        ''
      )}
    </>
  )
}

export default CopyToClipboard
