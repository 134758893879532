import { useUnit } from 'effector-react'

import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

import { extractPlanInfoById } from '@/constants'
import { $userProfile } from '@/entities/user'
import fmt from '@/lib/fmt'
import Icon from '@/shared/ui/icon'
import { cancelSubscriptionFxError, cancelSubscriptionFxPending } from '@shared/api'
import { ShadcnButton } from '@shared/ui'
import { Dialog, DialogClose, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@shared/ui/dialog'
import { $subscriptions } from 'src/screens/subscriptions/model'

interface ModalProps {
  children: string | number | undefined | JSX.Element | JSX.Element[]
  onConfirm: () => void
}

const Modal = ({ children, onConfirm }: ModalProps) => {
  const user = useUnit($userProfile)
  const plans = useUnit($subscriptions)
  const t = useTranslations()
  const [isSubscriptionCancelling, subscriptionCancelError] = useUnit([
    cancelSubscriptionFxPending,
    cancelSubscriptionFxError,
  ])

  const [open, setOpen] = useState(false)

  useEffect(() => {
    if (!isSubscriptionCancelling && !subscriptionCancelError) {
      setOpen(false)
    }
  }, [isSubscriptionCancelling, subscriptionCancelError])

  if (!user || !plans.length) return null

  const date = new Date(user.next_bill_date).toLocaleDateString(
    typeof window !== 'undefined' ? window?.__NEXT_DATA__?.locale || 'en-US' : 'en-US',
  )

  const plan = extractPlanInfoById(user.plan_id)

  if (!plan) return null

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogTitle>
          {t('cancel')} {t('current_plan').toLowerCase()}?
        </DialogTitle>
        <DialogDescription className="space-y-2">
          <h2 className="w-full text-center text-2xl font-bold text-green">{plan.title}</h2>
          <span className="w-full text-center text-xl">
            <span className="font-bold">{fmt.number(plan.price, 'kz')}</span> /{' '}
            {plan.months === 1 ? t('month') : `${plan.months} ${t('months')}`}
          </span>

          <p className="whitespace-pre-line">{t('cancel_plan_description', { date: date })}</p>

          <div className="flex w-full space-x-4">
            <ShadcnButton type="button" className="w-full bg-gray-800 hover:bg-gray-900" onClick={onConfirm}>
              {isSubscriptionCancelling ? <Icon name="loader-circle" className="animate-spin" /> : t('cancel')}
            </ShadcnButton>
            <DialogClose asChild>
              <ShadcnButton type="button" className="w-full bg-blue-500">
                {t('back')}
              </ShadcnButton>
            </DialogClose>
          </div>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

export default Modal
