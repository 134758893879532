// @ts-nocheck
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import React, { useMemo } from 'react'
import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

const VirtualizedTable = dynamic(() => import('@/ui').then((d) => d.VirtualizedTable))
export function RisksList({
  title = '',
  risks,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull'> & {
  title: string
  risks: Record<string, any>[]
}) {
  const t = useTranslations()

  const correctRisks = useMemo(
    () =>
      risks
        ?.map((e: any, idx: any) => {
          return (
            <div className="py-2.5" key={idx}>
              <div className="grid grid-cols-8">
                <div className="col-span-full flex flex-row justify-between text-base font-normal md:col-span-5">
                  <span className="flex flex-col md:block">
                    {e.object}
                    <span className="text-gray-500">&nbsp;·&nbsp;{e.source}</span>
                  </span>
                  <span className="font-medium w-16 text-right">
                    {' '}
                    {/* Добавляем фиксированную ширину и выравнивание */}
                    {e.status != 'not_found' ? (
                      <span className="text-red-600">{t('yes')}</span>
                    ) : (
                      <span className="text-green">{t('no')}</span>
                    )}
                  </span>
                </div>

                <SafeView for={e?.records?.length}>
                  <RiskTable records={e.records} />
                </SafeView>
              </div>
            </div>
          )
        })
        .filter(Boolean),
    [risks, t],
  )

  return (
    <div className="col-span-full my-2 text-base font-normal ">
      <div className="mb-2 flex items-center text-lg font-medium">{t(title)}</div>
      {correctRisks.map((e: any, idx: number) => (
        <React.Fragment key={`risk_lists_${idx}`}>{e}</React.Fragment>
      ))}
    </div>
  )
}

const RiskTable = ({ records }: { records: Record<string, any>[] }) => {
  const keys = records.reduce<{ header: string; accessorKey: string }[]>((prev, cur) => {
    const innerKeys: string[] = cur.records.reduce((cPrev: string[], cCur: { k: string }) => [...cPrev, cCur.k], [])

    const t = innerKeys
      .filter((k) => prev.findIndex((p) => p.header === k) === -1)
      .map((k) => ({ header: k, accessorKey: k }))

    return [...prev, ...t]
  }, [])

  const data = records.reduce<Record<string, string>[]>((prev, cur) => {
    const result: Record<string, string> = cur.records.reduce(
      (cPrev: Record<string, string>, cCur: { k: string; v: string }) => ({ ...cPrev, [cCur.k]: cCur.v }),
      {},
    )
    return [...prev, result]
  }, [])

  if (!keys || !data) return null

  return (
    <div className="col-span-full">
      <VirtualizedTable columns={keys} list={data} collapsible />
    </div>
  )
}
