import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'

import { LangSelection } from '@/widgets'

const ignoreList = ['refund-page', 'service/delete-phone-number']

export const Footer = () => {
  const t = useTranslations()
  const locale = useLocale()

  const footerLinks: Array<[string, string]> = t.raw('footer_links')

  return (
    <footer className="z-20 mt-auto w-full bg-white pt-2 shadow-xl md:pt-0">
      <div className="container flex h-full flex-col">
        <ul className="flex h-full flex-wrap gap-x-4 text-sm sm:text-base md:items-center">
          {(Array.isArray(footerLinks) ? footerLinks : [])
            ?.filter(([link, _]) => !ignoreList.includes(link))
            ?.map(([link, name]) => {
              return (
                <li className="text-primary hover:opacity-75" key={link}>
                  <Link locale={locale} href={`/${link === 'services' ? 'business-check' : link}`} legacyBehavior>
                    <a rel="nofollow">{name}</a>
                  </Link>
                </li>
              )
            })}
        </ul>
        <ul className="my-2 flex w-full flex-col flex-wrap text-sm sm:text-footer md:flex-row">
          <li className="pr-2">{t('small_about')}</li>
          <li className="pr-2">
            {t('for_question')}
            <a href="mail:to" className="text-gray-500" rel="nofollow">
              mail@statsnet.co
            </a>
            .
          </li>
          <li className="flex flex-wrap">
            <span>
              {t('subscribe_tg')}{' '}
              <a className="text-gray-500" href="https://t.me/statsnet_co" target="_blank" rel="nofollow">
                &nbsp; Telegram
              </a>
            </span>
            <span>&nbsp; &middot; &nbsp;</span>
            <LangSelection side="top" />
          </li>
        </ul>
      </div>
    </footer>
  )
}
