import { Store } from 'effector'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import { CollapsibleBlock } from '@/widgets'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'
import { ResponseType } from 'src/shared/api/model'

import { RisksList } from './list'

export function Risks({
  for: $data,
  isFull: $isFull,
  risks: $risks,
  person,
}: SetRequired<ForCompanyOrIndividualProps, 'isFull' | 'person'> & {
  risks: Store<
    | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['risks']
    | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['risks']
  >
}) {
  const t = useTranslations()
  const [data] = useUnit([$data])

  const structuredRisks = useStoreMap({
    store: $risks,
    fn: (risks, [person]) => {
      let all_risks_length = 0
      let active_risk_count = 0
      if (person && Array.isArray(risks)) {
        const personalRisks = risks?.map((risk) => {
          if (risk.status === 'found') {
            active_risk_count += 1
          }
          all_risks_length++

          return risk
        })

        return {
          risks: [
            {
              title: 'company_risk',
              risks: personalRisks,
            },
          ],
          total: all_risks_length,
          active: active_risk_count,
        }
      } else {
        const companyRisks = risks?.company?.map((risk) => {
          if (risk.status === 'found') {
            active_risk_count += 1
          }
          all_risks_length++
          return risk
        })
        const officersRisk = risks?.officers?.map((risk) => {
          if (risk.status === 'found') {
            active_risk_count += 1
          }
          all_risks_length++
          return risk
        })
        const shareHoldersRisk = risks?.shareholders?.map((risk) => {
          if (risk.status === 'found') {
            active_risk_count += 1
          }
          all_risks_length++
          return risk
        })

        return {
          risks: [
            { title: 'company_risk', risks: companyRisks },
            { title: 'officer_risk', risks: officersRisk },
            { title: 'shareholders_risk', risks: shareHoldersRisk },
          ],
          total: all_risks_length,
          active: active_risk_count,
        }
      }
    },
    keys: [person, data?.id],
  })

  return (
    <CollapsibleBlock
      title={
        <>
          {t('risks')}

          <span className="ml-2 text-sm font-normal">
            {structuredRisks.active} {t('from')} {structuredRisks.total}
          </span>
        </>
      }
      dataType="risks"
      id="risks"
      className="select-none"
    >
      <SafeView for={structuredRisks.risks} withProps>
        {(risks) =>
          risks.map((risk: Record<string, any>, idx: number) => (
            <RisksList key={idx} person={person} title={risk.title} risks={risk.risks} isFull={$isFull} for={$data} />
          ))
        }
      </SafeView>
      {data?.structure === 'se' ? (
        <a
          href="https://kgd.gov.kz/ru/services/taxpayer_search_unreliable"
          rel="nofollow"
          target="_blank"
          className="text-light col-span-full mt-4 text-xs text-gray-500 md:col-span-3"
        >
          {t('open_source', {
            name: 'kgd.gov.kz',
          })}
        </a>
      ) : null}
    </CollapsibleBlock>
  )
}
