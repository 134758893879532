import { availablePlansByTitle, PlanIDs } from '@/constants'
import { cn } from '@lib/shadcn'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { $userProfile } from '../model'

const getWidthForRange = (reports: number, planId: PlanIDs) => {
  let width = 0

  if (reports > 1000) {
    width = 0
  } else if (availablePlansByTitle.Premium.includes(planId)) {
    width = (reports / 1000) * 100
  } else if (availablePlansByTitle.Basic.includes(planId)) {
    width = (reports / 20) * 100
  }
  return 100 - width
}

export function OrdersCountProgress() {
  const t = useTranslations()

  const [user] = useUnit([$userProfile])

  if (!user) return

  return (
    <>
      <div className="w-full">
        <div className="h-2 w-full rounded bg-gray-300">
          <div
            className={cn(`h-2 rounded bg-gradient-to-r  transition-all duration-300 `, {
              'from-blue-500 to-blue-300': user?.reports > 1000,
              'from-blue-500 to-blue-500': user?.reports < 1000,
              'from-red-500 to-red-300': user?.reports <= 3,
            })}
            style={{ width: getWidthForRange(user.reports, user.plan_id) + '%' }}
          ></div>
        </div>
      </div>
      <span className="mt-1 block text-gray-500">
        {t.rich('used_orders', {
          count: user.reports > user.plan_reports ? user.reports : user.plan_reports - user.reports,
          max: user.plan_reports,
          important: (chunks) => <b className="font-medium text-black">{chunks}</b>,
        })}
      </span>
    </>
  )
}
