import { list, reflect } from '@effector/reflect'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import { Tabs } from '@/ui'

import FAQItem from './faq-item'
import { $subscriptionDuration, $subscriptions, subscriptionChosen, subscriptionDurationChanged } from './model'
import { SubscriptionForm } from './ui'

const plansTabs: { id: 'month' | 'year'; title: string }[] = [
  { id: 'month', title: 'month_subscribe' },
  { id: 'year', title: 'year_subscribe' },
]

const ReflectedSubscriptionForm = reflect({
  view: SubscriptionForm,
  bind: {
    onSubscriptionSelect: subscriptionChosen,
  },
})

const SubscriptionList = list({
  source: $subscriptions,
  view: ReflectedSubscriptionForm,
  bind: {
    duration: $subscriptionDuration,
  },
  mapItem: {
    subscription: (subscription) => subscription,
  },
})

export const SubscriptionsScreen = () => {
  const [subscriptionDuration, changeSubscriptionDuration] = useUnit([
    $subscriptionDuration,
    subscriptionDurationChanged,
  ])

  const t = useTranslations()

  return (
    <main className="container mx-auto items-center py-4 md:py-20">
      <div className="flex w-full items-center justify-between">
        <h2 className="mb-5 text-center font-stem text-2xl sm:text-4xl">{t('choose_plan')}</h2>
        <Tabs tabs={plansTabs} onTabChange={changeSubscriptionDuration} selectedTab={subscriptionDuration} />
      </div>

      <div className="mt-10 flex w-full flex-wrap items-start justify-center gap-[20px] space-y-4 md:gap-0 md:space-y-0 ">
        <SubscriptionList />
      </div>
      <div className="w-full">
        <h2 className="mb-8 mt-16 px-8 font-stem text-4xl font-bold">{t(`FAQ`)}</h2>

        <ul className="flex flex-col">
          {(t.raw('faq_items') as { faq_name: string; faq_description: string }[]).map(
            (el: { faq_name: string; faq_description: string }, idx: number) => (
              <li key={idx}>
                <FAQItem header={el.faq_name} desc={el.faq_description} />
              </li>
            ),
          )}
        </ul>
      </div>
    </main>
  )
}
