import { createEvent, createStore, sample } from 'effector'

import { loadIndividualFx } from '@/api'
import { ResponseType } from 'src/shared/api/model'

export const $individual = createStore<ResponseType<'/business/individuals', 'get'>>(null as never)
export const $isFull = createStore(false)
export const individualLoaded = createEvent<{
  hash: string
  headers: Record<string, any>
}>()

export const $individualsMeta = createStore<ResponseType<'/business/individuals', 'get'>['meta']>(null as never)
export const $individualRelations = createStore(null)
export const $individualHash = createStore<string | null>(null)
export const $individualRisks = createStore<ResponseType<'/business/individuals', 'get'>['risks']>([])

sample({
  clock: individualLoaded,
  fn: ({ hash }) => hash,
  target: $individualHash,
})

sample({
  //@ts-ignore
  clock: individualLoaded,
  fn: ({ hash, headers }) => ({
    query: {
      identifier: hash,
    },
    headers,
  }),
  target: loadIndividualFx,
})

sample({
  clock: loadIndividualFx.doneData,
  target: $individual,
})

sample({
  clock: loadIndividualFx.doneData,
  fn: ({ is_full }) => is_full || false,
  target: $isFull,
})

sample({
  clock: loadIndividualFx.doneData,
  fn: ({ meta }) => meta,
  target: $individualsMeta,
})
sample({
  clock: loadIndividualFx.doneData,
  fn: ({ risks }) => risks,
  target: $individualRisks,
})
