import { ReactNode } from 'react'

interface TableSectionProps {
  children: ReactNode
  title: ReactNode
}
export function TableSection({ children, title }: TableSectionProps) {
  return (
    <tr className="flex flex-col gap-0 md:flex-row md:gap-2">
      <td>
        <h3 className="col-span-full mb-0.5 text-base leading-normal text-gray-500">{title}</h3>
      </td>

      <td>
        <h4 className="col-span-full mb-0.5 break-words font-sans text-base font-normal">{children}</h4>
      </td>
    </tr>
  )
}
