import type { ReactNode } from 'react'

type RenderChildrenProps<E> = {
  for: E
  withProps: true // Вказує, що children — це функція
  otherwise?: ReactNode | null
  children: (data: NonNullable<E>) => ReactNode // Render-функція
}

type PrimaryChildren<E> = {
  for: E
  withProps?: false // Або withProps відсутній
  otherwise?: ReactNode | null
  children: ReactNode // Звичайний JSX
}

type Props<E> = RenderChildrenProps<E> | PrimaryChildren<E>

export function SafeView<E>({ for: data, children, otherwise = null, withProps = false }: Props<E>) {
  if (!data) return <>{otherwise}</>

  if ((Array.isArray(data) || typeof data === 'string') && data.length === 0) {
    return <>{otherwise}</>
  }

  if (typeof data === 'boolean') {
    return data ? <>{children as ReactNode}</> : <>{otherwise}</>
  }

  if (withProps && typeof children === 'function') {
    return <>{(children as (data: NonNullable<E>) => ReactNode)(data)}</>
  }

  return <>{children as ReactNode}</>
}
