import cn from 'clsx'
import { useTranslations } from 'next-intl'
import React, { CSSProperties, MouseEventHandler, useEffect, useMemo, useRef, useState } from 'react'

import fmt from '@/lib/fmt'
import { MultiRangeSlider } from '@/ui'

import { MiniBarProps } from './types'

export const MiniBar: React.FC<MiniBarProps> = ({
  data,
  totalSum,
  jurisdiction = 'kz',
  type = 'default',
  ref,
  industryType,
  headcountCol,
  additonalStyles,
  emplCount,
  onChange,
  withCurrency = true,
  style,
}) => {
  const [currentBar, setCurrentBar] = useState<{
    sum: string | number
    year: string | number
    minSum?: string | number
    maxSum?: string | number
  }>({
    sum: 0,
    year: 0,
  })
  const [min, setMin] = useState<any>(0)
  const [max, setMax] = useState<any>(0)
  const [initialBar, setInitialBar] = useState<any>()
  const [maxData] = useState(() => {
    const values = Object.values(data)
    return values.length - 1
  })

  const t = useTranslations()

  const parentRef = useRef<HTMLDivElement>(null)

  const getMinMax = (min: number, max: number) => {
    const barArr: any = parentRef.current?.querySelectorAll('.bar')
    setMin(barArr[min])
    setMax(barArr[max])
  }

  useMemo(() => {
    if (data || headcountCol) {
      const keys = Object.keys(data).reverse()
      const initialYear =
        type === 'estimate'
          ? keys.find((el) => el === '2024' && data[el] > 0)
            ? '2024'
            : keys.find((el) => el === '2023' && data[el] > 0)
              ? '2023'
              : keys.find((el) => data[el as any] !== 0) || 0
          : keys.find((el) => data[el as any] !== 0) || 0

      const returnedValue = {
        year: initialYear,
        sum:
          type === 'headcount'
            ? headcountCol?.[initialYear]
            : fmt.number(Math.floor(data[initialYear as number]), jurisdiction as any, withCurrency),
        minSum: 0,
        maxSum: 0,
      }

      if (type === 'salary' && emplCount) {
        const minSum = Math.floor(data[initialYear as number] / emplCount[1])
        const maxSum = Math.floor(data[initialYear as number] / emplCount[0])
        returnedValue.minSum = minSum < 41900 ? 41900 : minSum
        returnedValue.maxSum = maxSum < 41900 ? 41900 : maxSum
      }

      setInitialBar(returnedValue)
    }
  }, [data, emplCount, headcountCol, jurisdiction, type, withCurrency])

  const mouseMoveHandler: MouseEventHandler = (e) => {
    parentRef.current?.querySelectorAll('.bar').forEach((e) => e.classList.remove('active'))
    if (
      //@ts-ignore
      e.target?.classList.contains('bar') &&
      type !== 'taxes' &&
      type !== 'headcount'
    ) {
      //@ts-ignore
      e.target.className += ' active'
      setCurrentBar({
        sum: fmt.number(
          Math.round(
            //@ts-ignore
            e.target?.dataset?.sum,
          ),
          jurisdiction as any,
          withCurrency,
        ),
        //@ts-ignore
        year: e.target?.dataset.year,
        ...(type === 'salary'
          ? {
              //@ts-ignore
              minSum: e.target?.dataset.minSum,
              //@ts-ignore
              maxSum: e.target.dataset.maxSum,
            }
          : {}),
      })
    } else if (
      //@ts-ignore
      e.target?.classList.contains('bar') &&
      type === 'headcount'
    ) {
      //@ts-ignore
      e.target.className += ' active'
      //@ts-ignore
      setCurrentBar({
        //@ts-ignore
        sum: e.target?.dataset.headcount,
        //@ts-ignore
        year: e.target?.dataset.year,
      })
    }
  }

  useEffect(() => {
    if (type === 'taxes') {
      const minData = parseFloat(min?.dataset?.sum)
      const maxData = parseFloat(max?.dataset?.sum)
      parentRef.current?.querySelectorAll('.bar').forEach((e: any) => {
        const val = parseFloat(e.dataset.sum)

        if (val < minData) {
          e.classList += ' active_taxes_min'
        } else {
          e.classList.remove('active_taxes_min')
        }

        if (val > maxData) {
          e.classList += ' active_taxes_max'
        } else {
          e.classList.remove('active_taxes_max')
        }
      })

      if (onChange) {
        onChange(minData, maxData)
      }
    }
  }, [min, max, type, onChange])

  return (
    <div
      className={cn('mt-1 flex items-center justify-start', additonalStyles, {
        'flex-col': type === 'taxes',
      })}
      style={style || {}}
      ref={parentRef}
    >
      <span
        className={cn('child-span-leading', {
          'min-w-50': type === 'salary',
          'min-w-[105px]': type === 'headcount',
          'min-w-[50px]': type === 'court_cases',
          'min-w-[150px]': !['salary', 'headcount', 'court_cases'].includes(type),
        })}
      >
        {!(type === 'salary') ? (
          type === 'rialto' ? (
            currentBar?.sum.toString().split('₸')[0] === '0' ? (
              <>{initialBar?.sum.toString().split('₸')[0]}</>
            ) : (
              <>{currentBar?.sum.toString().split('₸')[0]}</>
            )
          ) : type === 'taxes' ? (
            <></>
          ) : type === 'headcount' ? (
            currentBar?.sum.toString().split('₸')[0] === '0' ? (
              initialBar?.sum + t(`people`)
            ) : (
              currentBar?.sum + t(`people`)
            )
          ) : type === 'applications' ? (
            currentBar?.sum.toString().split('₸')[0] === '0' ? (
              initialBar?.sum + t(`people`)
            ) : (
              currentBar?.sum + t(`people`)
            )
          ) : currentBar?.sum?.toString().split('₸')[0] === '0' ? (
            initialBar?.sum
          ) : (
            currentBar?.sum
          )
        ) : !currentBar.minSum || !currentBar.maxSum || (currentBar.minSum === 0 && currentBar.maxSum === 0) ? (
          `${fmt.number(initialBar?.minSum, jurisdiction as any)} - ${fmt.number(
            initialBar?.maxSum,
            jurisdiction as any,
          )}`
        ) : (
          `${fmt.number(currentBar.minSum, jurisdiction as any)} - ${fmt.number(
            currentBar.maxSum,
            jurisdiction as any,
          )}`
        )}
      </span>

      <div
        style={
          {
            '--count': `${Object.values(data) && Object.values(data).length}`,
          } as CSSProperties
        }
        className={cn('relative', {
          barWrapper: type !== 'taxes',
          barWrapper_taxes: type === 'taxes',
        })}
        onMouseMove={type !== 'taxes' ? mouseMoveHandler : () => ''}
      >
        {data &&
          Object.entries(data).map(([year, yearTotalSum]: [string, number], idx) => {
            const yearTotalMin =
              type === 'salary' && emplCount ? Math.floor(((yearTotalSum / emplCount[1]) * 10) / 12) : 0

            const yearTotalMax =
              type === 'salary' && emplCount ? Math.floor(((yearTotalSum / emplCount[0]) * 10) / 12) : 0

            let percent = 0

            const barHeightMultiplier = 2

            if (type === 'estimate' || type === 'court_cases') {
              percent = (yearTotalSum / totalSum) * 100 * barHeightMultiplier
            } else if (type === 'salary') {
              percent = (yearTotalMax * 100) / totalSum
            } else {
              percent = (yearTotalSum * 100) / totalSum
            }

            const salaryAttributes = type === 'salary' && {
              'data-min-sum': yearTotalMin <= 41900 ? 41900 : yearTotalMin,
              'data-max-sum': yearTotalMax <= 41900 ? 41900 : yearTotalMax,
            }

            const headcountAttributes =
              type === 'headcount' && Object.entries(headcountCol as any).find((e: any) => e[0] === year)

            const dataAttributes = {
              'data-sum': parseInt(yearTotalSum.toString().replace('-', '')),
              ...{
                'data-headcount': headcountAttributes && headcountAttributes[1],
              },
              ...(salaryAttributes || {}),
            }

            return (
              <div
                key={idx}
                ref={ref}
                className={cn(
                  'relative',
                  {
                    'bg-[#99cafe]': type !== 'taxes',
                    'bg-[#b0b0b0]': type === 'taxes',
                    active: idx === Object.entries(data).length - 1,
                  },
                  'bar',
                )}
                style={{
                  gridRowStart:
                    percent < 0
                      ? 100 + Math.floor(percent)
                      : Math.floor(percent) === 100
                        ? 1
                        : 100 - Math.floor(percent),
                }}
                data-value={yearTotalSum}
                data-year={year}
                {...dataAttributes}
              />
            )
          })}
      </div>

      {type !== 'taxes' ? (
        <span className="ml-2">
          {
            //@ts-ignore
            parseInt(currentBar.year) === 0 ? initialBar?.year : currentBar?.year
          }
        </span>
      ) : (
        <span
          style={{
            whiteSpace: 'pre',
          }}
          className={`ml-2 text-sm`}
        />
      )}

      {industryType && <span className="ml-2 text-gray-500"> · {industryType}</span>}
      {type === 'taxes' ? (
        <MultiRangeSlider min={0} max={maxData} onChange={({ min, max }: any) => getMinMax(min, max)} />
      ) : null}
    </div>
  )
}
