import { ShadcnButton } from '@/shared/ui'
import Icon from '@/shared/ui/icon'
import { Input } from '@/shared/ui/input'
import { InputOTP, InputOTPGroup, InputOTPSeparator, InputOTPSlot } from '@/shared/ui/input-otp'
import phoneCodes from '@json/phones.json' assert { type: 'json' }
import { Alert, AlertDescription, AlertTitle } from '@shared/ui/alert'
import { Label } from '@shared/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger } from '@shared/ui/select'
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import { useUnit } from 'effector-react'
import { REGEXP_ONLY_DIGITS } from 'input-otp'
import { useTranslations } from 'next-intl'
import { useSearchParams } from 'next/navigation'
import { useRouter } from 'next/router'
import { useState, type FormEvent } from 'react'
import {
  $deleteError,
  $phoneNumber,
  $verifyError,
  deletePhoneNumberFx,
  sendDeleteRequestWithCode,
  verifyNumber,
  verifyPhoneNumberFx,
} from './model'

const deletePhoneNumberStepQueryParam = 'delete-phone-step'

const defaultCode = {
  name: 'Kazakhstan',
  dial_code: '+77',
  emoji: '🇰🇿',
  code: 'KZ',
}

const otpCodeMinLength = 6

export const DeletePhoneNumberScreen = () => {
  const searchParams = useSearchParams()
  const [phoneNumber] = useUnit([$phoneNumber])

  const step = searchParams.get(deletePhoneNumberStepQueryParam)

  return step === 'code' && phoneNumber ? <SMSOTPScreen /> : <VerifyTokenScreen />
}

const VerifyTokenScreen = () => {
  const t = useTranslations()

  const router = useRouter()

  const [verifyPhoneNumber, isVerifyPhoneNumberLoading, verifyError] = useUnit([
    verifyNumber,
    verifyPhoneNumberFx.pending,
    $verifyError,
  ])
  const [phoneCode, setPhoneCode] = useState(defaultCode)
  const [phoneNumber, setPhoneNumber] = useState('')

  $phoneNumber.on(verifyPhoneNumberFx.doneData, () => {
    ;(async () => {
      await router.push(
        { pathname: router.pathname, query: { ...router.query, [deletePhoneNumberStepQueryParam]: 'code' } },
        undefined,
        {
          shallow: true,
        },
      )
    })()
  })

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    verifyPhoneNumber(`${phoneCode.dial_code}${phoneNumber}`)
  }

  const sortedCodes = [
    phoneCodes.find((item) => item.code === phoneCode.code) || defaultCode,
    ...phoneCodes.filter((item) => item.code !== phoneCode.code),
  ]

  const currentCode = phoneCodes.find((item) => item.code === phoneCode.code)

  return (
    <main className="container mx-auto flex flex-col items-center align-middle">
      <form onSubmit={onSubmit} className="w-full space-y-1 md:w-1/2">
        {verifyError && (
          <Alert variant="destructive" className="w-full">
            <AlertTitle className="flex flex-row items-center">
              <Icon name="x" size={14} />
              <span className="align-middle">{t(`errors.${verifyError}`)}</span>
            </AlertTitle>
          </Alert>
        )}
        <Label htmlFor="mcl/lf/x/lxflkxlxfdl/kdlf/x/lf" className="flex flex-col text-gray-700">
          {t('phone')}
        </Label>
        <div className="flex flex-row">
          <Select onValueChange={(code) => setPhoneCode(phoneCodes.find((item) => item.code === code) || defaultCode)}>
            <SelectTrigger className="w-fit rounded-r-none border-r-0">
              {currentCode?.emoji} {currentCode?.dial_code}
            </SelectTrigger>
            <SelectContent>
              {sortedCodes.map((item) => (
                <SelectItem key={`${item.code}${item.dial_code}`} value={item.code}>
                  {item.emoji} {item.dial_code}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <Input
            className="rounded-l-none border-l-0 invalid:border-red-600"
            id="mcl/lf/x/lxflkxlxfdl/kdlf/x/lf"
            type="tel"
            placeholder="0000000000"
            pattern="[0-9]{6,16}"
            required
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </div>
        <ShadcnButton className="w-full disabled:bg-gray-700" disabled={isVerifyPhoneNumberLoading}>
          {isVerifyPhoneNumberLoading ? <Icon name="loader-circle" className="animate-spin" /> : t('send')}
        </ShadcnButton>
      </form>
    </main>
  )
}

const SMSOTPScreen = () => {
  const router = useRouter()

  const t = useTranslations()
  const [phoneNumber, sendDeleteRequest, isDeleting, deleteError] = useUnit([
    $phoneNumber,
    sendDeleteRequestWithCode,
    deletePhoneNumberFx.pending,
    $deleteError,
  ])

  const [otpCode, setOTPCode] = useState('')

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (otpCode.length !== otpCodeMinLength) return

    sendDeleteRequest(otpCode)
  }

  const onBackClick = async () => {
    await router.push(
      { pathname: router.pathname, query: { ...router.query, [deletePhoneNumberStepQueryParam]: 'number' } },
      undefined,
      {
        shallow: true,
      },
    )
  }

  return (
    <main className="container mx-auto flex flex-col items-center align-middle">
      <form onSubmit={onSubmit} className="w-full space-y-1 md:w-1/2">
        <div className="mb-4 flex flex-col items-center space-y-2">
          {deleteError && (
            <Alert variant="destructive" className="w-full">
              <AlertTitle className="flex flex-row items-center">
                <Icon name="x" size={16} />
                <span>{t(`errors.${deleteError}`)}</span>
              </AlertTitle>
            </Alert>
          )}
          <Alert className="w-full">
            <AlertTitle>
              <Icon name="mail" />
            </AlertTitle>
            <AlertDescription>
              {t.rich('sms_was_sent_at_phone_number', { phone: phoneNumber, b: (chunks) => <b>{chunks}</b> })}
            </AlertDescription>
          </Alert>
          <InputOTP
            required
            pushPasswordManagerStrategy="increase-width"
            maxLength={otpCodeMinLength}
            value={otpCode}
            onChange={(value) => setOTPCode(value)}
            pattern={REGEXP_ONLY_DIGITS}
          >
            <InputOTPGroup>
              <InputOTPSlot className="h-12 w-12 text-lg" index={0} />
              <InputOTPSlot className="h-12 w-12 text-lg" index={1} />
              <InputOTPSlot className="h-12 w-12 text-lg" index={2} />
              <InputOTPSeparator />
              <InputOTPSlot className="h-12 w-12 text-lg" index={3} />
              <InputOTPSlot className="h-12 w-12 text-lg" index={4} />
              <InputOTPSlot className="h-12 w-12 text-lg" index={5} />
            </InputOTPGroup>
          </InputOTP>
          <div className="text-center">{t('write_code')}</div>
        </div>

        <div className="flex flex-col space-y-2 md:flex-row md:space-x-2 md:space-y-0">
          <Tooltip>
            <TooltipTrigger asChild>
              <ShadcnButton disabled={isDeleting} className="w-full bg-gray-700 hover:bg-gray-800 md:w-5/6">
                {isDeleting ? (
                  <Icon name="loader-circle" className="animate-spin" size={16} />
                ) : (
                  t('delete_number_from_statsnet')
                )}
              </ShadcnButton>
            </TooltipTrigger>
            {otpCode.length !== otpCodeMinLength && (
              <TooltipContent sideOffset={8} className="text-md">
                {t('write_code')}
              </TooltipContent>
            )}
          </Tooltip>
          <ShadcnButton type="button" className="w-full md:w-1/6" onClick={onBackClick}>
            {t('back')}
          </ShadcnButton>
        </div>
      </form>
    </main>
  )
}
