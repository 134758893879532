import { LottiePlayer } from 'lottie-web'
import { useEffect, useRef, useState } from 'react'

export const AnimationOnIndex = () => {
  const ref = useRef<HTMLDivElement>(null)
  const [lottie, setLottie] = useState<LottiePlayer | null>(null)

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default))
  }, [])

  useEffect(() => {
    let animation: any = {
      destroy: () => {},
    }
    if (lottie && ref.current) {
      animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        // path to your animation file, place it inside public folder
        path: '/lottie.json',
      })
    }

    return () => animation.destroy()
  }, [lottie])

  return <div ref={ref} className="animation"></div>
}

export const AnimationOnLast = ({ className }: { className?: string }) => {
  const ref = useRef<HTMLDivElement>(null)
  const [lottie, setLottie] = useState<LottiePlayer | null>(null)

  useEffect(() => {
    import('lottie-web').then((Lottie) => setLottie(Lottie.default))
  }, [])

  useEffect(() => {
    let animation: any = {
      destroy: () => {},
    }
    if (lottie && ref.current) {
      animation = lottie.loadAnimation({
        container: ref.current,
        renderer: 'svg',
        loop: true,
        autoplay: true,
        // path to your animation file, place it inside public folder
        path: '/lottieforlast.json',
      })
    }

    return () => animation.destroy()
  }, [lottie])

  return <div ref={ref} className={className}></div>
}
