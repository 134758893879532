// @ts-nocheck
import { Cell } from '@tanstack/table-core'
import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import dynamic from 'next/dynamic'
import { useEffect, useState } from 'react'
import { useInView } from 'react-intersection-observer'

import { SetRequired } from 'type-fest'

import { SafeView } from '@/features'
import { Placeholder, TableFilterItem, TableFilters } from '@/ui'
import { CollapsibleBlock } from '@/widgets'
import { DataSection } from 'src/screens/company/ui/data-section'
import { MiniBar } from 'src/screens/company/ui/mini-bar'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import { countOfCategorySet } from '@/screens'
import { CompanyItemLoading } from '../../../../shared/ui/companies/company-item-loading'
import { renderTableData } from './common'
import { $courtCases, $courtCasesBar, visibilityChanged } from './model'

const VirtualizedTable = dynamic(() => import('@/ui').then((c) => c.VirtualizedTable), {
  ssr: false,
})

const withRenderValue = ['case_number', 'plaintiff', 'defendant', 'sides']

export function CourtCases({ for: $data, isFull: $isFull }: SetRequired<ForCompanyOrIndividualProps, 'isFull'>) {
  const t = useTranslations()
  const [onVisible, data, courtCasesBar, courtCases, isFull] = useUnit([
    visibilityChanged,
    $data,
    $courtCasesBar,
    $courtCases,
    $isFull,
  ])
  const setCountOfCategory = useUnit(countOfCategorySet)
  const [filterValue, setFilter] = useState<string | null>(null)

  const { ref } = useInView({
    threshold: 0,
    triggerOnce: true,
    onChange: (visible) =>
      onVisible({
        visible,
        id: data?.id,
        shouldBeLoaded: isFull,
      }),
  })

  const cells = t.raw('court_cases_head').map((cell: Record<string, any>) =>
    withRenderValue.includes(cell.accessorKey)
      ? {
          ...cell,
          cell: (c: Cell<any, any>) => c.renderValue(),
        }
      : cell,
  )

  const [totalCount, filters] = useStoreMap({
    store: $courtCases,
    keys: [data?.id],
    fn: (data) => {
      let all_total: any = 0
      const typeMeta = data?.case_type_meta || []
      const meta = data?.meta || []
      if (data?.meta && data?.case_type_meta) {
        ;[...data.meta, ...data.case_type_meta].forEach(({ total, type }: any) => {
          if (type === 'all' || type === 'sides') {
            return
          }
          all_total += total
        })
      }

      return [
        all_total,
        [...meta, ...typeMeta].filter((e: Record<string, any>) => {
          return !(e?.type === '' || e?.type === 'sides' || e?.type === 'all')
        }),
      ]
    },
  })

  const translatedValue = {
    ...t.raw('court_cases_filter'),
    ...t.raw('court_cases_type_filter'),
  }

  const list = renderTableData(courtCases?.court_cases, t, filterValue)

  useEffect(() => {
    setCountOfCategory({
      category: 'courts',
      count: totalCount,
    })
  }, [totalCount, setCountOfCategory])

  return (
    <>
      <CollapsibleBlock
        title={
          <>
            {t('court_cases')}
            {totalCount !== 0 && <span className="ml-2 text-sm font-normal">{totalCount}</span>}
          </>
        }
        dataType="court_cases"
        id="courts"
        ref={ref}
      >
        {courtCases.court_cases?.length ? (
          <>
            <SafeView for={isFull && list.length === 0 && !courtCasesBar.totalSum}>
              <span className="col-span-full">{t('not_found')}</span>
            </SafeView>
            <SafeView for={isFull && courtCasesBar?.totalSum}>
              <DataSection title={t('court_cases_minibar')}>
                {courtCasesBar.totalSum ? (
                  //@ts-ignore
                  <MiniBar
                    type="court_cases"
                    jurisdiction={data?.jurisdiction as any}
                    data={courtCasesBar.yearsSum as Record<number, number>}
                    totalSum={courtCasesBar.totalSum}
                    withCurrency={false}
                    isVatPayer
                  />
                ) : (
                  t(`not_found`)
                )}
              </DataSection>
            </SafeView>

            <SafeView for={!isFull}>
              <div className="col-span-full mb-2 flex gap-x-4">
                <Placeholder withGeneration />
                <Placeholder withGeneration />
              </div>
            </SafeView>

            <div className="col-span-full">
              <SafeView for={isFull && filters?.length > 0}>
                <TableFilters applyFilter={setFilter} isFull={isFull}>
                  {({ isFull, applyFilter, ...props }) => (
                    <>
                      <TableFilterItem
                        {...props}
                        className={clsx({
                          'font-bold': !filterValue || filterValue === '-1',
                        })}
                        onClick={() => applyFilter('-1')}
                      >
                        {totalCount} {t('all')}
                      </TableFilterItem>

                      {filters.map((f) => (
                        <TableFilterItem
                          {...props}
                          className={clsx({
                            'font-bold': f?.type === filterValue,
                          })}
                          onClick={() => applyFilter(f?.type || null)}
                          key={f?.type}
                        >
                          {f?.total} {translatedValue[f.type as string] || '-'}
                        </TableFilterItem>
                      ))}
                    </>
                  )}
                </TableFilters>
              </SafeView>

              <VirtualizedTable list={list} columns={cells} mocked={false} />
            </div>

            {data?.structure === 'se' ? (
              <a
                href="https://office.sud.kz/courtActs/index.xhtml"
                rel="nofollow"
                target="_blank"
                className="text-light col-span-full mt-4 text-xs text-gray-500 md:col-span-3"
              >
                {t('open_source', {
                  name: 'office.sud.kz',
                })}
              </a>
            ) : null}
          </>
        ) : (
          <div className="col-span-full">
            {isFull ? <CompanyItemLoading /> : <VirtualizedTable list={[]} columns={cells} mocked />}
          </div>
        )}
      </CollapsibleBlock>
    </>
  )
}
