import { useUnit } from 'effector-react'
import { GetServerSidePropsContext } from 'next'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useId } from 'react'
import Highlighter from 'react-highlight-words'

import { apiTypes } from '@/api'
import { ROUTES } from '@/constants'
import { SafeView } from '@/features'
import { CompanyStatus } from '@/ui'
import { suggestingReset } from 'src/widgets/search-snippets/model'

interface SuggestedListProps {
  data: apiTypes.SuggestCompaniesOut['company']
  search: string
}
export const SuggestedList = ({ data, search = '' }: SuggestedListProps) => {
  const t = useTranslations()
  const locale = useLocale()

  const companyId = useId()
  const [resetSuggested] = useUnit([suggestingReset])
  if (!data) {
    return null
  }

  return (
    <ul className="shadow">
      {data.length > 0 ? (
        data.map((company) => (
          <li
            className="flex w-full cursor-pointer items-center gap-1 px-2 py-3 text-primary hover:bg-gray-100"
            key={company.id || companyId}
          >
            <Link
              locale={locale}
              href={`${ROUTES.COMPANY}/${company.jurisdiction}/${company.id}`}
              onClick={resetSuggested}
              className="flex items-center gap-x-1"
            >
              <CompanyStatus inActive={company.inactive} />
              <p>
                <Highlighter
                  highlightClassName="bg-transparent text-primary font-bold"
                  searchWords={search.split(' ')}
                  autoEscape={true}
                  textToHighlight={company.title}
                />
                <SafeView for={company.addresses?.[0]?.state} otherwise={null} withProps>
                  {(value) => (
                    <>
                      {' · '}
                      <span className="text-gray-500">{value}</span>
                    </>
                  )}
                </SafeView>
                <SafeView for={company.identifiers} withProps otherwise={null}>
                  {(identifiers) =>
                    identifiers.map((identifier, id) => {
                      let translatedType = ''
                      if (identifier.type === 'ИНН') {
                        translatedType = t(`identifier.kz.individual`)
                      } else if (identifier.type === 'БИН') {
                        translatedType = t(`identifier.kz.legal`)
                      } else {
                        translatedType = identifier.type
                      }

                      return (
                        <span className="text-gray-500" key={`${identifier.type}_${id}`}>
                          {' · '}
                          {translatedType} {identifier.value}
                        </span>
                      )
                    })
                  }
                </SafeView>
              </p>
            </Link>
          </li>
        ))
      ) : (
        <p></p>
      )}
    </ul>
  )
}

export const getStaticProps = async (ctx: GetServerSidePropsContext) => {
  const locale = ctx.locale

  const identifier = (await import(`../../../../messages${locale}/common.json`)).default

  return {
    props: {
      identifier,
    },
  }
}
