import { useUnit } from 'effector-react'
import { useRouter } from 'next/router'
import { createContext, Dispatch, SetStateAction, useCallback, useContext } from 'react'

import { $userProfile } from '@/entities/user'

type NumberOrString = number | string

export const PlanModalWindowContext = createContext<{
  planModalWindow: boolean
  setPlanModalWindow: Dispatch<SetStateAction<boolean>>
}>({
  planModalWindow: false,
  setPlanModalWindow: () => {},
})

export function generatePlanRedirectQuery(title: string, id: NumberOrString, months: number) {
  return `checkout?type=plan&plan=${title}&plan_id=${id}&month=${months}`
}

export const usePlan = (url = '/auth?type=signup', withRedirectToPlanAfterSignUp = true) => {
  const { planModalWindow, setPlanModalWindow } = useContext(PlanModalWindowContext)
  const user = useUnit($userProfile)
  const router = useRouter()

  const onPlanSubmit = useCallback(
    (id: NumberOrString, title: string, months: number) => {
      if (!user) {
        if (withRedirectToPlanAfterSignUp) {
          return router.push(`${url}&redirect=${router.asPath}`)
        }

        return router.push(url)
      }

      router.push(`/${generatePlanRedirectQuery(title, id, months)}`)
    },
    [router, url, user, withRedirectToPlanAfterSignUp],
  )

  const setBodyOverflow = useCallback((overflow: string) => {
    document.body.style.overflow = overflow
  }, [])

  const openPlanModalWindow: any = useCallback(() => setPlanModalWindow(true), [setPlanModalWindow])

  const closePlanModalWindow = useCallback(() => setPlanModalWindow(false), [setPlanModalWindow])

  return {
    onPlanSubmit,
    planModalWindow,
    openPlanModalWindow,
    closePlanModalWindow,
    setBodyOverflow,
  }
}
