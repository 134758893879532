import { createEvent, createStore, sample } from 'effector'

import { forgetPasswordFx } from '@/api'

export const $email = createStore<string>('')
export const $showSuccessMessage = createStore(false)
export const emailChanged = createEvent<string>()
export const forgetPasswordSent = createEvent()

sample({
  clock: emailChanged,
  target: $email,
})

sample({
  clock: forgetPasswordSent,
  source: $email,
  fn: (email) => ({ data: { email } }),
  target: forgetPasswordFx,
})

sample({
  clock: forgetPasswordFx.doneData,
  fn: () => true,
  target: $showSuccessMessage,
})
