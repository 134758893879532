/* eslint-disable */
import { Nullable } from '@/T'
import _ from 'lodash'

const emailRegEx =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const startDateRegEx = /^(0001).*$/

const phoneRegEx = /^((8|\+[0-79])[\- ]?)?(\(?\d{3}\)?[\- ]?)?[\d\- ]{7,10}$/

const passwordRegEx = /^([^\s])*$/
/* eslint-enable */

function isEmail(s?: string) {
  if (!s) return false
  return emailRegEx.test(s)
}

function isPhone(s?: string) {
  if (!s) return false
  return phoneRegEx.test(s)
}

function isCorrectPasswordLength(s?: string) {
  if (!s) return false
  return s.length > 4
}

function isValidPassword(s?: string) {
  if (!s) return false
  return passwordRegEx.test(s)
}

function isCapitalize(s?: string) {
  if (!s) return false

  return s.charAt(0) === s.charAt(0).toUpperCase()
}

function isCorrectName(s?: string) {
  if (!s) return false
  return isCapitalize(s) && s.length >= 3
}

function isNotNull<T>(v?: Nullable<T>): v is NonNullable<T> {
  return v !== null
}

function isNumeric(v: string): boolean {
  const filterChars = ['+', '(', ')', '-', ' ']
  const phoneTypes = ['996', '77', '87', '998', '']

  const rightnum = _.chain(v)
    .thru(decodeURI)
    .split('')
    .reject((char) => _.includes(filterChars, char))
    .join('')
    .value()

  const isPhone = _.includes([9, 10, 11], _.size(rightnum))
  const isPhoneType = _.chain(phoneTypes)
    .intersection([rightnum.slice(0, 3), rightnum.slice(0, 2)])
    .isEmpty()
    .thru((value) => !value)
    .value()

  if (isPhone && isPhoneType) {
    return _.isInteger(_.toNumber(rightnum))
  }
  return false
}

const tg = {
  isEmail,
  isPhone,
  isCorrectName,
  isCorrectPasswordLength,
  isValidPassword,
  isCapitalize,
  isNotNull,
  isNumeric,
}

export default tg
