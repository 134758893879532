import { createEvent, createStore, sample } from 'effector'
import { spread } from 'patronum'

import { loadCompanyBetaInformationFx, loadCompanyFullInformationFx } from '@/api'
import { $userProfile } from '@/entities/user'
import { CounterCategory } from 'src/screens/company/types'
import { $govContractsYears } from 'src/screens/company/ui/gov-contracts/model'
import { RequestPath, ResponseType } from 'src/shared/api/model'

export const $company = createStore<
  | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['company']
  | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['company']
>({})

export const $companyContacts = createStore<ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['contacts']>({})

export const $companyPageSeo = createStore<
  | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['seo']
  | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['seo']
>({})

export const $isFullReport = createStore(false)
export const $companyAlternatives = createStore<
  | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['alternatives']
  | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['alternatives']
>([])

export const $companyRisks = createStore<
  | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['risks']
  | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['risks']
  // @ts-ignore
>([])

export const $companyRelations = createStore<
  | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['relations']
  | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['relations']
>([])

export const $companyMeta = createStore<ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['meta']>({})

export const $counters = createStore<Partial<Record<CounterCategory, number>>>({})

export const $correctIndustry = $company.map((company) => {
  const correctIdentifiers: Required<
    ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>
  >['company']['identifiers'] = []
  if (company?.identifiers) {
    company.identifiers.forEach((identity) => {
      if (identity?.type) {
        if (['iin', 'иин'].includes(identity?.type?.toLowerCase())) return
        if (['iin', 'иин'].includes(identity?.type?.toLowerCase())) {
          correctIdentifiers.unshift(identity)
          return
        }

        correctIdentifiers.push(identity)
      }
    })
  }
  return correctIdentifiers
})

export const loadCompanyInfo = createEvent<{
  path:
    | RequestPath<'/business/{jurisdiction}/{id}/paid', 'get'>
    | RequestPath<'/business/{jurisdiction}/{id}/view', 'get'>
  headers: Record<string, any>
}>()

export const countOfCategorySet = createEvent<{
  category: CounterCategory
  count: number
}>()

sample({
  clock: loadCompanyInfo,
  source: $userProfile,
  filter: (user) => !!user && user.plan_id !== 0,
  fn: (_, { path, headers }) => ({
    path,
    headers: headers,
  }),
  target: loadCompanyFullInformationFx,
})

sample({
  clock: loadCompanyInfo,
  source: $userProfile,
  filter: (user) => !user || user.plan_id === 0,
  fn: (_, { path, headers }) => ({
    path,
    headers: headers,
  }),
  target: loadCompanyBetaInformationFx,
})

spread({
  source: loadCompanyBetaInformationFx.doneData,
  targets: {
    company: $company,
    seo: $companyPageSeo,
    risks: $companyRisks,
    alternatives: $companyAlternatives,
    relations: $companyRelations,
    meta: $companyMeta,
  },
})

// @ts-ignore
spread({
  source: loadCompanyFullInformationFx.doneData,
  targets: {
    company: $company,
    seo: $companyPageSeo,
    risks: $companyRisks,
    alternatives: $companyAlternatives,
    relations: $companyRelations,
    contacts: $companyContacts,
    meta: $companyMeta,
  },
})

sample({
  clock: [loadCompanyBetaInformationFx.doneData, loadCompanyFullInformationFx.doneData],
  fn: ({ is_full }) => is_full ?? false,
  target: $isFullReport,
})

loadCompanyFullInformationFx.fail.watch((fail) =>
  console.error(` ${fail.error.request?.method} ${fail.error.request?.path} ${fail.error.response?.status}`),
)
loadCompanyBetaInformationFx.fail.watch((fail) =>
  console.error(` ${fail.error.request?.method} ${fail.error.request?.path} ${fail.error.response?.status}`),
)

sample({
  clock: countOfCategorySet,
  source: $counters,
  fn: (counters, { category, count }) => ({
    ...counters,
    [category]: count,
  }),
  target: $counters,
})

sample({
  clock: $govContractsYears,
  fn: (contracts) => ({
    category: 'government_contracts' as const,
    count: contracts.totalCount,
  }),
  target: countOfCategorySet,
})
