import _ from 'lodash'

const renderSide = (sides: any) =>
  sides?.length
    ? sides.map((el: any, idx: number) => (
        <div key={idx} className="bold_b_golos" dangerouslySetInnerHTML={{ __html: el }} />
      ))
    : '-'

export const renderTableData = (courtCases: any, t: any, filterType: string | null) =>
  _.chain(courtCases)
    .filter((el: any) => {
      if (!filterType) return true

      return el[filterType]?.length > 0 || el.case_type === filterType
    })
    .map((e: any) =>
      e.case_type !== 'entry'
        ? {
            case_number: (
              <>
                <h3>{e.case_number || '-'}</h3>
                <h3>{e.case_type ? t.raw('court_cases_type_filter')[e.case_type] : '-'}</h3>
                <h3>{e.court || '-'}</h3>
              </>
            ),
            plaintiff: renderSide(e.plaintiffs),
            defendant: renderSide(e.defendants),
            sides: renderSide(e.sides),
            result: e.result || '-',
            start_date: e.start_date || '-',
          }
        : null,
    )
    .value()
