import { useUnit } from 'effector-react'

import { Client } from '@/constants'
import fmt from '@/lib/fmt'
import { $company, $companyContacts } from '@/screens'

export function StructuredData() {
  const company = useUnit($company)
  const contacts = useUnit($companyContacts)
  return (
    <script
      type={'application/ld+json'}
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Organization',
          ...(company?.legal_form !== 'ИП' && {
            taxID: company?.identifier,
          }),
          ...(company?.legal_form !== 'ИП' &&
            company?.identifiers?.find((item) => ['vat', 'НДС'].includes(item.type as string)) && {
              vatID: company?.identifiers?.find((item) => ['vat', 'НДС'].includes(item.type as string))?.value,
            }),
          ...(company?.dissolution_date && {
            dissolutionDate: company?.dissolution_date && company?.dissolution_date,
          }),
          ...(company?.incorporation_date && {
            foundingDate: company?.incorporation_date,
          }),
          ...(company?.headcount &&
            company?.headcount.length > 0 && {
              numberOfEmployees: {
                '@type': 'QuantitativeValue',
                value: company?.headcount?.at(-1)?.count?.match(/\d+/)?.at(-1),
              },
            }),
          ...(company?.addresses &&
            company?.addresses.length > 0 && {
              address: fmt.addressCropper(company?.addresses[0]),
            }),
          ...(company?.branches &&
            company?.branches.length > 0 && {
              subOrganization: company?.branches?.map((branch) => ({
                '@type': 'Organization',
                name: branch.name,
                url: `${Client.host}/companies/${company?.jurisdiction}/${branch.id}`,
              })),
            }),
          ...(company?.managing_company_id && {
            parentOrganization: {
              '@type': 'Organization',
              name: company?.managing_company_name,
              url: `${Client.host}/companies/${company?.jurisdiction}/${company?.managing_company_id}`,
            },
          }),
          name: company?.title || company?.name,
          legalName: company?.name,
          alternateName: company?.names?.map((name) => name.name),
          location: company?.jurisdiction,
          identifier: company?.id,
          url: `${Client.host}/companies/${company?.jurisdiction}/${company?.id}`,
          knowsLanguage: ['ru', 'kz'],
          sameAs: contacts?.website?.map((v) => v.value).join(','),
          telephone: contacts?.phone?.map((v) => v.value).join(','),
          email: contacts?.email?.map((v) => v.value).join(','),
          employee: company?.officers
            ?.filter((officer) => !officer.inactive)
            ?.map((officer) => ({
              '@type': 'Person',
              name: officer.name,
              jobTitle: (officer.role || '')
                .split('_')
                .map((v: string) => v.charAt(0).toUpperCase() + v.slice(1))
                .join(' '),
              email: officer.contacts?.filter((v: any) => v.type === 'email').at(-1)?.value,
              telephone: officer.contacts?.filter((v: any) => v.type === 'phone').at(-1)?.value,
            })),
          makesOffer: [
            {
              '@type': 'Offer',
              name: company?.industry,
            },
            // eslint-disable-next-line no-unsafe-optional-chaining
            ...(company?.industry_codes || [])?.map((item) => ({
              '@type': 'Offer',
              name: item.description,
            })),
          ],
        }),
      }}
    />
  )
}
