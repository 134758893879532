import { useTranslations } from 'next-intl'
import { useEffect, useRef, useState } from 'react'

import fmt from '@/lib/fmt'

import { KZMap, RUMap } from './ui'
interface InteractiveMapProps {
  country: 'ru' | 'kz'
}

const classNames = {
  tooltip: `
    absolute
    p-3
    rounded
    flex-col
    flex-nowrap
    bg-white
    shadow-lg
  `,
}

const availableMaps = ['ru', 'kz']
/*eslint-disable*/

const getInteractiveElements = (svg: SVGSVGElement, country: string): NodeListOf<SVGElement> | undefined => {
  switch (country) {
    case 'ru':
      return svg.querySelectorAll('#g5 > * > *')

    case 'kz':
      return svg.querySelectorAll('[name]')

    default:
      return undefined
  }
}
/*eslint-enable*/

export function InteractiveMap({ country }: InteractiveMapProps) {
  // eslint-disable-next-line @typescript-eslint/no-require-imports
  const mapData = availableMaps.includes(country) ? require(`json/map-data/${country}.json`) : null

  const svgRef = useRef<SVGSVGElement>(null)
  const [hoveredCountry, setHoveredCountry] = useState('')

  const [isTooltipVisible, setIsTooltipVisible] = useState(false)
  const [tooltipCoordinates, setTooltipCoordinates] = useState({ x: 0, y: 0 })

  const t = useTranslations()

  const makeMapInteractive = (): void => {
    const svg = svgRef.current
    if (!svg) return

    /*eslint-disable*/
    let interactiveElements: NodeListOf<SVGElement>
    interactiveElements = getInteractiveElements(svg, country) as NodeListOf<SVGElement>
    if (!interactiveElements) return
    /*eslint-enable*/

    interactiveElements.forEach((element) => {
      element.addEventListener('mouseenter', (e) => {
        const target = e.target as SVGElement
        const tooltipReadabilityOffsetX = 45

        switch (country) {
          case 'ru':
            setHoveredCountry(target.id)
            break

          case 'kz':
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            setHoveredCountry(target.getAttribute('name')!)
            break
        }

        const tooltipX = e.pageX + tooltipReadabilityOffsetX
        const tooltipY = e.pageY
        setTooltipCoordinates({ x: tooltipX, y: tooltipY })
        setIsTooltipVisible(true)

        target.style.transitionDuration = '0.2s'
        target.style.transitionTimingFunction = 'linear'
        target.style.translate = '1px -2px'
        target.style.stroke = 'white'
        target.style.strokeWidth = '3px'
      })

      element.addEventListener('mouseleave', (e) => {
        const target = e.target as SVGElement

        setIsTooltipVisible(false)

        target.style.translate = ''
        target.style.stroke = ''
        target.style.strokeWidth = ''
      })
    })
  }

  useEffect(() => {
    makeMapInteractive()
  })

  return availableMaps.includes(country) ? (
    <>
      {country === 'ru' ? <RUMap ref={svgRef} /> : country === 'kz' ? <KZMap ref={svgRef} /> : null}
      <div
        className={classNames.tooltip}
        style={{
          display: isTooltipVisible ? 'flex' : 'none',
          left: tooltipCoordinates.x + 'px',
          top: tooltipCoordinates.y + 'px',
        }}
      >
        <div className="text-md mb-2">{hoveredCountry && t(hoveredCountry)}</div>
        <div className="text-3xl">
          {mapData[hoveredCountry]?.companiesCount
            ? fmt.number(mapData[hoveredCountry].companiesCount, 'kz', false)
            : '-'}
        </div>
        <div>{t('companies_count')}</div>
      </div>
    </>
  ) : null
}
