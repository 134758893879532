import { $userProfile } from '@entities/user'
import { ROUTES } from '@shared/constants'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useEffect, useMemo, useState } from 'react'

const daysToMicroseconds = (days: number) => {
  return days * 8.64e7
}

const microsecondsToDays = (ms: number) => {
  return ms / 8.64e7
}

export const Notifications = () => {
  const t = useTranslations()
  const locale = useLocale()
  const [user] = useUnit([$userProfile])

  const [noBannerDB, setNoBannerDB] = useState<Record<string, string | null>>({})

  useEffect(() => {
    setNoBannerDB((prev) => ({
      ...prev,
      buy_plan: localStorage.getItem('nobanner.buy_plan'),
      balance_ended: localStorage.getItem('nobanner.balance_ended'),
      continue_plan: localStorage.getItem('nobanner.continue_plan'),
      plan_ended: localStorage.getItem('nobanner.plan_ended'),
      plan_ended_soon: localStorage.getItem('nobanner.plan_ended_soon'),
    }))
  }, [])

  const ignoreBanner = (banner: string) => {
    localStorage.setItem(`nobanner.${banner}`, 'true')
    setNoBannerDB((prev) => ({ ...prev, [banner]: 'true' }))
  }

  const banner = useMemo(() => {
    if (!user) return null

    const now = Date.now()
    const userPlanEndTime = new Date(user.end_date).getTime()

    if (user.plan_id === 0 && !noBannerDB['buy_plan']) return { text: 'buy_plan', data: {} }
    else if (user.reports <= 0 && now < userPlanEndTime && !noBannerDB['balance_ended'])
      return { text: 'balance_ended', data: { count: user.reports } }
    else if (user.reports <= 5 && now < userPlanEndTime && !noBannerDB['continue_plan'])
      return {
        text: 'continue_plan',
        data: { count: user.reports, dateEnd: new Date(user.end_date).toLocaleDateString(locale || 'ru') },
      }
    else if (now > userPlanEndTime && !noBannerDB['plan_ended']) return { text: 'plan_ended', data: {} }
    else if (Math.abs(now - userPlanEndTime) < daysToMicroseconds(5) && !noBannerDB['plan_ended_soon'])
      return {
        text: 'plan_ended_soon',
        data: { days: Math.round(microsecondsToDays(Math.abs(now - userPlanEndTime))) },
      }

    return null
  }, [user, noBannerDB, locale])

  if (!banner || !user) return null

  return (
    <Link
      href={ROUTES.PRICING}
      locale={locale}
      onClick={() => (banner ? ignoreBanner(banner.text) : null)}
      className="flex h-8 w-full flex-col items-center justify-center bg-purple-600 text-center align-middle text-sm text-gray-50"
    >
      {t(`banner.${banner.text}`, banner.data)}
    </Link>
  )
}
