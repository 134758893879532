import { RequestParams } from '@/api/model'
import { filterKeys } from '@/constants'

const filterMutate = (key: (typeof filterKeys)[number], value: string) => {
  const values = value.split(',')

  switch (key) {
    case 'inactive':
      return values.map((v) => v === 'true')
    case 'fin_taxes':
    case 'revenue':
      return {
        min: Number(values[0]),
        max: Number(values.length < 2 ? values[0] : values[1]),
      }
    case 'contact_type':
      return value
    default:
      return values
  }
}

export const queryToFilters = (
  query: URLSearchParams,
): RequestParams<'/business/search/highlights', 'post'>['filters'] => {
  return filterKeys.reduce(
    (acc, key) => ({
      ...acc,
      [key]: !query.get(key) ? null : filterMutate(key, query.get(key) as string /* type guard in condition */),
    }),
    {} as RequestParams<'/business/search/highlights', 'post'>['filters'],
  )
}
