import Image from 'next/image'
import { useRouter } from 'next/router'

export const Back = () => {
  const router = useRouter()
  return (
    <span className="absolute inset-2  h-8 w-8 cursor-pointer">
      <span onClick={() => router.back()}>
        <Image src="/images/back.svg" width={24} height={24} alt="back" />
      </span>
    </span>
  )
}
