import { combine, createEvent, createStore, sample } from 'effector'

import { loadPaymentsFx, requestRefundFx } from '@/api'
import { ResponseType } from '@/api/model'
import { Exist } from '@/T'

export const requestRefund = createEvent<{ recurrent_id: string }>()
export const paymentsLoaded = createEvent()
export const paymentsPageChanged = createEvent<number>()

export const $displayPaymentsPerPage = createStore(10)
export const $payments = createStore<Exist<ResponseType<'/billing/cloudpayments/history', 'get'>>>([])
export const $paymentsPage = createStore<number>(1)

export const $totalPayments = $payments.map((payments) => payments.length)
export const $slicedPayments = combine(
  $payments,
  $paymentsPage,
  $displayPaymentsPerPage,
  (payments, page, paymentsPerPage) => {
    return payments.slice((page - 1) * paymentsPerPage, page * paymentsPerPage)
  },
)

sample({
  //@ts-ignore
  clock: paymentsLoaded,
  target: loadPaymentsFx,
})

sample({
  clock: loadPaymentsFx.doneData,
  fn: (doneData) => doneData || [],
  target: $payments,
})

sample({
  clock: paymentsPageChanged,
  target: $paymentsPage,
})

sample({
  clock: requestRefund,
  fn: ({ recurrent_id }) => ({
    path: {
      recurrent_id,
    },
  }),
  target: requestRefundFx,
})

sample({
  clock: requestRefundFx.done,
  target: paymentsLoaded,
})
