import clsx from 'clsx'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { SetRequired } from 'type-fest'

import { SafeView, usePlan } from '@/features'
import fmt from '@/lib/fmt'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import styles from '../styles.module.css'
import { TableSection } from './table-section'

export function Officers({
  for: $data,
  isFull: $isFull,
}: SetRequired<Pick<ForCompanyOrIndividualProps, 'for' | 'isFull'>, 'isFull'>) {
  const { openPlanModalWindow } = usePlan()
  const [officers, chiefOfficers] = useStoreMap({
    store: $data,
    keys: [],
    fn: (data) => {
      const officers: Record<string, any>[] = []
      const chiefOfficers: Record<string, any>[] = []
      //@ts-ignore
      data?.officers?.forEach((officer) => {
        if (officer.inactive && officer.role !== 'chief_accountant') {
          officers.push({ ...officer, role: 'ceo' })
        }

        if (officer.role === 'chief_accountant') {
          chiefOfficers.push(officer)
        }

        if (officer.role !== 'chief_accountant' && !officer.inactive) {
          officers.push(officer)
        }
      })

      return [
        officers.sort((a, b) => {
          if (a.inactive && b.inactive) return -1
          if (a.inactive && !b.inactve) return 1
          if (b.inactive && !a.inactive) return -1

          return 0
        }),
        chiefOfficers,
      ]
    },
  })

  const [isFull, data] = useUnit([$isFull, $data])

  const redirectToShareholderCompany = (person: boolean, company_id: string) => {
    if (isFull) {
      if (person && company_id) {
        const rightHash = company_id?.split('+').join('%')
        window.open(`/individuals?identifier=${rightHash}`, '_blank', 'noopener,noreferrer')
      } else if (company_id) {
        window.open(`/companies/${data?.jurisdiction}/${company_id}`, '_blank', 'noopener,noreferrer')
      }
    } else {
      openPlanModalWindow()
    }
  }

  const t = useTranslations()

  const translatedOfficersRoles = t.raw('officers_role') as Record<string, string>

  return (
    <>
      <TableSection title={t.raw('officers')['base']}>
        {officers.map((officer, idx) => (
          <div
            key={`${officer?.id}_${idx}`}
            className={clsx(
              `block w-full text-primary hover:text-flamingo`,
              {
                'cursor-pointer': officer?.hash_bin,
              },
              styles.officers,
            )}
            onClick={() => redirectToShareholderCompany(true, officer?.hash_bin || officer?.identifier)}
          >
            <span
              className={clsx({
                'text-[#90674f] line-through': officer.inactive,
              })}
            >
              {officer.name}
            </span>
            {officer.role !== 'ceo' && (
              <span className="text-gray-500">
                {' '}
                ·{' '}
                {(officer.role as string)?.toLocaleLowerCase() === officer.role
                  ? t.raw('officers_role')[officer.role]
                  : officer.role}
              </span>
            )}

            {officer.start_date && <span className="text-gray-500"> · {fmt.date(new Date(officer.start_date))}</span>}
            {!officer.inactive && officer.start_date && <span className="text-gray-500"> - {t('nowadays')}</span>}
            {officer.inactive && officer.end_date && <span>&nbsp;- {fmt.date(new Date(officer.end_date))}</span>}
          </div>
        ))}
      </TableSection>
      <SafeView for={chiefOfficers}>
        <TableSection title={t('chief_accountant')}>
          {chiefOfficers.map((officer, idx) => (
            <div
              key={`${officer?.id}_${idx}`}
              className={clsx(
                `block w-full text-primary hover:text-flamingo`,
                {
                  'cursor-pointer': officer?.hash_bin,
                },
                styles.officers,
              )}
            >
              <span
                className={clsx({
                  'text-[#90674f] line-through': officer.inactive,
                })}
              >
                {officer.name}
              </span>
            </div>
          ))}
        </TableSection>
      </SafeView>
    </>
  )
}
