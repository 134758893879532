import { useUnit } from 'effector-react'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { ReactNode, useEffect } from 'react'

import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'
import { routerSet } from '@/shared-events'
import { SubscriptionBanner } from '@/widgets'

import { Footer } from './footer'
import { Header } from './header'

type LayoutProps = Readonly<{
  children: ReactNode
}>

const fullPagePaths = ['auth', 'checkout']

export const Layout = ({ children }: LayoutProps) => {
  const router = useRouter()

  const [setRouter, user] = useUnit([routerSet, $userProfile])

  useEffect(() => {
    setRouter(router)
  }, [setRouter, router, user])

  return (
    <>
      <SafeView for={user} withProps>
        {(user) => {
          const isActive = _.get(user, 'status') === 'active'
          const reports = _.get(user, 'reports')
          const nextBillDate = _.get(user, 'next_bill_date')

          const offsetDaysNextBillDate = parseInt(
            Math.round((Date.now() - new Date(user.next_bill_date).getTime()) / (1000 * 60 * 60 * 24))
              .toString()
              .replace('-', ''),
          )

          const offsetDaysEndDate = parseInt(
            Math.round((Date.now() - new Date(user.end_date).getTime()) / (1000 * 60 * 60 * 24))
              .toString()
              .replace('-', ''),
          )

          return (
            <>
              <SafeView for={isActive && reports > 3 && reports <= 5 && offsetDaysNextBillDate > 3}>
                <SubscriptionBanner
                  textKey={nextBillDate ? 'banner.continue_plan' : 'banner.continue_plan_without_next_bill_date'}
                  count={reports}
                  dateEnd={fmt.date(new Date(nextBillDate || ''))}
                />
              </SafeView>

              {isActive && offsetDaysNextBillDate <= 3 && (
                <SubscriptionBanner textKey={'banner.plan_ended_soon'} days={offsetDaysNextBillDate} />
              )}
              {isActive && offsetDaysEndDate <= 3 && (
                <SubscriptionBanner textKey={'banner.plan_ended_soon'} days={offsetDaysEndDate} />
              )}
              {Boolean(reports && reports <= 3) && (
                <SubscriptionBanner textKey={'banner.balance_ended'} count={reports} />
              )}
            </>
          )
        }}
      </SafeView>

      <SafeView for={!fullPagePaths?.some((path) => router.pathname.includes(path))} otherwise={null}>
        <Header />
      </SafeView>
      {children}
      <SafeView for={!fullPagePaths?.some((path) => router.pathname.includes(path))} otherwise={null}>
        <Footer />
      </SafeView>
    </>
  )
}
