// @ts-nocheck
import { PaginationView } from '@shared/ui/pagination'
import { useUnit } from 'effector-react'
import * as FileSaver from 'file-saver'
import { useTranslations } from 'next-intl'
import { useEffect, useState } from 'react'

import { $isOrderHistoryLoading } from '@/api'
import { $axiosInstance } from '@/shared-events'
import { CompaniesList, ShadcnButton } from '@/ui'

import { generateHeaders } from 'src/shared/api/model'

import { CompanyItemLoading } from '@shared/ui/companies/company-item-loading'
import Icon from '@shared/ui/icon'
import { $ordersHistory, $page, $totalOrders, orderHistoryLoaded, pageChanged } from './model'

export const OrderHistoryScreen = () => {
  const [isLoading, loadOrderHistory, totalOrders, handlePaginate, page, ordersHistory] = useUnit([
    $isOrderHistoryLoading,
    orderHistoryLoaded,
    $totalOrders,
    pageChanged,
    $page,
    $ordersHistory,
  ])
  const [axios] = useUnit([$axiosInstance])
  const t = useTranslations()

  const [isFileLoading, setIsFileLoading] = useState(false)

  useEffect(() => {
    loadOrderHistory()
  }, [loadOrderHistory])

  const downloadExcel = async () => {
    setIsFileLoading(true)
    try {
      const headers = generateHeaders()
      const url = `${process.env.NEXT_PUBLIC_API_URL}/api/v2/export/create/reports`

      const res: any = await axios?.get(url, {
        responseType: 'arraybuffer',
        headers,
      })

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const data = new Blob([res.data], { type: fileType })
      FileSaver.saveAs(data, 'report' + fileExtension)
    } catch (e) {
      console.info(e)
    }
    setIsFileLoading(false)
  }

  return (
    <>
      <div className="col-span-full grid grid-cols-4 pb-4">
        <ShadcnButton onClick={downloadExcel}>
          {isFileLoading ? <Icon name="loader-circle" className="animate-spin" /> : t(`add_on_excel`)}
        </ShadcnButton>
        {!isLoading ? (
          <div className="col-span-full">
            <CompaniesList data={ordersHistory} jurisdiction="kz" self />

            <div className="my-6 w-fit">
              <PaginationView onPageChange={handlePaginate} totalCount={totalOrders} pageSize={20} currentPage={page} />
            </div>
          </div>
        ) : (
          <h2 className="col-span-full text-xl font-medium">{t(`no_history`)}</h2>
        )}
      </div>
      {isLoading ? (
        <div style={{ width: '600px' }}>
          <CompanyItemLoading />
        </div>
      ) : (
        ''
      )}
    </>
  )
}
