import { createBindingForVisibility } from '@/features'
import { loadBrowserInfoFx } from '@shared/api'
import { createStore, sample } from 'effector'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, $isLoaded, $companyId, visibilityChanged } = createBindingForVisibility()

export const $browser = createStore<ResponseType<'/business/{id}/browser/preview', 'get'>>([])

sample({
  clock: $inView,
  source: { isLoaded: $isLoaded, id: $companyId },
  filter: ({ isLoaded, id }, inView) => !isLoaded && inView && !!id,

  fn: ({ id }) => ({
    path: {
      id: id as string,
    },
  }),
  target: [loadBrowserInfoFx],
})

sample({
  clock: loadBrowserInfoFx.doneData,
  target: $browser,
})
