import clsx from 'clsx'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'

import { useMemo } from 'react'

import { SafeView } from '@/features'
import { Nullable } from '@/T'

import styles from './styles.module.css'

export interface CrumbProps {
  text: string
  href: string
  last?: boolean
  textGenerator: { trans: string; param: string | string[] | undefined }
  customText?: Nullable<string>
}

export function Crumb({ text, href, textGenerator, last = false, customText = null }: CrumbProps) {
  const t = useTranslations()
  const locale = useLocale()

  const isJurisdiction = textGenerator.trans === 'jurisdictions'

  const translated = useMemo(() => {
    if (customText) return customText

    if (!textGenerator.trans) {
      return text
    }

    const generatedText = t.raw(textGenerator.trans)
    if (isJurisdiction) return generatedText[text]
    return generatedText
  }, [customText, isJurisdiction, t, text, textGenerator.trans])

  return (
    <li
      itemProp="itemListElement"
      itemScope
      itemType="https://schema.org/ListItem"
      className={clsx(styles.breadcrumbsItem, 'cursor-pointer text-primary hover:text-flamingo')}
    >
      <SafeView
        for={last}
        otherwise={
          <Link
            locale={locale}
            itemProp={'item'}
            itemScope
            itemType="https://schema.org/Thing"
            itemID={href}
            href={href}
          >
            {customText || translated}
          </Link>
        }
      >
        <span>{customText || translated}</span>
      </SafeView>
    </li>
  )
}
