import { DependencyList, EffectCallback, useEffect, useRef } from 'react'

export const useDidUpdateEffect = (effect: EffectCallback, deps: DependencyList) => {
  const isMountingRef = useRef(false)

  useEffect(() => {
    isMountingRef.current = true
  }, [])

  useEffect(() => {
    if (!isMountingRef.current) {
      return effect()
    } else {
      isMountingRef.current = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
