import axios from 'axios'
import { setCookie } from 'cookies-next'
import { attach, createEffect, createEvent, createStore, sample } from 'effector'
import FileSaver from 'file-saver'
import ky from 'ky'
import { NextParsedUrlQuery } from 'next/dist/server/request-meta'
import { NextRouter } from 'next/router'
import { combineEvents } from 'patronum'

import { generateHeaders } from '@/api/model'

import { parseJwt } from '@middleware'
import { paths } from '../api/api.types'

export const kyBaseInstance = ky.create({
  prefixUrl: process.env.NEXT_PUBLIC_API_URL,
  headers: {
    mode: 'cors',
  },
  hooks: {
    beforeRequest: [
      (request) => {
        if (
          window &&
          window.localStorage &&
          localStorage.getItem('token') &&
          request.headers.get('Authorization') === null
        ) {
          request.headers.set('Authorization', `Bearer ${localStorage.getItem('token')}`)
        }
      },
    ],
    afterResponse: [
      async (request, options, response) => {
        if (response.status === 401) {
          if (window && window.localStorage && localStorage.getItem('refresh')) {
            localStorage.removeItem('token')

            const result = await ky
              .post(process.env.NEXT_PUBLIC_API_URL + '/user/me/refresh', {
                json: {
                  refresh_token: localStorage.getItem('refresh'),
                },
                headers: {
                  ...generateHeaders(),
                },
              })
              .json<paths['/user/me/refresh']['post']['responses']['200']['content']['application/json']>()

            localStorage.setItem('token', result.access_token)
            localStorage.setItem('refresh', result.refresh_token)

            request.headers.set('Authorization', `Bearer ${result.access_token}`)

            const nowUnix = (+new Date() / 1e3) | 0
            setCookie('access', result.access_token, {
              path: '/',
              maxAge: (parseJwt(result.access_token)?.exp || 0) - nowUnix,
            })
            setCookie('refresh', result.refresh_token, {
              path: '/',
              maxAge: (parseJwt(result.refresh_token)?.exp || 0) - nowUnix,
            })

            return ky(request)
          }
        }
      },
    ],
  },
})

export const axiosBaseInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL?.includes('/api/v2')
    ? process.env.NEXT_PUBLIC_API_URL
    : process.env.NEXT_PUBLIC_API_URL + '/api/v2',
  headers: {
    mode: 'cors',
  },
})

export const appStarted = createEvent()
export const routerSet = createEvent<NextRouter>()
export const $userToken = createStore<string>('')
export const $refreshToken = createStore<string>('')

export const $router = createStore<NextRouter | null>(null)
export const $qParams = createStore<NextParsedUrlQuery | null>(null).on($router, (_, router) => router?.query)
export const $axiosInstance = createStore(axiosBaseInstance, {
  serialize: 'ignore',
})

export const clientCookiesSet = createEvent<string>()
export const refreshSet = createEvent<string>()
export const loadAuthenticatedUser = createEvent()

sample({
  clock: clientCookiesSet,
  target: $userToken,
})

sample({
  clock: refreshSet,
  target: $refreshToken,
})

export const pushFx = attach({
  source: $router,
  effect(router, path: string) {
    if (!router) {
      throw new Error('router not ready')
    }

    router.push(path)
  },
})

const saveTokenFx = attach({
  source: { userToken: $userToken },
  effect({ userToken }) {
    if (window && localStorage) localStorage.setItem('token', userToken)
  },
})

export const setupAxiosFx = attach({
  source: { userToken: $userToken },
  effect({ userToken }) {
    axiosBaseInstance.interceptors.request.clear()

    if (userToken) {
      axiosBaseInstance.interceptors.request.use((config) => {
        config.headers['Authorization'] = `Bearer ${userToken}`
        return config
      })
    }

    return axiosBaseInstance
  },
})

export const loadUserFx = attach({
  source: { instance: $axiosInstance, userToken: $userToken },
  async effect({ instance, userToken }) {
    const { data } = await instance.get('/user/me', {
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
    return data
  },
})

sample({
  clock: appStarted,
  target: [setupAxiosFx],
})

sample({
  clock: setupAxiosFx.doneData,
  target: $axiosInstance,
})

sample({
  clock: combineEvents({ events: [appStarted, setupAxiosFx.doneData] }),
  source: $userToken,
  filter: (token) => token?.length > 0,
  target: loadAuthenticatedUser,
})

sample({
  clock: loadAuthenticatedUser,
  target: loadUserFx,
})

sample({
  clock: routerSet,
  target: $router,
})

sample({
  clock: clientCookiesSet,
  target: saveTokenFx,
})

export const downloadFx = createEffect(async ({ file, jurisdiction, search }: any) => {
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'
  const data = new Blob([file], { type: fileType })
  return FileSaver.saveAs(data, `${search}_${jurisdiction}` + fileExtension)
})
