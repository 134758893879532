import clsx from 'clsx'
import { Store } from 'effector'
import { useStoreMap, useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { useEffect, useMemo, useRef } from 'react'

import { $counters, countOfCategorySet } from '@/screens'
import { CounterCategory } from 'src/screens/company/types'

import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

import { ResponseType } from 'src/shared/api/model'

interface NavigationProps {
  person?: boolean
  risks: Store<
    | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['risks']
    | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['risks']
    | ResponseType<'/business/individuals', 'get'>['risks']
  >
  for: ForCompanyOrIndividualProps['for']
  meta: Store<
    | ResponseType<'/business/{jurisdiction}/{id}/view', 'get'>['meta']
    | ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['meta']
  >
}

/*
 * meta has k-v values
 *
 * left side what we want
 * right side what we got from meta
 * */
const capabilityMap: Record<string, string[]> = {
  employees: ['employees'],
  courts: ['courts_cases'],
  activity_type: ['activities'],
  government_contracts: ['gov_contracts'],
  registration_history: ['events'],
  contacts: ['companies_contacts', 'contacts', 'websites'],
}

const badList = ['risks']

export function Navigation({ person = false, risks: $risks, for: $data, meta: $meta }: NavigationProps) {
  const t = useTranslations()
  const tabs = t.raw(person ? 'person_second_header' : 'company_second_header_kz') as Record<string, string>[]
  const [data, meta, counters, setCounterCategory] = useUnit([$data, $meta, $counters, countOfCategorySet])

  const risksCount = useStoreMap({
    store: $risks,
    keys: [person, data?.id],
    fn: (risksArr, [person]) => {
      if (risksArr) {
        const riskTypes = person ? [''] : (['company', 'officers', 'shareholders'] as const)

        const risks = riskTypes.flatMap((riskType) => {
          const riskArr = riskType ? risksArr[riskType as keyof typeof risksArr] : risksArr
          // @ts-ignore
          return riskArr?.map((risk: any) => {
            return { ...risk, riskType: riskType || 'person' }
          })
        })

        return risks.reduce((acc, risk) => {
          return acc + (risk?.status === 'found' ? 1 : 0)
        }, 0)
      }
      return 0
    },
  })

  useEffect(() => {
    if (!risksCount || counters['risks'] === risksCount) {
      return
    }

    setCounterCategory({ category: 'risks', count: risksCount })
  }, [setCounterCategory, risksCount, counters])

  const headerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    let headerEl = headerRef.current
    let breakpoints: [string, number][] = []

    const updateActiveBlock = () => {
      const wScroll = window.scrollY
      const tabsItems = document.querySelectorAll('.tabs-item')

      if (wScroll >= 256) {
        if (headerEl) {
          headerEl.classList.add('fixed', 'top-0', 'left-0', 'z-30')
          tabsItems.forEach((el) => el.classList.remove('active'))

          const activeBreakpoint = breakpoints.find((_, idx) =>
            breakpoints[idx + 1] ? wScroll < breakpoints[idx + 1][1] : true,
          )

          if (activeBreakpoint) {
            document.querySelectorAll(`.tabs-item[href="${activeBreakpoint[0]}"]`).forEach((el) => {
              el.classList.add('active')
            })
          }
        }
      } else {
        if (headerEl) {
          headerEl.classList.remove('fixed', 'top-0', 'left-0', 'z-30')
          document.querySelectorAll(`.tabs-item[href="#info"]`).forEach((el) => {
            el.classList.add('active')
          })
        }
      }
    }

    const scrollListener = () => {
      updateActiveBlock()
    }

    window.addEventListener('scroll', scrollListener)

    return () => {
      window.removeEventListener('scroll', scrollListener)
      headerEl = null
      breakpoints = []
    }
  }, [])

  return (
    <div className="flex w-full items-center justify-between" ref={headerRef}>
      <ul className="company-tabs justifty-between relative mx-auto flex h-12 w-full  max-w-container items-center justify-between overflow-hidden overflow-x-scroll whitespace-nowrap border-b bg-white">
        {tabs.map((tab, idx) => {
          const [[key, text]] = Object.entries(tab)

          return (
            <li className="min-w-fit text-center text-base" key={key}>
              <a
                href={`#${key}`}
                className={`tabs-item px-2 py-3 text-primary ease-in hover:text-flamingo ${idx === 0 ? 'active' : ''}`}
              >
                <span>{text}</span>
                <Counter counter={key} counters={counters} meta={meta} />
              </a>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

const Counter = ({
  counter,
  counters,
  meta,
}: {
  counter: string
  counters: Partial<Record<CounterCategory, number>>
  meta: ResponseType<'/business/{jurisdiction}/{id}/paid', 'get'>['meta']
}) => {
  const count = useMemo(() => {
    const getMetaCounter = (): number => {
      const metaCounters = capabilityMap[counter] || [counter]

      if (meta) {
        return Object.entries(meta as Record<string, number>)
          .filter(([key]) => metaCounters.includes(key))
          .map(([_, value]) => value || 0)
          .reduce((acc, value) => acc + value, 0)
      } else {
        return 0
      }
    }

    return counters[counter as CounterCategory] || getMetaCounter() || 0
  }, [meta, counters, counter])

  if (!count) return null

  const classes = clsx('ml-2', {
    'rounded px-2 py-0 text-white bg-red-500': badList.includes(counter),
    'bg-inherit text-gray-400': !badList.includes(counter),
  })

  return <span className={classes}>{count}</span>
}
