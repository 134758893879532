import clsx from 'clsx'

import styles from './styles.module.css'

interface CompanyStatusProps {
  inActive?: boolean
}
export const CompanyStatus = ({ inActive }: CompanyStatusProps) => {
  return (
    <span
      className={clsx(styles.status, {
        [styles.statusActive]: !inActive,
        [styles.statusInActive]: inActive,
      })}
    ></span>
  )
}
