import { useUnit } from 'effector-react'
import * as FileSaver from 'file-saver'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'

import { useEffect } from 'react'

import { $isExportsHistoryLoading } from '@/api'

import { $userProfile } from '@/entities/user'
import fmt from '@/lib/fmt'
import { $axiosInstance } from '@/shared-events'
import { CircularProgress, Table } from '@/ui'

import { $exportsHistory, exportsHistoryLoad } from './model'
function checkPlan(plan?: number) {
  if (!plan) return false
  return plan > 2
}

export const ExportsScreen = () => {
  const t = useTranslations()
  const locale = useLocale()

  const [user, exports, loading, loadExportsHistory, axios] = useUnit([
    $userProfile,
    $exportsHistory,
    $isExportsHistoryLoading,
    exportsHistoryLoad,
    $axiosInstance,
  ])

  useEffect(() => {
    loadExportsHistory()
  }, [loadExportsHistory])

  const downloadExcel = async (id: string, name: string) => {
    try {
      const res: any = await axios?.get(`export/download/${id}`, {
        responseType: 'arraybuffer',
      })

      const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
      const fileExtension = '.xlsx'
      const data = new Blob([res.data], { type: fileType })
      FileSaver.saveAs(data, name + fileExtension)
    } catch (e) {
      console.info(e)
    }
  }

  return (
    <>
      <div className="col-span-full flex items-center justify-start">
        <h1 className="font-stem text-2xl">{t(`unloading_history`)}</h1>
        <span className="ml-2 text-xl text-gray-500">{exports?.length || ''}</span>
      </div>
      <div className={'col-span-full flex w-full'}>
        <Link
          locale={locale}
          className={
            'text-md w-full rounded bg-blue-500 p-2 text-white transition-colors hover:bg-blue-600 focus:outline-none md:w-auto md:text-sm'
          }
          href={`/search/kz`}
        >
          {t('create_export')}
        </Link>
      </div>
      {!checkPlan(user?.plan_id) ? (
        <>
          <h2 className="col-span-full text-center text-lg">{t('only_for_subscribers_premium')}</h2>
        </>
      ) : null}
      {checkPlan(user?.plan_id) ? (
        exports?.length && !loading ? (
          <>
            <Table
              isFull
              withCustomHide
              column={t.raw(
                exports?.filter((item: any) => item.description)?.length
                  ? 'excel_download_head_with_error'
                  : 'excel_download_head',
              )}
              data={exports.map((e: any) => {
                const filters = Object.values(e?.filters)?.map((filter: any, idx: number, arr: any) => {
                  return filter ? (idx + 1 !== arr?.length ? filter + ', ' : filter) : ''
                })

                return {
                  id: e.id || '-',
                  status:
                    e.status === 'FINISHED' ? (
                      <span className="text-green">{t(`excel_status_success`)}</span>
                    ) : e.status === 'IN_PROGRESS' || e.status === 'IN_QUEUE' ? (
                      <span className="text-red-500">{t(`excel_status_progress`)}</span>
                    ) : (
                      <span className="text-red-500">{t(`excel_status_error`)}</span>
                    ),
                  source: filters?.length ? filters : '-',
                  date: e.created_at ? fmt.date(new Date(e.created_at)) : '-',
                  description: e.description || e.error,
                  companies: e.filters?.true_limit || '-',
                  button: e.url ? (
                    <button
                      className={`col-span-full rounded bg-blue-500 px-2 py-1 text-white focus:outline-none md:col-span-1`}
                      onClick={() => {
                        window.open(e.url, '_blank')
                      }}
                    >
                      {t('dop_payment')}
                    </button>
                  ) : (
                    <button
                      className={`col-span-full rounded px-2 py-1 text-white transition-colors focus:outline-none md:col-span-1 ${
                        e.status === 'FINISHED'
                          ? 'bg-blue-500 hover:bg-blue-600'
                          : e.status === 'IN_PROGRESS' || e.status === 'IN_QUEUE'
                            ? 'bg-gray-400 hover:bg-gray-500'
                            : 'bg-gray-400 hover:bg-gray-500'
                      }`}
                      disabled={
                        e.status === 'FINISHED'
                          ? false
                          : e.status === 'IN_PROGRESS' || e.status === 'IN_QUEUE'
                            ? true
                            : true
                      }
                      onClick={() => downloadExcel(e.hash_id, e.file_name)}
                    >
                      {t('download_on_excel')}
                    </button>
                  ),
                }
              })}
            />
          </>
        ) : loading ? (
          <div className="h-10 w-10">
            <CircularProgress />
          </div>
        ) : (
          <h2 className="col-span-full text-xl font-medium">{t(`no_history`)}</h2>
        )
      ) : null}
    </>
  )
}
