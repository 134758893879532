import { Tooltip, TooltipContent, TooltipTrigger } from '@/shared/ui/tooltip'
import { $isFullReport } from '@screens/company/model'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { MouseEventHandler, ReactNode } from 'react'

type FilterItemProps = {
  isFull?: boolean
  applyFilter: (v: string | null) => void
}

type Props = {
  children(p: FilterItemProps): ReactNode
  isFull?: boolean
  applyFilter: (v: string | null) => void
}

interface TableFilterItemProps {
  className: string
  children: ReactNode
  onClick: MouseEventHandler
}

export function TableFilterItem({ className, children, onClick }: TableFilterItemProps) {
  const t = useTranslations()
  const [isFull] = useUnit([$isFullReport])

  const value = (
    <li onClick={onClick} className={clsx('flex cursor-pointer flex-row hover:text-flamingo', className)}>
      {children}
    </li>
  )

  return (
    <span className="with-dots flex flex-row text-primary">
      {isFull ? (
        value
      ) : (
        <Tooltip>
          <TooltipTrigger>{value}</TooltipTrigger>
          <TooltipContent>{t(`service_access_in_full_order`)}</TooltipContent>
        </Tooltip>
      )}
    </span>
  )
}

export function TableFilters({ children, isFull, applyFilter }: Props) {
  const handleFilter = (id: string | null) => {
    if (isFull) {
      applyFilter(id === '-1' ? null : id)
    }
  }

  return (
    <ul className="relative col-span-full mb-6 flex w-full flex-col flex-wrap space-y-1 md:flex-row md:space-x-2 md:space-y-0">
      {children({ applyFilter: handleFilter, isFull })}
    </ul>
  )
}
