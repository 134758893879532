import { createEvent, createStore, sample } from 'effector'

import { loadEmployeesFx } from '@/api'
import { createBindingForVisibility } from '@/features'
import { ResponseType } from 'src/shared/api/model'

export const { $inView, visibilityChanged, $companyId, $isLoaded, $shouldBeLoaded } = createBindingForVisibility()
export const $employees = createStore<ResponseType<'/business/{company_id}/employers', 'get'>>({})
export const $employeesPage = createStore(0)
export const employeesPageChanged = createEvent<number>()
sample({
  clock: $inView,
  source: {
    companyId: $companyId,
    isLoaded: $isLoaded,
    shouldBeLoaded: $shouldBeLoaded,
  },
  filter: ({ companyId, isLoaded, shouldBeLoaded }, visible) => visible && !isLoaded && !!companyId && shouldBeLoaded,
  fn: ({ companyId }) => ({
    path: {
      company_id: companyId as number,
    },
    query: {},
  }),
  target: [loadEmployeesFx],
})

sample({
  clock: loadEmployeesFx.doneData,
  fn: () => true,
  target: $isLoaded,
})

sample({
  clock: loadEmployeesFx.doneData,
  target: $employees,
})

sample({
  clock: employeesPageChanged,
  target: $employeesPage,
})

sample({
  clock: $employeesPage,
  source: $companyId,
  fn: (id, page) => ({
    path: {
      company_id: id as number,
    },
    query: {
      limit: 20,
      page,
    },
  }),
  target: loadEmployeesFx,
})
