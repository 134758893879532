import clsx from 'clsx'
import { useUnit } from 'effector-react'
import { useRouter } from 'next/router'

import { SafeView } from '@/features'
import { $company, $isFullReport } from '@/screens'
import { Crumb } from 'src/widgets/breadcrumbs/ui'

export function CompanyCrumbs() {
  const [company, isFullReport] = useUnit([$company, $isFullReport])
  const { query } = useRouter()
  return (
    <ul
      className={clsx('my-4 flex text-base', {
        'mt-1': isFullReport,
      })}
      itemScope={true}
      itemType="https://schema.org/BreadcrumbList"
    >
      <Crumb
        text={company?.jurisdiction ?? 'kz'}
        href={`/states/${company?.jurisdiction ?? 'kz'}`}
        textGenerator={{
          trans: 'jurisdictions',
          param: query.state as string,
        }}
      />

      <SafeView for={company?.jurisdiction && company?.addresses?.[0]?.path}>
        <Crumb
          text={company?.addresses?.[0]?.state ?? ''}
          href={`/states/${company?.jurisdiction}/${company?.addresses?.[0]?.path}`}
          textGenerator={{
            trans: company?.addresses?.[0]?.state ?? '',
            param: query.state as string,
          }}
          customText={company?.addresses?.[0]?.state}
        />
      </SafeView>
    </ul>
  )
}
