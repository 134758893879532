import { useLocale } from 'next-intl'
import Highlighter from 'react-highlight-words'

import { SafeView } from '@/features'

export const CompanyHighlight = ({
  query,
  correctName,
  companyName,
}: {
  query: string
  correctName: string
  companyName: string
}) => {
  const lang = useLocale()

  return (
    <>
      <SafeView for={query}>
        <Highlighter
          highlightClassName="bg-transparent text-primary font-bold"
          searchWords={query.split(' ')}
          autoEscape={true}
          textToHighlight={correctName}
        />
      </SafeView>

      <SafeView for={!query && lang === 'ru'}>{correctName}</SafeView>

      <SafeView for={!query && lang !== 'ru'}>{companyName}</SafeView>
    </>
  )
}
