import { Event, createEvent, createStore, sample } from 'effector'
import { debounce } from 'patronum'

import * as api from '@/api'
import { Jurisdictions } from '@/entities/jurisdiction'
import { $router } from '@/shared-events'
import { RequestParams, UnwrapApiResponse } from 'src/shared/api/model'

export const $suggestedCompanies = createStore<UnwrapApiResponse<'/business/search', 'post'>>({
  company: [],
  count: 0,
})
export const suggestingReset = createEvent()
export const $query = createStore<string>('')
export const $jurisdiction = createStore<Jurisdictions>(Jurisdictions.KZ)

export const queryChanged = createEvent<string>()
export const jurisdictionChanged = createEvent<Jurisdictions>()
export const companiesSuggested = createEvent<RequestParams<'/business/search', 'post'>>()

const debouncedSuggesting = debounce({
  source: companiesSuggested,
  timeout: 250,
})

sample({
  clock: queryChanged,
  target: $query,
})

sample({
  clock: jurisdictionChanged,
  target: $jurisdiction,
})

sample({
  clock: debouncedSuggesting,
  source: $router,
  //@ts-ignore
  filter: (_, { query }) => (query ? query?.length > 0 : false),
  fn: (router, data) => ({
    data,
    query: {
      query: data.query,
      limit: 5,
    },
    headers: {
      'Accept-Language': router?.locale ?? 'ru',
      ...api.apiModel.generateHeaders(),
    },
  }),
  target: api.suggestCompaniesFx,
})

sample({
  //@ts-ignore
  clock: debouncedSuggesting,
  //@ts-ignore
  filter: ({ query }) => query?.length === 0,
  target: $suggestedCompanies.reinit as Event<void>,
})
//@ts-ignore
sample({
  //@ts-ignore
  clock: api.suggestCompaniesFx.doneData,
  target: $suggestedCompanies,
})

sample({
  clock: suggestingReset,
  target: [$suggestedCompanies.reinit, $jurisdiction.reinit],
})
