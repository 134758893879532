import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'

import fmt from '@/lib/fmt'

import { CollapsibleBlock } from '@/widgets'
import { ForCompanyOrIndividualProps } from 'src/screens/company/ui/types'

export function SeoDescription({ for: $data }: ForCompanyOrIndividualProps) {
  const t = useTranslations()
  const data = useUnit($data)

  return (
    <CollapsibleBlock className="select-none" title={t('description')} dataType="description" id="description">
      <div className="col-span-full select-none text-sm md:col-span-6">
        <p>
          {t('seo_description_text', {
            company: data?.title || data?.name,
            incorporation_date: data?.incorporation_date ? fmt.date(new Date(data?.incorporation_date)) : '',
          })}
        </p>
        <p>
          {data?.structure !== 'se' &&
            t('identifier_seo_description', {
              identifier: data?.identifier || '',
            })}
        </p>

        <p>
          {t('legal_address')}: {data?.addresses?.[0]?.raw}
        </p>

        <p>
          {t('oked')} {(data as any)?.general_industry_code || ''}
          {data?.company_size || t.raw('company_size_labels')['small']}
        </p>

        {data?.industry && (
          <p>
            {t('main_activity_was')} {data?.industry}
          </p>
        )}

        <p>
          {t.raw('officers')['base']} -{' '}
          {data?.officers
            ?.map(
              (
                //@ts-ignore
                of,
              ) => of?.name,
            )
            ?.join(', ')}
        </p>

        {data?.structure === 'se' ? (
          <a
            href="https://stat.gov.kz/jur-search/filter"
            target="_blank"
            rel="nofollow"
            className="text-light col-span-full mt-4 text-xs text-gray-500 md:col-span-3"
          >
            {t('open_source', {
              name: 'stat.gov.kz',
            })}
          </a>
        ) : null}
      </div>
    </CollapsibleBlock>
  )
}
