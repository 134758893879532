import clsx from 'clsx'
import { useUnit } from 'effector-react'
import { useLocale, useTranslations } from 'next-intl'
import Link from 'next/link'
import { useRouter } from 'next/router'

import { ROUTES } from '@/constants'
import { $isUserAuthenticated, $userProfile } from '@/entities/user'
import { SafeView } from '@/features'

import headerLinksStyles from './header-links.module.css'
import { UserDropdown } from './user-dropdown'

export interface HeaderLinksProps {
  burger?: boolean
  className?: string
}

export const HeaderLinks = ({ className = headerLinksStyles.headerLink, burger = false }: HeaderLinksProps) => {
  const t = useTranslations()
  const locale = useLocale()
  const router = useRouter()
  const [user] = useUnit([$userProfile])

  const [isUserAuthenticated] = useUnit([$isUserAuthenticated])

  let query = router.query
  if (!isUserAuthenticated) {
    query = {
      ...router.query,
      ...(decodeURI(router.asPath) === '/'
        ? {}
        : {
            redirect_uri: decodeURI(router.asPath),
          }),
    }
  }

  return (
    <>
      <li className={className}>
        <Link locale={locale} href={ROUTES.PRICING} rel="nofollow">
          {t(`small_plans`)}
        </Link>
      </li>
      <li className={className}>
        <Link locale={locale} href={ROUTES.STATES}>
          {t(`companies`)}
        </Link>
      </li>
      <SafeView for={user?.name}>
        <li className={className}>
          <Link locale={locale} href={ROUTES.PERSONAL_AREA}>
            {user?.name.split(' ')[0] || ''}
          </Link>
        </li>
      </SafeView>
      <SafeView for={!isUserAuthenticated} otherwise={null}>
        <li
          className={clsx('font-medium text-primary', {
            hidden: burger,
          })}
        >
          |
        </li>
        <li className={className}>
          <Link
            locale={locale}
            href={{
              pathname: ROUTES.LOGIN,
              query,
            }}
            rel="nofollow"
          >
            {t(`login`)}
          </Link>
        </li>
        <li className={className}>
          <Link
            locale={locale}
            href={{
              pathname: ROUTES.SIGN_UP,
              query,
            }}
            rel="nofollow"
          >
            {t(`create_account`)}
          </Link>
        </li>
      </SafeView>

      <UserDropdown />
    </>
  )
}
