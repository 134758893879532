import * as api from '@/api'
import { createEvent, createStore, Event, sample } from 'effector'

import { availablePlans, Subscription } from '@/constants'
import { $isUserAuthenticated } from '@/entities/user'
import { pushFx } from '@/shared-events'
import { createEffect } from 'effector/effector.umd'
import { not } from 'patronum'

const externalRedirectFx = createEffect((url: string) => {
  if (typeof window !== 'undefined') {
    window.location.href = url
  }
})

export const $subscriptionDuration = createStore<'month' | 'year'>('month')
export const $subscriptions = createStore<Subscription[]>(availablePlans)

export const subscriptionDurationChanged = createEvent<string>()

export const subscriptionChosen = createEvent<Subscription>()

sample({
  clock: subscriptionDurationChanged as Event<'month' | 'year'>,
  target: $subscriptionDuration,
})

sample({
  clock: subscriptionChosen,
  fn: (subscription) => {
    return {
      path: {
        id: String(subscription.plan_id),
      },
    }
  },
  target: api.loadPaymentUrlFx,
})

sample({
  clock: subscriptionChosen,
  filter: not($isUserAuthenticated),
  fn: () => {
    return '/auth/signup'
  },
  target: pushFx,
})

sample({
  clock: api.loadPaymentUrlFx.doneData,
  filter: Boolean,
  fn: ({ checkout_url }) => checkout_url as string,
  target: externalRedirectFx,
})
