import { useUnit } from 'effector-react'

import { $individual } from '@/screens'

export function IndividualBrief() {
  const individual = useUnit($individual)

  return (
    <>
      <div className={`mt-2 w-full space-y-1`}>
        <h1 className={`w-full font-stem text-xl font-medium xl:text-2xl`}>{individual?.name || ''}</h1>
      </div>
    </>
  )
}
