import { useState } from 'react'

export type FAQProps = {
  header: string
  desc: string
}

const FAQItem = ({ header, desc }: FAQProps) => {
  const [show, setShow] = useState(false)

  return (
    <button
      type="button"
      onClick={() => setShow((prev) => !prev)}
      className={`my-1 w-full rounded-3xl px-8 py-6 transition-all duration-300 hover:bg-gray-100 ${
        show ? 'bg-gray-100' : ''
      }`}
    >
      <div className="flex justify-between text-lg font-medium md:text-xl">
        {header}
        <span className={`rotate-0 text-3xl font-thin duration-200 ${show ? 'rotate-45 text-black' : 'text-gray-400'}`}>
          +
        </span>
      </div>
      <div className={`mt-4 text-start font-normal md:text-lg ${show ? 'block' : 'hidden'}`}>{desc}</div>
    </button>
  )
}

export default FAQItem
