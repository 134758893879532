import { createEvent, createStore, sample } from 'effector'

import { loadExportsHistoryFx } from '@/api'

export const $exportsHistory = createStore<Record<string, any>[]>([])
export const exportsHistoryLoad = createEvent()

sample({
  //@ts-ignore
  clock: exportsHistoryLoad,
  target: loadExportsHistoryFx,
})

sample({
  //@ts-ignore
  clock: loadExportsHistoryFx.doneData,
  fn: ({ exports }) => exports,
  target: $exportsHistory,
})
