import { ShadcnButton } from '@/ui'
import { CheckIcon, ReloadIcon } from '@radix-ui/react-icons'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import { usePathname, useRouter } from 'next/navigation'

import { useEffect, useState } from 'react'

import * as api from '@/api'
import { Subscription } from '@/constants'
import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features'
import fmt from '@/lib/fmt'

import { $subscriptionDuration } from '../model'

const SubscriptionInformation = ({ title }: { title: string; description: string }) => {
  return (
    <div className="flex items-center gap-x-1">
      <CheckIcon />

      <span className="cursor-pointer text-[14px] font-medium">{title}</span>
    </div>
  )
}

export const SubscriptionForm = ({
  subscription,
  onSubscriptionSelect,
  duration,
}: {
  subscription: Subscription
  onSubscriptionSelect: (s: Subscription) => void
  duration: 'month' | 'year'
}) => {
  const t = useTranslations()
  const [user, subscriptionDuration, isPaymentLoading] = useUnit([
    $userProfile,
    $subscriptionDuration,
    api.$isPaymentLoading,
  ])
  const router = useRouter()
  const pathname = usePathname()
  const [isLoading, setIsLoading] = useState(false)

  const forBanner: any = (subscription: Subscription) => {
    localStorage.removeItem('subscribe')
    localStorage.setItem('subscribe', JSON.stringify({ tried_at: Date.now(), subscription }))
  }

  useEffect(() => {
    if (!isPaymentLoading) {
      setIsLoading(false)
    }
  }, [isPaymentLoading])

  const redirectToCreateAccount = () => {
    router.push(`/auth/sign-up?redirect=${pathname}`)
  }

  return (
    <SafeView for={duration === 'year' ? subscription.months === 12 : subscription.months === 1} otherwise={null}>
      <div
        className="border-slate relative mx-2 flex flex-auto flex-col items-start justify-between space-y-4 rounded-[12px] border p-4"
        style={{
          width: '350px',
        }}
      >
        <form
          className="relative flex w-full flex-auto flex-col items-start justify-between gap-y-4 rounded-[12px] bg-white"
          onSubmit={(e) => {
            e.preventDefault()
            onSubscriptionSelect(subscription)
            setIsLoading(true)
          }}
        >
          <input type="hidden" name="planPrice" value={`${subscription.price}`} />
          <input type="hidden" name="planId" value={`${subscription.plan_id}`} />
          <input type="hidden" name="planTitle" value={`${subscription.title}`} />
          <input type="hidden" name="planChecks" value={`${subscription.checks}`} />
          <input type="hidden" name="planMonths" value={`${subscription.months}`} />
          <div className="w-full rounded-[12px]">
            <h2 className="flex items-center justify-between py-2 font-stem text-[27px] font-medium">
              {subscription.title === 'Basic' ? t('basic') : subscription.title}

              {subscription.title === 'Pro' && <span className="text-sm text-primary">{t('popular')}</span>}
            </h2>
            <p className="mb-2 text-[14px] opacity-[0.7]">
              {fmt.number((subscription?.checks || 0) * subscription.months || '', 'kz', false)}{' '}
              {subscription.months === 1
                ? `${t('length_checks')} / ${t('checks_price')}`
                : subscription.months === 12
                  ? `${t('length_checks')} / ${t('checks_price_year')}`
                  : null}
            </p>
            <div>
              <h3 className="mb-2 flex flex-col gap-2 pt-2 font-stem text-[40px] font-medium">
                <div className="text-nowrap relative flex items-start gap-2">
                  <SafeView for={subscriptionDuration === 'year'}>
                    <span className="relative text-[20px] line-through opacity-[0.7]">
                      <span className="absolute ml-[-10px] text-[15px] opacity-[0.7]">₸</span>
                      <span>{subscription.price ? fmt.number(subscription.old_price, 'kz', false) : '0'} </span>
                    </span>
                  </SafeView>

                  <span className="text-nowrap relative pl-4">
                    <span className="absolute left-0 top-0 align-text-top text-[20px] opacity-[0.7]">₸</span>
                    {subscription.price ? fmt.number(subscription.price, 'kz', false) : '0'}{' '}
                    <span className="text-sm opacity-[0.7]">
                      {subscriptionDuration === 'month' ? t('checks_price') : t('checks_price_year')}
                    </span>
                  </span>
                </div>
              </h3>
              <SafeView
                for={user}
                withProps
                otherwise={
                  <ShadcnButton size="xl" className="w-full" onClick={redirectToCreateAccount} variant="linear">
                    {t('create_account')}
                  </ShadcnButton>
                }
              >
                {(user) => (
                  <ShadcnButton
                    onClick={() => forBanner(subscription)}
                    disabled={user.plan_id === subscription.id || isLoading}
                    variant={user.plan_id === subscription.id ? 'disabled' : 'linear'}
                    size="xl"
                    className="w-full"
                  >
                    {isLoading && <ReloadIcon className="mr-2 h-4 w-4 animate-spin" />}

                    <span className="text-lg font-bold">
                      {user?.plan_id === subscription.id ? t('your_subscribe') : t('start')}
                    </span>
                  </ShadcnButton>
                )}
              </SafeView>
            </div>
            <div className="border-slate mt-3 flex flex-col gap-[8px] border-t pt-3">
              {t
                .raw(
                  subscription.title === 'Pro'
                    ? 'pro_text'
                    : subscription.title === 'Premium'
                      ? 'premium_text'
                      : subscription.title === 'Basic'
                        ? 'basic_plans_text'
                        : 'free_plans_text',
                )
                .map((e: { title: string; description: string }, idx: number) => (
                  <SubscriptionInformation key={idx} {...e} />
                ))}
            </div>
          </div>
        </form>
      </div>
    </SafeView>
  )
}
