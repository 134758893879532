import * as Popover from '@radix-ui/react-popover'

import { HeaderLinks } from '../header-links'
import styles from './styles.module.css'

export const Burger = () => {
  return (
    <Popover.Root>
      <Popover.Trigger className="burger-menu">
        <div className="burger-menu-arrow"></div>
        <div className="burger-menu-arrow"></div>
      </Popover.Trigger>
      <Popover.Content className={styles.burgerList} sideOffset={24}>
        <ul className="flex w-full flex-col justify-center divide-y divide-gray-200 text-lg">
          <HeaderLinks className={styles.burgerLinks} burger />
        </ul>
      </Popover.Content>
    </Popover.Root>
  )
}
