'use client'

import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import React, { FormEvent, useState } from 'react'

import { $userProfile } from '@/entities/user'
import { ShadcnButton } from '@/ui'

import { Button } from '@/shared/ui/button'
import { Collapsible, CollapsibleContent, CollapsibleTrigger } from '@/shared/ui/collapsible'
import Icon from '@/shared/ui/icon'
import { Input } from '@/shared/ui/input'
import { Label } from '@/shared/ui/label'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/shared/ui/select'
import { Separator } from '@/shared/ui/separator'
import { Dialog, DialogContent, DialogDescription, DialogTitle, DialogTrigger } from '@shared/ui/dialog'
import { Tooltip, TooltipContent, TooltipTrigger } from '@shared/ui/tooltip'
import { feedbackLoading, postFeedback } from './model'
import { ChangeList, ChildModalProps } from './types'

const CollapsableBlock = ({
  title,
  count,
  children,
  open = true,
}: {
  title: string
  count?: number
  children?: JSX.Element | JSX.Element[] | string
  open?: boolean
}) => {
  const [isOpen, setIsOpen] = useState(open)

  const blockTitle = `${title} ${count && count > 0 ? count : ''}`.trim()

  return (
    <Collapsible open={isOpen} onOpenChange={setIsOpen}>
      <div className="flex items-center justify-between space-x-4">
        <h4 className="h-full align-middle text-sm font-semibold">{blockTitle}</h4>
        <CollapsibleTrigger asChild>
          <ShadcnButton variant="ghost" size="sm">
            <Icon name={isOpen ? 'a-arrow-up' : 'a-arrow-down'} className="h-4 w-4 transition-all" />
            <span className="sr-only">Toggle</span>
          </ShadcnButton>
        </CollapsibleTrigger>
      </div>
      <CollapsibleContent className="space-y-2">{children}</CollapsibleContent>
    </Collapsible>
  )
}

const ChangeInformationCompanyModal = ({ for: $data, email, phone, children = '' }: ChildModalProps) => {
  const t = useTranslations()
  const [open, setOpen] = useState(false)

  const [company, user, sendRequest, isFeedbackLoading] = useUnit([$data, $userProfile, postFeedback, feedbackLoading])

  const phoneDefault = { company_id: company?.id || 0, type: 'phone', department: 'office', value: '' }
  const emailDefault = { company_id: company?.id || 0, type: 'email', department: 'office', value: '' }
  const webDefault = { company_id: company?.id || 0, type: 'web', department: '', value: '' }
  const officerDefault = { company_id: company?.id || 0, type: 'officer', role: '', value: '' }

  const [phonesList, setPhonesList] = useState<ChangeList>([phoneDefault])
  const [emailsList, setEmailsList] = useState<ChangeList>([emailDefault])
  const [webList, setWebList] = useState<ChangeList>([webDefault])
  const [officersList, setOfficersList] = useState<ChangeList>([officerDefault])

  const [connectionEmail, setConnectionEmail] = useState(email)
  const [connectionPhone, setConnectionPhone] = useState(phone)

  const handleInputChangeList = <T,>(
    event: React.ChangeEvent<HTMLInputElement>,
    list: T[],
    index: number,
    updater: (list: T[]) => T[],
    setter: React.Dispatch<React.SetStateAction<T[]>>,
    defaultNew: T,
  ) => {
    const updatedList = updater([...list])

    setter(updatedList)

    if (index === list.length - 1 && event.target.value !== '') {
      setter([...updatedList, defaultNew])
    }

    if (index !== updatedList.length - 1 && event.target.value === '') {
      setter(updatedList.filter((_, idx) => idx !== index))
    }
  }

  const aggregatedList = [...phonesList, ...emailsList, ...webList, ...officersList].filter((v) => v.value !== '')

  const submitDisabled = aggregatedList.length === 0 || !connectionPhone || !connectionEmail

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    sendRequest({
      bin: company?.identifier || '',
      data: aggregatedList,
      phone: connectionPhone,
      email: connectionEmail,
    })
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild className="w-full">
        {children}
      </DialogTrigger>
      <DialogContent className="">
        <DialogTitle>{t('company_feedback_title_change_information')}</DialogTitle>
        <DialogDescription>
          <form className="max-h-screen space-y-2 overflow-y-auto px-1" onSubmit={onSubmit}>
            <CollapsableBlock title={t('phone')} count={phonesList.filter((p) => p.value).length}>
              {phonesList.map((elem, idx) => (
                <div key={`${idx}`} className="grid w-full grid-cols-12 gap-x-1">
                  <div className="col-span-8 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Input
                      placeholder="7 *** *** ** **"
                      value={elem.value}
                      onChange={(e) =>
                        handleInputChangeList(
                          e,
                          phonesList,
                          idx,
                          (v) => {
                            v[idx].value = e.target.value
                            return v
                          },
                          setPhonesList,
                          phoneDefault,
                        )
                      }
                      type="tel"
                    />
                  </div>
                  <div className="col-span-3 flex flex-col justify-between space-y-1">
                    <Label>{t('type')}</Label>
                    <Select
                      defaultValue="office"
                      name={`contact-type-${idx}`}
                      onValueChange={(value) => {
                        phonesList[idx].department = value
                        setPhonesList([...phonesList])
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder={t('office')} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="office">{t('office')}</SelectItem>
                        <SelectItem value="accountant">{t('accountant')}</SelectItem>
                        <SelectItem value="commercial_department">{t('commercial_department')}</SelectItem>
                        <SelectItem value="press_department">{t('press_department')}</SelectItem>
                        <SelectItem value="human_resources">{t('human_resources')}</SelectItem>
                        <SelectItem value="sales_department">{t('sales_department')}</SelectItem>
                        <SelectItem value="reception">{t('reception')}</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="col-span-1 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Button
                      disabled={idx === 0}
                      type="button"
                      onClick={() => setPhonesList((prev) => prev.filter((_, index) => idx !== index))}
                      className="relative bg-gray-700 p-0 py-1 align-middle hover:bg-gray-800 active:bg-gray-900"
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </div>
              ))}
            </CollapsableBlock>

            <Separator />

            <CollapsableBlock title={t('email')} count={emailsList.filter((p) => p.value).length}>
              {emailsList.map((elem, idx) => (
                <div key={`${idx}`} className="grid w-full grid-cols-12 gap-x-1">
                  <div className="col-span-8 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Input
                      placeholder="email@domain.com"
                      value={elem.value}
                      onChange={(e) =>
                        handleInputChangeList(
                          e,
                          emailsList,
                          idx,
                          (v) => {
                            v[idx].value = e.target.value
                            return v
                          },
                          setEmailsList,
                          emailDefault,
                        )
                      }
                      type="email"
                    />
                  </div>
                  <div className="col-span-3 flex flex-col justify-between space-y-1">
                    <Label>{t('type')}</Label>
                    <Select
                      defaultValue="office"
                      name={`contact-type-${idx}`}
                      onValueChange={(value) => {
                        emailsList[idx].department = value
                        setEmailsList([...emailsList])
                      }}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder={t('office')} />
                      </SelectTrigger>
                      <SelectContent>
                        <SelectItem value="office">{t('office')}</SelectItem>
                        <SelectItem value="accountant">{t('accountant')}</SelectItem>
                        <SelectItem value="commercial_department">{t('commercial_department')}</SelectItem>
                        <SelectItem value="press_department">{t('press_department')}</SelectItem>
                        <SelectItem value="human_resources">{t('human_resources')}</SelectItem>
                        <SelectItem value="sales_department">{t('sales_department')}</SelectItem>
                        <SelectItem value="reception">{t('reception')}</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="col-span-1 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Button
                      disabled={idx === 0}
                      type="button"
                      onClick={() => setEmailsList((prev) => prev.filter((_, index) => idx !== index))}
                      className="relative bg-gray-700 p-0 py-1 align-middle hover:bg-gray-800 active:bg-gray-900"
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </div>
              ))}
            </CollapsableBlock>

            <Separator />

            <CollapsableBlock
              title={`${t('website')}, ${t('social_networks')}`}
              count={webList.filter((p) => p.value).length}
            >
              {webList.map((elem, idx) => (
                <div key={`${idx}`} className="grid w-full grid-cols-12 gap-x-1">
                  <div className="col-span-11 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Input
                      placeholder="data.com | https:://www.data.com/stastnet"
                      value={elem.value}
                      onChange={(e) =>
                        handleInputChangeList(
                          e,
                          webList,
                          idx,
                          (v) => {
                            v[idx].value = e.target.value
                            return v
                          },
                          setWebList,
                          webDefault,
                        )
                      }
                      type="url"
                    />
                  </div>

                  <div className="col-span-1 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Button
                      disabled={idx === 0}
                      type="button"
                      onClick={() => setWebList((prev) => prev.filter((_, index) => idx !== index))}
                      className="relative bg-gray-700 p-0 py-1 align-middle hover:bg-gray-800 active:bg-gray-900"
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </div>
              ))}
            </CollapsableBlock>

            <Separator />

            <CollapsableBlock title={`${t('officer')}`} count={officersList.filter((p) => p.value).length}>
              {officersList.map((elem, idx) => (
                <div key={`${idx}`} className="grid w-full grid-cols-12 gap-x-1">
                  <div className="col-span-6 flex flex-col justify-between space-y-1">
                    <Label>{t('fullname_short')}</Label>
                    <Input
                      placeholder={t('fullname_short')}
                      value={elem.value}
                      onChange={(e) =>
                        handleInputChangeList(
                          e,
                          officersList,
                          idx,
                          (v) => {
                            v[idx].value = e.target.value
                            return v
                          },
                          setOfficersList,
                          officerDefault,
                        )
                      }
                      type="text"
                    />
                  </div>
                  <div className="col-span-5 flex flex-col justify-between space-y-1">
                    <Label>{t('role')}</Label>
                    <Input
                      placeholder={t('role')}
                      value={elem.role}
                      onChange={(e) => {
                        officersList[idx].role = e.target.value
                        setOfficersList([...officersList])
                      }}
                      type="text"
                    />
                  </div>
                  <div className="col-span-1 flex flex-col justify-between space-y-1">
                    <Label></Label>
                    <Button
                      disabled={idx === 0}
                      type="button"
                      onClick={() => setOfficersList((prev) => prev.filter((_, index) => idx !== index))}
                      className="relative bg-gray-700 p-0 py-1 align-middle hover:bg-gray-800 active:bg-gray-900"
                    >
                      <Icon name="trash" />
                    </Button>
                  </div>
                </div>
              ))}
            </CollapsableBlock>

            {!user && (
              <>
                <div>
                  <Label htmlFor="feedback-connection-email">{t('feedback_user_email')}</Label>
                  <Input
                    id={'feedback-connection-connectionEmail'}
                    className={
                      'w-full border-2 p-2 outline-0 invalid:border-red-400 hover:border-blue-200 focus:border-blue-200 disabled:animate-pulse disabled:bg-gray-100'
                    }
                    type={'email'}
                    value={connectionEmail}
                    onChange={(e) => setConnectionEmail(e.target.value)}
                    placeholder={t('feedback_user_email')}
                    required
                  />
                </div>

                <div>
                  <Label htmlFor="feedback-connection-phone">{t('feedback_user_phone')}</Label>
                  <Input
                    id={'feedback-connection-connection-phone'}
                    className={
                      'w-full border-2 p-2 outline-0 invalid:border-red-400 hover:border-blue-200 focus:border-blue-200 disabled:animate-pulse disabled:bg-gray-100'
                    }
                    type={'tel'}
                    value={connectionPhone}
                    onChange={(e) => setConnectionPhone(e.target.value)}
                    placeholder={t('feedback_user_phone')}
                    required
                  />
                </div>
              </>
            )}

            <Tooltip>
              <TooltipTrigger>
                <ShadcnButton disabled={submitDisabled || isFeedbackLoading} type={'submit'}>
                  {isFeedbackLoading ? <Icon name="loader-circle" className="animate-spin" /> : t('send')}
                </ShadcnButton>
              </TooltipTrigger>
              {submitDisabled ? <TooltipContent>{t('fields_empty')}</TooltipContent> : null}
            </Tooltip>
          </form>
        </DialogDescription>
      </DialogContent>
    </Dialog>
  )
}

export default ChangeInformationCompanyModal
