import Link from 'next/link'
import { useRouter } from 'next/router'

import { ROUTES } from '@/constants'
import { SafeView } from '@/features'
import { SearchSnippets } from '@/widgets'

import { useLocale } from 'next-intl'
import { Burger, HeaderLinks, Notifications } from './ui'

export const Header = () => {
  const { pathname } = useRouter()
  const locale = useLocale()

  return (
    <header className="relative z-10 mb-auto h-auto w-full shadow">
      <Notifications />
      <div className="container flex items-center justify-between py-1.5">
        <div className="flex items-center gap-x-2">
          <Link
            locale={locale}
            href={ROUTES.HOME}
            className="mr-2 font-aeroport text-logo font-medium sm:w-auto md:mr-8"
          >
            Statsnet
          </Link>
          <SafeView for={!['/en', '/'].includes(pathname)} otherwise={null}>
            <div className="border-header transition-shadow focus-within:shadow-lg focus:outline-none">
              <SearchSnippets reduced />
            </div>
          </SafeView>
        </div>
        <nav className="flex h-8 w-8 md:h-auto md:w-auto">
          <ul className="hidden items-center gap-x-4 sm:text-sm md:flex">
            <HeaderLinks />
          </ul>
          <Burger />
        </nav>
      </div>
    </header>
  )
}
