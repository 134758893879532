import { useGoogleLogin, useGoogleOneTapLogin } from '@react-oauth/google'
import { useUnit } from 'effector-react'
import { useTranslations } from 'next-intl'
import Image from 'next/image'

import { $authInGoogleInProcess } from '@/api'
import { CircularProgress } from '@/ui'

import { authSuccessful } from './model'

export const AuthWithGoogle = () => {
  const t = useTranslations()
  const [handleSuccess, pending] = useUnit([authSuccessful, $authInGoogleInProcess])

  const signIn = useGoogleLogin({
    onSuccess: (response) => handleSuccess(response),
  })

  useGoogleOneTapLogin({
    onSuccess: (credentialResponse) => {
      console.info(credentialResponse)
    },
    onError: () => {
      console.info('Login Failed')
    },
  })

  return (
    <div className="w-full">
      <button
        className="relative flex w-full items-center justify-center rounded-md py-3 text-gray-500 outline-none focus:outline-none disabled:text-gray-200"
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.14) 0px 2px 2px 0px',
        }}
        type="button"
        onClick={() => signIn()}
      >
        {pending ? (
          <div className="mx-auto h-4 w-4">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Image
              className="top-50 absolute left-5"
              src="/images/google_logo.svg"
              alt="google-logo"
              width={20}
              height={20}
            />
            <span>{t('sign_in_with_google')}</span>
          </>
        )}
      </button>
    </div>
  )
}
