import { TokenResponse } from '@react-oauth/google'
import { createEvent, createStore, sample } from 'effector'

import { authWithGoogleFx } from '@/api'

export const $googleAuthResponse = createStore<Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'> | null>(
  null,
)
export const authSuccessful = createEvent<Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>>()

sample({
  clock: authSuccessful,
  filter: (authResult: Omit<TokenResponse, 'error' | 'error_description' | 'error_uri'>) => 'accessToken' in authResult,
  target: $googleAuthResponse,
})

sample({
  clock: $googleAuthResponse,
  filter: Boolean,
  fn: (response) => ({
    data: {
      token: response.access_token,
    },
  }),
  target: authWithGoogleFx,
})
