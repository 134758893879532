import { RankingInfo, rankItem } from '@tanstack/match-sorter-utils'
import {
  ColumnDef,
  FilterFn,
  Row,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table'

import { useVirtualizer } from '@tanstack/react-virtual'
import clsx from 'clsx'
import { useUnit } from 'effector-react'
import _ from 'lodash'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/router'
import { memo, useMemo, useRef, useState } from 'react'

import { ROUTES } from '@/constants'
import { $userProfile } from '@/entities/user'
import { SafeView } from '@/features/safe-view'

interface Props {
  list: any[]
  columns: ColumnDef<any>[]
  mocked?: boolean
  partialMocked?: boolean
  globalFilter?: any
  filterKey?: string
  collapsible?: boolean
}

declare module '@tanstack/table-core' {
  interface FilterFns {
    fuzzy: FilterFn<unknown>
  }
  interface FilterMeta {
    itemRank: RankingInfo
  }
}

const fuzzyFilter: any = (row: any, columnId: any, value: any, addMeta: any, key: string) => {
  // Rank the item
  const itemRank = rankItem(row.original[key], value)

  // Store the itemRank info
  addMeta({
    itemRank,
  })

  // Return if the item should be filtered in/out
  return itemRank.passed
}

function BaseVirtualizedTable({
  list = [],
  columns = [],
  mocked = false,
  partialMocked = false,
  globalFilter,
  filterKey,
  collapsible = false,
}: Props) {
  const t = useTranslations()
  const router = useRouter()
  const user = useUnit($userProfile)
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false)

  const slicedList = useMemo(() => {
    if (mocked && !partialMocked) {
      const mockDataStrings = ['Mock data', 'Mock string']
      const createMockObject = () => {
        return _.mapValues(_.keyBy(columns, 'accessorKey'), () => _.sample(mockDataStrings))
      }

      return _.times(3, createMockObject)
    }

    if (mocked && partialMocked) {
      return list?.length > 3 ? list.slice(0, 3) : list
    }

    return list
  }, [mocked, partialMocked, list, columns])
  const table = useReactTable({
    data: slicedList,
    columns,
    getCoreRowModel: getCoreRowModel(),
    filterFns: {
      fuzzy: fuzzyFilter,
    },
    globalFilterFn: (...args) => fuzzyFilter(...args, filterKey),
    state: {
      globalFilter,
    },
    getFilteredRowModel: getFilteredRowModel(),
  })

  const tableContainerRef = useRef<HTMLDivElement>(null)

  const { rows } = table.getRowModel()

  const rowVirtualized = useVirtualizer({
    getScrollElement: () => tableContainerRef.current,
    count: rows.length,
    estimateSize: () => 52,
  })

  const virtualRows = rowVirtualized.getVirtualItems()
  const totalSize = rowVirtualized.getTotalSize()

  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0
  const paddingBottom = virtualRows.length > 0 ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0) : 0

  const payHandler = () => {
    if (user) {
      router.push(ROUTES.PRICING)
    } else {
      router.push(`/auth/sign-up?redirect=${encodeURIComponent(router.asPath)}`)
    }
  }

  return (
    <>
      <SafeView for={collapsible}>
        <button
          className="flex cursor-pointer items-center rounded py-1 text-sm text-gray-400 outline-none duration-300 hover:text-primary focus:outline-none"
          onClick={() => setIsCollapsed((isCollapsed) => !isCollapsed)}
        >
          {isCollapsed ? t('show') : t('hide')}
          <span className={`forTable text-gray-400 ${!isCollapsed ? 'rotated' : ''}`} />
        </button>
      </SafeView>
      <div ref={tableContainerRef} className="relative h-fit max-h-[600px] min-w-full overflow-auto rounded-lg shadow">
        <SafeView for={!isCollapsed}>
          <table
            style={{
              height: rowVirtualized.getTotalSize(),
              width: '100%',
              position: 'relative',
            }}
          >
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      colSpan={header.colSpan}
                      style={{ width: header.getSize() }}
                      className="sticky top-0 border-0 bg-gray-50 px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      {header.isPlaceholder ? null : (
                        <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                      )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody
              className={clsx({
                blur: mocked && !partialMocked,
              })}
            >
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<any>

                return (
                  <tr
                    key={row.id}
                    className="text-sm"
                    ref={rowVirtualized.measureElement}
                    data-index={virtualRow.index}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const size = cell.column.columnDef.size

                      return (
                        <td
                          key={cell.id}
                          className="px-6 py-4 justify-start items-center whitespace-nowrap"
                          style={{
                            minWidth: '16rem',
                            whiteSpace: 'normal',
                          }}
                        >
                          {flexRender(cell.column.columnDef.cell, cell.getContext()) || '-'}
                        </td>
                      )
                    })}
                  </tr>
                )
              })}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
          {mocked && (
            <div
              className={clsx('z-99 absolute bottom-0 left-0 flex w-full items-center md:justify-center', {
                'linear-gradient h-64': partialMocked,
                'h-full': mocked && !partialMocked,
              })}
            >
              <div
                className={clsx('flex w-screen flex-col text-center', {
                  'mt-6': mocked && !partialMocked,
                })}
              >
                <span className="font-stem text-xl text-black">{t('see_full_report')}</span>
                <button className="hover:text-orange arrow-after border-0 bg-none text-primary" onClick={payHandler}>
                  {t('subscribe_for_more')}
                </button>
              </div>
            </div>
          )}
        </SafeView>
      </div>
    </>
  )
}

export const VirtualizedTable = memo(BaseVirtualizedTable)
